import { setCookie ,getCookie,deleteCookie } from "./coockies"
import { setlocalStorage ,getlocalStorage ,deletlocalStorage} from './localStorage';

 

export const  setAuthentication = (token , user) => {
    setCookie('token',token);
    setlocalStorage('user',user);
};

export const isAuthenticated = () => {

    if (getlocalStorage('user')&& getCookie('token')) {
        return getlocalStorage('user');
        
    }else{
        return false;
    }
}

export const Logout = () => {
    deleteCookie('token');
    deletlocalStorage('user');
    
};




export const  setBlog = (token , Blog) => {

    setlocalStorage('Blog',Blog);
};

 





export const isBlog = () => {

    if (getlocalStorage('Blog')) {
        return getlocalStorage('Blog');
        
    }else{
        return false;
    }
}