import React, {useState, useEffect} from "react";
import {Container, Row, Col, Button} from "react-bootstrap";
import ListClient from "./ListClient";
import {Link} from "react-router-dom";
import {Form, Alert} from "react-bootstrap";
import {
  readOneClient,
  AjouterSousClient,
  Table_Expert_Simister_exacte,
} from "../../../../api/Clinets";
import NavbarClientleve1 from "./NavbarClientleve1";
import Recap from "./Recap";

const Accueil = () => {
  const Id = localStorage.getItem("ID_Client");
  let x = 0;
  const [stast, setstast] = useState({
    xr: "",
  });
  const {xr} = stast;

  const [formdata, setformdata] = useState({
    _id: Id,
    EmailCt: "",
    NameCt: "",
    NumCt: "",
    Pass: "",
    TelCt: "",
  });

  const {NameCt} = formdata;
  const [myiduser, setMyiduser] = useState({iduser: Id});

  useEffect(() => {
    readOneClient(formdata).then((response) => {
      console.log("hi", response.data);
      setformdata(response.data);
    });

    Table_Expert_Simister_exacte(myiduser)
      .then((response) => {
        const data = response.data;
        const reslut = response.data.simster;
        reslut.map((m) => {
          if (m.ID_Filer_Client == Id) {
            x = x + 1;
          }
        });
        setstast({xr: x});
      })
      .catch((err) => {
        alert("Error retrieving data!!!");
      });
  }, []);

  const [isVisible, setisVisible] = useState({
    Visible: "hidden",
  });

  const handleSubmit2 = () => {
    setisVisible({
      ...isVisible,
      Visible: "visible",
    });
  };

  const handleSubmit3 = () => {
    setisVisible({
      ...isVisible,
      Visible: "hidden",
    });
  };

  const [formSmister, setSmister] = useState({
    NameCt2: "",
    numClt: Id,
    errorMSg: "",
  });

  const handleChange = (evt) => {
    setSmister({
      ...formSmister,
      [evt.target.name]: evt.target.value,
      errorMSg: "",
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    AjouterSousClient(formSmister)
      .then(() => {
        alert("Vous avez ajout un sous client ");
        window.location.reload(false);
      })
      .catch((err) => {
        console.log("login error est", err);
        setSmister({
          ...formSmister,
          errorMSg: err.response.data.errorMessage,
        });
      });
  };

  const gohome = () => {
    window.location = "/HomeAdmin";
  };

  return (
    <div>
      <NavbarClientleve1 Id_CLIENT={Id} />

      <Container fluid>
        <div>
          <Row>
            <Col>
              <h1 style={{margin: "30px"}}>Accueil</h1>
            </Col>
            <Col md={{span: 3, offset: 3}}>
              <Button
                style={{margin: "30px"}}
                onClick={gohome}
                className="btn-info"
              >
                Retour Menu Principal
              </Button>
            </Col>
          </Row>

          <div>
            <Row>
              <Col>
                <p
                  style={{
                    margin: "10px",
                    "font-size": "20px",
                    "font-family": "georgia",
                  }}
                >
                  {" "}
                  Nom de client : {NameCt}{" "}
                </p>
              </Col>
              <Col md={{span: 3, offset: 3}}>
                <p
                  style={{
                    margin: "10px",
                    "font-size": "20px",
                    "font-family": "georgia",
                  }}
                >
                  Numéro du compte : {formdata.NumCt}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{span: 3}}>
                <p
                  style={{
                    margin: "10px",

                    "font-size": "20px",
                    "font-family": "georgia",
                  }}
                >
                  Nombre de visites : {stast.xr}
                </p>
              </Col>
            </Row>
            <Row>
              <p>vous trouverez ici la liste des sous-clients</p>
            </Row>
            <Row>
              <Col style={{margin: "20px", "font-size": "15px"}}>
                <ListClient Name_Client={formdata.NameCt} numCt={Id} Id={Id}>
                  {" "}
                </ListClient>
              </Col>
            </Row>
            <Row className=" justify-content-md-center ">
              <Col xs={6}>
                <Link
                  className="float-right btn btn-success btn-sm"
                  onClick={handleSubmit2}
                  style={{marginTop: 10}}
                >
                  {" "}
                  Ajouter un Nouveau client{" "}
                </Link>
              </Col>
            </Row>

            <Row>
              <Container
                style={{visibility: isVisible.Visible, marginBottom: "30px"}}
              >
                <div
                  style={{
                    "background-color": "#f5f5f5",
                    padding: "20px ",
                    "border-radius": "30px",
                    marginTop: "30px",
                    "box-shadow": "0px 0px 10px 2px #acacacf5 ",
                  }}
                >
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>
                          {formSmister.errorMSg && (
                            <Alert key={"danger"} variant={"danger"}>
                              {formSmister.errorMSg}
                            </Alert>
                          )}
                          Nom du sous-client de la société{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="NameCt2"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col md={{span: 3, offset: 3}}>
                        <Button variant="primary" type="submit">
                          Ajouter
                        </Button>
                      </Col>

                      <Col md={{span: 3, offset: 3}}>
                        <Button variant="secondary" onClick={handleSubmit3}>
                          cancel
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Container>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Accueil;
