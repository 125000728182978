import React from "react";
import {suprimesuousclient, ClientList} from "../../../../api/Clinets";
import {Row, Col, Button, Table, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaUserEdit} from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Updatesousclients from "./Updatesousclient";
import ItemListtoutl from "./sous_cleint/Itemtous";

class ListClient extends React.Component {
  state = {
    NameCt: "",
    ID: "",
    posts: [],
  };

  componentDidMount = () => {
    this.getBlogPost();
  };

  getBlogPost = () => {
    ClientList()
      .then((response) => {
        const data = response.data;
        this.setState({posts: data.sousClient});
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  };
  openModal = () => {
    this.setState({isOpen: true});
  };

  closeModal = () => {
    this.setState({isOpen: false});
  };

  render() {
    const num = this.props.numCt;
    const Id = this.props.Id;
    const name_Client = this.props.Name_Client;

    const handleClickSuprime = async (id, name) => {
      // this function deletat client
      if (window.confirm("Are you sure you want to delete " + name + " ?")) {
        await this.setState({ID: id});

        suprimesuousclient(this.state)
          .then((response) => {
            alert("you have delete an acompte");
            window.location.reload(false);
          })
          .catch((err) => {
            alert(err);
          });
      }
    };
    const handleClickUpdate = async (id, name) => {
      await localStorage.setItem("ID_Sous_Client_update", id);
      await localStorage.setItem("Name_Sous_Client_update", name);

      this.openModal();
      //redurection to update page of client
      // window.location = "/Admin/updateClient";
    };

    return (
      <div>
        <Row className=" justify-content-md-center ">
          <Col md="6">
            <Row>
              <Col md="12">
                <Table striped>
                  <tbody>
                    {this.state.posts.map((post) => {
                      if (post.numClt === num) {
                        return (
                          <tr>
                            <td>{post.NameCt}</td>
                            <td>
                              <Link
                                className="float-right btn btn-info btn-sm"
                                to={{
                                  pathname: "/Admin/GroupSousClient",
                                  state: {
                                    Id_SOUS_CLIENT: post._id,
                                    NAME_SOUSCLIENT: post.NameCt,
                                    Id_CLIENT: Id,
                                    NAmeClient: name_Client,
                                  },
                                }}
                              >
                                Consulter
                              </Link>
                            </td>
                         
                            <td>
                              <Button
                                onClick={() => {
                                  handleClickSuprime(post._id, post.NameCt);
                                }}
                                className="float-right btn btn-danger btn-sm"
                                style={{
                                  marginLeft: "10px",
                                  marginRight: " 10px",
                                }}
                              >
                                X
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="float-right btn btn-warning btn-sm"
                                variant="success"
                                onClick={() => {
                                  handleClickUpdate(post._id, post.NameCt);
                                }}
                              >
                                <FaUserEdit />
                              </Button>
                            </td> 
                            <td>
                           
                              
                            <ItemListtoutl idcl={post._id} NAME_SOUSCLIENT={post.NameCt} />
                                                        </td>
                            <Modal
                              show={this.state.isOpen}
                              onHide={this.closeModal}
                            >
                              <Modal.Header closeButton></Modal.Header>
                              <Modal.Body>
                                <Updatesousclients />
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={this.closeModal}
                                >
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </tr>
                        );
                      }
                      return true;
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ListClient;
