import React from "react";
import "./../../css/styleAprochanim.scss";
import pref from "./../../../img/perf2.jpg";
import {FaAngleDoubleRight} from "react-icons/fa";
import Navbarstnd from "./../../Navbar/Navbarstnd";
import NavbarApproche from "../../Navbar/NavbarApproche";

const ApprochTop = () => {
  return (
    <div>
      <Navbarstnd />

      <NavbarApproche />
      <div id="app">
        <div class="title">
          <div class="title-inner">
            <div class="cafe">
              <div class="cafe-inner">Notre approche basée sur </div>
            </div>
            <div class="mozart">
              <div class="mozart-inner"> la performance et l’efficience</div>
            </div>
            <div class="mozart">
              <div class="cafe-inner">
                <div class="image">
                  <p>
                    Une approche globale : <br />
                    &#8194; GLOBAL RISK ENTREPRISE, nous accompagnons nos
                    clients sur toute la chaine de valeur{" "}
                  </p>
                </div>
                <div class="donc">
                  <p>
                    <FaAngleDoubleRight /> Performance garantie{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="image">
          <img src={pref} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ApprochTop;
