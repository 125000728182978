import axios from "axios";

export const login = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post("/api/auth/login", data, config);
  return response;
};

export const AddArticles = async (data) => {
  const response = await axios.post("/api/auth/AddArticles", data);
  return response;
};

export const readall = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post("/api/auth/readall", data, config);
  return response;
};

export const setIdRef = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post("/api/auth/setIdRef", data, config);
  return response;
};

export const readOne = async (data) => {
  const response = await axios.post("/api/auth/readOne", data);
  return response;
};

export const Supprimer = async (data) => {
  const response = await axios.post("/api/auth/Supprimer", data);
  return response;
};

export const UpdateArticles = async (data) => {
  const response = await axios.post("/api/auth/UpdateArticles", data);
  return response;
};
export const supVedos = async (data) => {
  const response = await axios.post("/api/auth/supVedos", data);
  return response;
};

export const Addvideo = async (data) => {
  const response = await axios.post("/api/auth/Addvideo", data);
  return response;
};
export const Addimage = async (data) => {
  const response = await axios.post("/api/auth/Addimage", data);
  return response;
};

export const supimgs = async (data) => {
  const response = await axios.post("/api/auth/supimgs", data);
  return response;
};
