import React from "react";
import {useEffect} from "react";
import "./css/style.css";

import {Col, Row, Container} from "react-bootstrap";
import {IoIosArrowForward} from "react-icons/io";
import Client from "./Compusnte/home/Client";
import Partenaires from "./Compusnte/home/Partenaires";
import Navbarstnd from "./Navbar/Navbarstnd";
import Navbar from "./Navbar/navbar";
import Footter from "./Footer/Footer1";
import PREVEX_V from "./../videos/PREVEX_01.mp4";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const Home = () => {
  return (
    <div style={{marginTop: 0, paddingTop: 0}}>
      <div className="parallax">
        <div className="back">
          <div className="backr">
            <Navbarstnd />
          </div>

          <Navbar />

          <div className="Container">
            <div className=" Row">
              <div className="col">
                <div className=" frit">
                  <p>
                    UNE APPROCHE GLOBALE DES RISQUES,
                    <br /> DE LA PRÉVENTION AU RÉGLEMENT{" "}
                  </p>
                  <button type="button" className="slide">
                    <div>
                      <a href="NOSOFFRES"> En savoir plus </a>
                    </div>{" "}
                    <i>
                      {" "}
                      <IoIosArrowForward />
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="theerd">
        <Container>
          <Row>
            <Col>
              <h3>Qui sommes nous ?</h3>
              <hr />
              <div className="item" data-aos="fade-right" data-aos-offset="300">
                {" "}
                <p className="prog">
                  PREV-EX AFRICA est une société de prévention, d’expertise et
                  de gestion de sinistres multidisciplinaires. <br />
                </p>
              </div>
              <div class="item" data-aos="fade-right" data-aos-offset="300">
                {" "}
                <p className="prog">
                  Nous accompagnons nos clients dans leur démarche de sécurité,
                  dans le choix des moyens de prévention et la mise en place des
                  mesures de sécurité afin d’atténuer la future sinistralité.
                  <br />
                </p>
              </div>
              <div className="item" data-aos="fade-right" data-aos-offset="300">
                <p className="prog">
                  Nous intervenons dans l’expertise et l’évaluation des
                  sinistres de fréquences et des sinistres d’ampleur et de fort
                  enjeux <br />
                </p>
              </div>
              <div class="item" data-aos="fade-right" data-aos-offset="300">
                <p className="prog">
                  Nous sommes Experts dans l’estimation préalables des capitaux
                  et l’évaluation des patrimoines
                </p>
              </div>
              <div class="item" data-aos="fade-right" data-aos-offset="300">
                <p className="prog">
                  Nous offrons des solutions digitalisés pour traiter les
                  sinistres à l‘instant t . <br />
                </p>
              </div>
            </Col>

            <Col>
              <div class="item" data-aos="fade-right" data-aos-offset="300">
                <div className="video" style={{"margin-top": "60px"}}>
                  <video
                    src={PREVEX_V}
                    autoPlay
                    muted
                    width="500"
                    shouldPlay
                    height="300"
                    controls="controls"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <hr style={{height: "1px", color: "#4d4f574f", margin: 40}} />
        <Client />

        <Partenaires />
      </div>

      <Footter />
    </div>
  );
};

export default Home;
