
import React, {   useState , useEffect} from 'react' ;
import { Container,  Row, Col} from 'react-bootstrap';
 
import { useLocation } from 'react-router-dom';
import { readOneClient , readOneFiler } from '../../../../../../../api/Clinets';
import CollapsibleExample from './../NavbarClient';
import TableauSimster from './tableSimiste';
import AjouterEstimationsprelibleformulair from './Ajouter_Estimations_prelible_formulair';
import {Link} from "react-router-dom";

const  Estimationprelible = () =>  {


  const location = useLocation();

  console.log("id: " , location.state);
 
    const Id_Filer = location.state.Id_Filer ;  
    const Id_CLIENT = location.state.Id_CLIENT ;  
    
    const NameCtt =location.state.NameCtt ; 
    const NAME_Filer =location.state.NAME_Filer ; 
    const Name_sous_client =location.state.Name_sous_client ; 
     
  const Id_SOUS_CLIENT = location.state.Id_SOUS_CLIENT;
  
  const [formdata , setformdata]  = useState ({
    _id : Id_Filer ,
    Name_filer : "",
    Name_client : "",
    Name_SousClient : ""
    
}) ;



const  {
 Name_filer ,
Name_client ,
Name_SousClient ,
    
 
} = formdata; 

 
const [formdata2 , setformdata2]  = useState ({
  _id : Id_CLIENT ,
  EmailCt: "",
  NameCt: "",
  NumCt: "",
  Pass: "",
  TelCt: "",
}) ;


 



useEffect(() => {
     
  readOneFiler(formdata)

  .then( (response ) => {
//  setformdata(response.data);
  console.log ("hi",response.data)
  setformdata(response.data);

  

})
readOneClient(formdata2)

  .then( (response ) => {
//  setformdata(response.data);
  console.log ("hi2",response.data)
  setformdata2(response.data);

})
 
 // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
 
return (
  <div>
  <CollapsibleExample   Id_SOUS_CLIENT  = {Id_SOUS_CLIENT}  Id_Filer={Id_Filer} Id_CLIENT={Id_CLIENT}  Name_sous_client={Name_sous_client}  NameCtt={NameCtt} NAME_Filer={NAME_Filer} />

  <Container fluid>
  
  <div   >
      <h1 style={{"margin" : "30px" }}>Estimation préalable</h1>
    
       <div>
       <Row>
           
       <p  style={{"margin" : "10px" , "font-size": "20px"  , "font-family": 'georgia'}}> Nom de client :  {Name_client} </p>
       </Row>
       <Row>
       <Col> 
       
       <p
       style={{
         "margin-left": "20px",
         "font-size": "15px",
         "font-family": "georgia",
       }}
     >
       <Link
         exact
         to={{
           pathname: "/Admin/accuile",
           state: {
             Id_CLIENT: Id_CLIENT,
           },
         }}
       >
         {NameCtt}
       </Link>
       {">"}
       <Link
         exact
         to={{
           pathname: "/Admin/GroupSousClient",
           state: {
             Id_CLIENT: Id_CLIENT,
             Id_SOUS_CLIENT: Id_SOUS_CLIENT,
             NAME_SOUSCLIENT: Name_sous_client,
             NAmeClient: NameCtt,
           },
         }}
       >
         {Name_sous_client}
       </Link>
     
             

           {">"}
           <Link
             to={{
               pathname: "/Admin/Filiere",
               state: {
                 Id_Filer: Id_Filer,
                 Id_CLIENT: Id_CLIENT,
                 NameCtt: NameCtt,
                 NAME_Filer: NAME_Filer,
                 Name_sous_client: Name_sous_client,
               },
             }}
           >
             {NAME_Filer}
           </Link>
         </p>
       </Col>
       <Col>
       <p style={{"margin" : "10px" , "font-size": "20px"  , "font-family": 'georgia'}}>
       Numéro du compte :   {formdata2.NumCt} 
       </p>
       </Col>

       </Row> 
       
     
       <Row>
       
       <Col style={{"margin" : "20px" , "font-size": "15px"   }}>
       
         
<TableauSimster  ID_Filer_Client= {Id_Filer} > </TableauSimster>
 
           </Col>
           
           </Row>

           <Row>
           <Row>
          
           <div>
           
          
       
           <AjouterEstimationsprelibleformulair ID_Filer_Client= {Id_Filer} > </AjouterEstimationsprelibleformulair>
          
           </div>
           
           </Row>
           </Row>
           
           </div>
          </div>
        
</Container>
 
  </div>


  );
} 

export default Estimationprelible ;