
import React from 'react'
import {  Container , Row, Col } from 'react-bootstrap';
import "./../../../style2.scss" ; 
import "./../../../css/style.css"
import {Link} from "react-router-dom";
 
import Navbarstnd from '../../../Navbar/Navbarstnd'; 
import Card2 from '../Card2';
import Card from '../Card';
import Card4 from '../Card4';
import Card5 from '../Card5';
import Card6 from '../Card6';
import Card7 from '../Card7';
import Card9 from '../Card9';
import Card10 from '../Card10';
import NavbarOffres from './../../../Navbar/NavbarOffres';
import Footter from './../../../Footer/Footer1';

const AnalysedeRisqueBris = ()=> { 
       
    return ( 
 
        <div>
      
        <div className='backgroundOffres'>
        <div className='backr'>
                <div className='backr'>
                <Navbarstnd />
                </div>
                <NavbarOffres/> 
        <div style={{paddingBottom : 30 , margin : 40 }} ></div>
      </div>
      </div>
   
   
      <div className="secandparts">
       
      <div className='secands'>
      <Container>
      <Row className=' justify-content-md-center'> 
        <Col   style={{marginTop : 30  }} >
          <h1 >Analyse de risque Bris de machine  </h1>
           <p>
           Le risque de bris de machine est un risque qui peut toucher tout type d’entreprise/industrie. En effet le matériel utilisé peut subir des dommages soudains, résultant de causes internes (erreur de conception, défaillance des automatismes de régulation, incidents d'exploitation, incendie ...), ou de causes externes (collision, maladresse, négligence, malveillance ...). 
           </p>
           <p>
           L'entreprise peut être totalement dépendante de certaines machines de production d’où le risque d’interruption de l’activité et de perte de marché ;
           </p>
           <p>Nos experts vous accompagnent dans une démarche préventive pour éviter les situations de risque avec une analyse approfondie (AMDEC) des modes de défaillance leurs effets et leur criticité pour vous proposer un plan préventif adéquat à vos équipements.</p>
           <p>Nous vous accompagnons également, pour analyser les causes des sinistres des appareils ou des processus industriels et évaluer les préjudices associés dans les domaines suivants : mécanique, électricité, électronique, process industriel, automatisme... </p>
           </Col>  
            </Row>
   
            <Row>
            <Link    to={{
                pathname: "/Form",
                state: "Analyse de risque Bris de machine "  ,
                }} >
                <button className='demandeBTN'>Demander un devis</button>
             </Link>
                </Row>
         
            </Container>   
            </div>


            
            <div className="autreOffer">
            <Container>
            <Row className=' justify-content-md-center'> 
          <h1 > Nos autres offres   </h1>
                
            


      <Col md={4}>
          <Card />
      </Col> 
      <Col md={4}>
          <Card2 />
      </Col> 
     
      <Col md={4}>
           <Card4 />
      </Col> 
      <Col md={4}>
            <Card5 />
      </Col>  
       <Col md={4}>
           <Card6 />
      </Col>   
      <Col md={4}>
            <Card7 />
      </Col>   
       
       <Col md={4}>
           <Card9 />
      </Col>   
      <Col md={4}>
            <Card10 />
      </Col> 




            </Row>
            </Container>   
            </div>



      </div>
   
    
   
    
   
      <Footter /> 
   
   </div>  
      
   
);
}
export default AnalysedeRisqueBris ;

 
