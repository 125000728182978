
import React from 'react'
import { Container , Row, Col } from 'react-bootstrap';
import './css/style.css' ; 
import Navbarstnd from './Navbar/Navbarstnd';
import "./style2.scss" ;  
import Card from './Compusnte/Carde/Card';
import Card2 from './Compusnte/Carde/Card2';
import Card3 from './Compusnte/Carde/Card3';
import Card4 from './Compusnte/Carde/Card4';
import Card5 from './Compusnte/Carde/Card5';
import Card6 from './Compusnte/Carde/Card6';
import Card7 from './Compusnte/Carde/Card7';
import Card9 from './Compusnte/Carde/Card9';
import Card10 from './Compusnte/Carde/Card10';
import NavbarOffres from './Navbar/NavbarOffres';
import Footter from './Footer/Footer1';


class Offres extends React.Component {
 
    render() {
      
      return ( 
 
        <div>
        <div className='backgroundOffres'>
        <div className='backr'>
                <div className='backr'>
                <Navbarstnd />
                </div>
        <NavbarOffres/> 
        <div style={{paddingBottom : 30 , margin : 40 }} ></div>
      </div>
      </div>

      <div className="secandparts">
    
    <div className='secands'>
    <Container>
    <Row className=' justify-content-md-center'> 
      <Col md={8} style={{marginTop : 30  }} >
        <h1 >Une Offre Globale, des Solutions sur mesure  </h1>
         <p>
         Organisé en branches de spécialités, Union d’Experts intervient sur l’ensemble du territoire auprès des Particuliers et des Entreprises, victimes de sinistres de fréquence,
         d’ampleur et à fort enjeu (Experts de spécialités)
</p>
          </Col>  
          </Row>
 
    </Container>
          </div>
    </div>


            <div>
            <Container>
            <Row className=' justify-content-md-center'> 

            
                <Col md={4}>
                    <Card />
                </Col> 
                <Col md={4}>
                    <Card2 />
                </Col> 
                <Col md={4}>
                     <Card3 />
                </Col> 
                <Col md={4}>
                     <Card4 />
                </Col> 
                <Col md={4}>
                      <Card5 />
                </Col>  
                 <Col md={4}>
                     <Card6 />
                </Col>   
                <Col md={4}>
                      <Card7 />
                </Col>   
                
                 <Col md={4}>
                     <Card9 />
                </Col>   
                <Col md={4}>
                      <Card10 />
                </Col> 


                </Row>
            </Container>   
            </div>

            
           

            <Footter /> 

</div>
      )
    }
}
export default Offres  ;