import React, {useState} from "react";
import {Container, Form, Row, Col, Button} from "react-bootstrap";
import {AjouterSimstre} from "../../../../../../../../api/Clinets";

function AjouterSmister(props) {
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);

  const [inputValue2, setInputValue2] = useState("");
  const [tags2, setTags2] = useState([]);

  const [inputValue3, setInputValue3] = useState("");
  const [tags3, setTags3] = useState([]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleInputChange2 = (event) => {
    setInputValue2(event.target.value);
  };

  const handleInputChange3 = (event) => {
    setInputValue3(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      if (inputValue) {
        setTags([...tags, inputValue]);
        setInputValue("");
      } else if (inputValue2) {
        setTags2([...tags2, inputValue2]);
        setInputValue2("");
      } else if (inputValue3) {
        setTags3([...tags3, inputValue3]);
        setInputValue3("");
      }
    }

    setSmister({
      ...formSmister,
      endommagees: tags2,
      causes: tags,
      tag: tags3,
    });
  };

  const num = props.Id_CLIENT;

  const [formSmister, setSmister] = useState({
    numSIM: "",
    DateSim: "",
    NumCont: "",
    montant_preminare: "",
    ID_Filer_Client: num,
    rapp_preminare: null,
  });

  const {numSIM, DateSim, NumCont, montant_preminare, rapp_preminare} =
    formSmister;

  const handleChange = (evt) => {
    setSmister({
      ...formSmister,

      [evt.target.name]: evt.target.value,

      errorMSg: "",
    });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setTags3([...tags3, inputValue3]);
    setInputValue3("");

    setSmister({
      ...formSmister,
     
      tag: tags3,
    });

    await  AjouterSimstre(formSmister)
      .then(() => {
        alert("Vous avez ajout un Smister ");
        // window.location.reload();
      })
      .catch((err) => {
        console.log("login error est", err);
        setSmister({
          ...formSmister,
          errorMSg: err.response.data.errorMessage,
        });
      });
  };

  return (
    <div>
      <Container fluid>
        <div
          style={{
            "background-color": "#f5f5f5",
            padding: "20px ",
            "border-radius": "30px",
            marginTop: "30px",
            "box-shadow": "0px 0px 10px 2px #acacacf5 ",
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Numéro Sinistre </Form.Label>
                <Form.Control
                  type="text"
                  name="numSIM"
                  onChange={handleChange}
                  value={numSIM}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Password"
                  name="DateSim"
                  onChange={handleChange}
                  value={DateSim}
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Numéro contrat </Form.Label>
              <Form.Control
                name="NumCont"
                onChange={handleChange}
                value={NumCont}
              />
            </Form.Group>

            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label> les causes de sinistres </Form.Label>
                  <Form.Control
                    className="mb-3"
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                  />
                  {tags.map((tag, index) => (
                    <div key={index}>{tag}</div>
                  ))}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label> les parties endommagées </Form.Label>
                  <Form.Control
                    className="mb-3"
                    type="text"
                    value={inputValue2}
                    onChange={handleInputChange2}
                    onKeyDown={handleInputKeyDown}
                  />
                  {tags2.map((tag2, index2) => (
                    <div key={index2}>{tag2}</div>
                  ))}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label> Garantie concernée </Form.Label>
                  <Form.Control
                    className="mb-3"
                    type="text"
                    value={inputValue3}
                    onChange={handleInputChange3}
                    onKeyDown={handleInputKeyDown}
                  />
                  {tags3.map((tag3, index3) => (
                    <div key={index3}>{tag3}</div>
                  ))}
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary"  
            onClick={handleInputKeyDown}
            
            type="submit">
              Envoyer
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
}

export default AjouterSmister;
