import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import "./css/style.css";
import Navbarstnd from "./Navbar/Navbarstnd";
import "./style2.scss";
import Materiel from "./../img/Materiel.jpg";
import AOS from "aos";
import prejudices from "./../img/Préjudices Économiques et Marchandises.jfif";
import agricol from "./../img/Agricole et Agroalimentaire.jpg";
import sesche from "./../img/Secheresse.jpg";
import vol from "./../img/Vol, Mobilier et Fine Art.jpg";
import pro from "./../img/Protection-Juridique.jpg";
import NavbarSpecialites from "./Navbar/NavbarSpecialites";
import Footter from "./Footer/Footer1";

const Specialites = () => {
  AOS.init({});

  return (
    <div>
      <div className="backgroundSpecialite">
        <div className="backr">
          <div className="backr">
            <Navbarstnd />
          </div>
          <NavbarSpecialites />
          <div style={{margin: 30, padding: 30}}></div>
        </div>
      </div>

      <div className="secandparts">
        <div className="secands">
          <Container>
            <Row>
              <Col>
                <h1>Acteur de spécialités </h1>
                <p>
                  Union d’Experts dispose d’une organisation technique
                  spécifique au bénéfice d’une offre Entreprise Globale éprouvée
                  par les assureurs et courtiers du marché : de la prévention au
                  règlement.
                </p>

                <p>
                  <strong>Chaque branche</strong> de spécialités est animée par
                  un Référent Technique National (RTN), certifié et spécialiste.
                  Accompagné d’un réseau de référents techniques régionaux, le
                  RTN assure la gestion en mode projet des offres sur-mesure
                  mises en oeuvre par Union d’Experts au plus près des
                  Professionnels et des Entreprises sur le territoire.
                </p>
              </Col>
            </Row>
            <hr style={{height: "1px", color: "#4d4f574f", margin: 40}} />
          </Container>
        </div>
      </div>

      <div className="therdparts">
        <Container>
          <Row>
            <Col>
              <div className="titreSpecilat">
                <h2>Découvrez nos branches de spécialités</h2>
              </div>

              <div className="timeline">
                <div className="container">
                  <div
                    className="timeline-item"
                    data-aos="fade-right"
                    data-aos-offset="300"
                  >
                    <div className="timeline-img"></div>

                    <div className="timeline-content js--fadeInLeft">
                      <Row>
                        <Col md={5}>
                          {" "}
                          <img
                            src={Materiel}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              width: 200,
                              height: 200,
                              padding: 5,
                              marginRight: 10,
                            }}
                          />{" "}
                        </Col>
                        <Col>
                          <h2>Matériel et Risques Techniques</h2>

                          <p>
                            PRIME Solutions – Process, Réseau, Installation,
                            Matériel, Engins
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div
                    className="timeline-item"
                    data-aos="fade-left"
                    data-aos-offset="300"
                  >
                    <div className="timeline-img"></div>

                    <div className="timeline-content timeline-card js--fadeInRight">
                      <Row>
                        <Col>
                          <h2>Préjudices Économiques et Marchandises </h2>

                          <p>
                            Perte d’exploitation, arrêt d’activité, Stocks et
                            Analyse financière
                          </p>
                        </Col>
                        <Col md={5}>
                          {" "}
                          <img
                            src={prejudices}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              width: 200,
                              height: 200,
                              padding: 5,
                              marginRight: 10,
                            }}
                          />{" "}
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div
                    className="timeline-item"
                    data-aos="fade-right"
                    data-aos-offset="300"
                  >
                    <div className="timeline-img"></div>

                    <div className="timeline-content js--fadeInLeft">
                      <Row>
                        <Col md={5}>
                          {" "}
                          <img
                            src={agricol}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              width: 200,
                              height: 200,
                              padding: 5,
                              marginRight: 10,
                            }}
                          />{" "}
                        </Col>
                        <Col>
                          <h2>Agricole et Agroalimentaire</h2>

                          <p>
                            Elevage, culture, bâtiment agricole, amiante,
                            agro-industrie
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div
                    className="timeline-item"
                    data-aos="fade-left"
                    data-aos-offset="300"
                  >
                    <div className="timeline-img"></div>

                    <div className="timeline-content timeline-card js--fadeInRight">
                      <Row>
                        <Col>
                          <h2>Protection Juridique</h2>

                          <p>
                            {" "}
                            Médiation, analyse juridique, négociation,
                            accompagnements{" "}
                          </p>
                        </Col>
                        <Col md={5}>
                          {" "}
                          <img
                            src={pro}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              width: 200,
                              height: 200,
                              padding: 5,
                              marginRight: 10,
                            }}
                          />{" "}
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div
                    className="timeline-item"
                    data-aos="fade-right"
                    data-aos-offset="300"
                  >
                    <div className="timeline-img"></div>

                    <div className="timeline-content js--fadeInLeft">
                      <Row>
                        <Col md={5}>
                          {" "}
                          <img
                            src={sesche}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              width: 200,
                              height: 200,
                              padding: 5,
                              marginRight: 10,
                            }}
                          />{" "}
                        </Col>
                        <Col>
                          <h2>Sécheresse </h2>

                          <p>
                            Structures, fondations, mécanique des sols, reprise
                            en sous oeuvre
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div
                    className="timeline-item"
                    data-aos="fade-left"
                    data-aos-offset="300"
                  >
                    <div className="timeline-img"></div>

                    <div className="timeline-content timeline-card js--fadeInRight">
                      <Row>
                        <Col>
                          <h2>Vol, Mobilier et Fine Art</h2>

                          <p>
                            Evaluation mobilière, Expertise préalable, Objets
                            précieux{" "}
                          </p>
                          <a
                            className="linkeButton"
                            href="/Vol-Mobilier-et-Fine-Art"
                          >
                            <button> Découvrir </button>{" "}
                          </a>
                        </Col>
                        <Col md={5}>
                          {" "}
                          <img
                            src={vol}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              width: 200,
                              height: 200,
                              padding: 5,
                              marginRight: 10,
                            }}
                          />{" "}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <br />
        <br />
        <br />
        <br />
      </div>
      <Footter />
    </div>
  );
};

export default Specialites;
