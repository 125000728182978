import React from "react";
import "./css/style.css";

import Navbarstnd from "./Navbar/Navbarstnd";
import Navbar from "./Navbar/navbar";
import Footter from "./Footer/Footer1";

import "./css/styleMentionsLegales.css";

import { Container, Row, Col } from "react-bootstrap";
const MentionsLegales = () => {
  return (
    <div>
      <div className="backgroundSpecialite">
        <div className="backr">
          <div className="backr">
            <Navbarstnd />
          </div>
          <Navbar />
        </div>
      </div>
      <Container>
        <Row>
          <Col>
            <h1 style={{ textAlign: "center", marginBlock: "50px" }}>
              Mentions Légales
            </h1>
            <h3 className="subtitle">Hébergement</h3>
            <p>Le site www.prevexafrica.com est hébergé par OVH Cloud.</p>
            <h3 className="subtitle">Propriété intellectuelle</h3>
            <p>
              Tout le contenu de ce site, ainsi que les marques, logos et noms
              de domaine associés, bénéficient de la protection accordée par les
              lois sur la propriété intellectuelle. Prevex Africa en est le
              propriétaire ou possède les autorisations nécessaires à leur
              utilisation. Ce site ne peut être reproduit, copié, modifié,
              publié, téléchargé, altéré, transmis ou diffusé, en totalité ou en
              partie, sur quelque support que ce soit, sans l'accord écrit
              préalable de Prevex Africa, sauf dans le cas d'une utilisation
              strictement limitée à des fins d'information du public sur Prevex
              Africa, ses produits et services, dans le cadre de la presse, et à
              condition de respecter les droits de propriété intellectuelle.
            </p>
            <h3 className="subtitle">Protection des données personnelles</h3>
            <p>
              Prevex Africa collecte et traite les données personnelles en
              conformité avec la loi organique n° 63 du 27 juillet 2004 relative
              à la protection des données personnelles et le Règlement Général
              sur la Protection des Données (RGPD). L'utilisateur du site
              dispose du droit d'accéder à ses informations personnelles et de
              les rectifier, en adressant sa demande à l'adresse suivante :
              contact@uesas.com.tn. L'utilisateur du site a également la
              possibilité de s'opposer, pour des motifs légitimes, au traitement
              de ses données personnelles. Prevex Africa prend toutes les
              mesures nécessaires, en fonction de la nature des données et des
              risques associés au traitement, afin de garantir la sécurité des
              données, notamment en prévenant toute altération ou accès non
              autorisé par des tiers.{" "}
              <a href="politiques-de-confidentialité">
                La politique de confidentialité de Prevex Africa est accessible
                ici.
              </a>
              <br />
              <br />
              Le site peut contenir des liens vers les sites de tiers. Prevex
              Africa ne peut en aucun cas être tenue responsable de la
              disponibilité technique et du contenu de sites internet exploités
              par des tiers auxquels les utilisateurs accéderaient par
              l’intermédiaire du site.
              <br />
              <br />
              Tout utilisateur qui souhaite créer un lien hypertexte vers le
              site doit insérer un lien conduisant directement à la page
              d’accueil du site et mettre en évidence que le site et son contenu
              sont distinct de la page sur laquelle le lien est inséré. Les
              liens hypertextes dit profonds (deep linking) et le framing
              consistant à insérer des liens hypertextes visant à empêcher la
              reconnaissance de la paternité des contenus sont expressément
              interdit.
            </p>
            <h3 className="subtitle">Sécurité</h3>
            <p>
              Le site est protégé par des mesures physiques, administratives et
              techniques mises en place pour maintenir la sécurité, la
              confidentialité et l’intégrité des données collectées par Prevex
              Africa. Toutefois, Prevex Africa rappelle aux utilisateurs du site
              qu’il est impossible de garantir la sécurité absolue des
              transmissions de données sur internet. En conséquence, Prevex
              Africa ne peut garantir la sécurité des données transmises sur
              internet.
            </p>
            <h3 className="subtitle">Contact</h3>
            <p>
              Pour toutes questions concernant les présentes Mentions Légales,
              vous pouvez contacter Prevex Africa à l’adresse suivante : Imm
              Centre de vie, 1er étage bureau B1-4, Zone industrielle lac 3,
              Tunis, Tunisie
            </p>
          </Col>
        </Row>
      </Container>
      <Footter />
    </div>
  );
};

export default MentionsLegales;
