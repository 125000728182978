import {React ,useState ,useEffect }  from 'react';  
import { FaLinkedinIn } from "react-icons/fa";
import { IoMail} from    "react-icons/io5";
import Form from 'react-bootstrap/Form'
import './../css/style articles.css'; 
import Navbarstnd from './../Navbar/Navbarstnd';
import NavbarContact from './../Navbar/NavbarContact';
import { Container, Row , Col } from 'react-bootstrap';
import Footter from './../Footer/Footer1';
import { readOne  } from './../../api/auth'; 
import { FaPhoneAlt  , FaMapMarkerAlt } from 'react-icons/fa';
import emailjs from 'emailjs-com';

import isEmail   from 'validator/lib/isEmail';
import   isEmpty  from 'validator/lib/isEmpty'; 
import { Text} from 'react-native'; 


import AlertDanger from './../Compusnte/Alert/AlertDanger'
import AlertSuss from './../Compusnte/Alert/AlertSucc'
//import { ImageBackground } from 'react-native-web';

const Artcle = () => {
  
    const Id = (localStorage.getItem('_id'));

    console.log(Id);
    const [formdata , setformdata]  = useState ({

    _id : Id , titre :'' ,text :'' ,source :'' ,image: null ,video: null ,linke:'' ,createdAt:'',  Nom :'', heading:'',
    Email : '', 
    tel : '',  
    MESG :'', 
  
    }) ;
  
  
    
    const {
     // eslint-disable-next-line no-unused-vars
     _id ,   titre  ,text  ,source  ,image ,video ,linke ,createdAt,  Nom ,heading ,
     Email, 
     tel,
     MESG, 
    } = formdata; 
      
        useEffect(() => {
   
          readOne(formdata)
      
        .then( (response ) => {
        //  setformdata(response.data);
          console.log (response.data)
          setformdata(response.data);
      
   
    })
 // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
 
 
 

const handleChange = (evt) => {

  setformdata({
    ...formdata,
    [evt.target.name] : evt.target.value,
    errorMSg :'',
  });
};
 const handleSubmit = (evt) => {

  evt.preventDefault();
   
  if( isEmpty(Nom) ||  isEmpty(Email) || isEmpty(tel)  || isEmpty(MESG)  )  {
  
    setformdata({
        ...formdata, errorMSg : 'Tous les champs sont requis'

    });
    console.log(formdata.errorMSg);

}else if(!isEmail(Email)){
    setformdata({
      ...formdata, errorMSg : 'Email invalide' 

    });
    console.log(formdata.errorMSg);
  }else{

    




const serviceID = 'service_kkgy3mg';
const templateID = 'template_contact';
const userID = 'user_Zj2twct9L9lO0ScR9YfpZ';
        

         const {  Nom  , Email, tel , MESG  } = formdata; 
         const data = {  Nom  , Email,tel , MESG };
       
         emailjs.send(serviceID, templateID,{
             from_name: data.Nom ,
            message: data.MESG ,
            from_email: data.Email ,
            from_tel: data.tel,
            type_mess: formdata.titre ,},userID);
         
            
            setformdata({ 
              ...formdata,
              [evt.target.name] : evt.target.value,
              sussMsg :"Merci d'utiliser ce formulaire. Réponse rapide garantie !",
         
            });   

  }}

  

        return(
          <div>
                  <div className='backgroundContact'>
     <div className='backr'>
     <div className='backr'>
     <Navbarstnd />
     <NavbarContact /> 
     </div>
     </div>
   

    
     </div>
 
 
     



     <div style={{  
      backgroundImage: "url('/uploads/'"+image + ")",
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
     
    }}>
    <div className='backr'   style={{ padding :"10%", paddingBottom : "10px" ,}}
    >
    <Container>
      <Row >
      <div className="header">
      <h2 >  {titre}</h2>
      </div>
     
      </Row>
      <Row>
       
      <Col >
      <div style={{  
            marginBottom : 0 ,
            marginTop : "20%"
       
      }}>
      <h5 ><i>Title description, {createdAt.substring(0,10)}</i></h5> 
      <h5  ><i>Source : {source}</i></h5>
      <h5  ><i> linke : {linke}</i></h5>
      </div>
       
      </Col>
    
      </Row>
    </Container>
 
    </div>
    
    </div>

     
    

    <div className="row">
    <div  >
    <div className="articleText">
    <Container>
        <h2>{heading}</h2>
         
         
       <div  style={{  "font-family": "'Open Sans'" ,    fontSize: 20,  textAlign: 'justify' , "line-height": "40px"}} className='textblogdiv'>
    <Text style={{  "font-family": "'Open Sans'" ,    fontSize: 18, "line-height": "40px"}} >{ ((text.replace('\n', '. \n\n')).replace('  ', ' ')).replace('  ', ' ')}</Text> 

    </div>
<div className="video"  >
 



     {(() => {
      if(video != null)
      {
          return (
            <video 
            src={"/uploads/"+video} autoPlay muted 
            width="700" 
            shouldPlay  controls="controls" 
            
            />
          )
      }
      
      return ( null  );
    })()}

 </div>
 
<Row>

<Col>

<div className="card">
  <div className="info">
  <h2>Contactez-nous</h2>
  </div>
 
  <a href="https://www.linkedin.com/company/union-d-experts-tunisie/"><FaLinkedinIn className="iconsFooter" /> &nbsp; Linkedin</a>

  <i> <FaPhoneAlt  style={{color : "#0076bd" ,marginRight : "10px"}}/> +216 70 298 170  / +216 95 999 644 </i>
  <i> <IoMail style={{marginRight : "10px"}}   />  service.client@uesas.fr </i>
  <i><FaMapMarkerAlt style={{color : "#df2c23e3" ,marginRight : "10px"}}   /> Avenue de la bourse , les jardins de lac 1053, Immeuble Rosalys , 1er étage bureau C2 , Tunis, 1053, TN</i> 
</div>
</Col>
<Col>


<div className="card">
  <h3>Envoey un message</h3>
  <Form onSubmit={handleSubmit}>
  
  {AlertDanger(formdata.errorMSg) }
  {AlertSuss(formdata.sussMsg)}
  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
  <Form.Control type="text" placeholder="Nom et Prenom"   name="Nom"  onChange={handleChange}  value = {Nom} />
</Form.Group>
<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
<Form.Control type="number"  placeholder="Numéro de téléphone"      name="tel" onChange={handleChange} value = {tel} />
</Form.Group>
<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
  <Form.Label></Form.Label>
  <Form.Control type="email" placeholder="Address email"   name="Email"  onChange={handleChange}  value = {Email} />
</Form.Group>

<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
  <Form.Control as="textarea" rows={3} placeholder="Message"   name="MESG"  onChange={handleChange}  value = {MESG} />
</Form.Group>
<button   type="submit" className="butnn">
<div>Envoyer </div>  
</button> 

</Form>
</div>
</Col>


</Row>
 
 </Container>

    </div>


  </div>
 </div>
 <Footter />
 </div>
        );
      } 
    
    
export default Artcle ;
 
   



 