import React, {useState, useEffect} from "react";
import {Container, Row, Button ,  Col} from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import {Link} from "react-router-dom";

import {BsDownload} from "react-icons/bs";
import fileDownload from "js-file-download";
import axios from "axios";
import {useLocation} from "react-router-dom";
import {
  StatRap,
  readOneClient,
  readOneFiler,
  Table_Expert_Simister,
} from "../../../../../../api/Clinets";
import Card from "react-bootstrap/Card";

import CollapsibleExample from "./../NavbarClient";
const Stastistique = () => { 
    const location = useLocation();
    let x = 0;
    let p = 0;
    let d = 0;
  
    const reslut = [];
    const causseList = [];
    const emmdList = [];
    const Id_Filer = location.state.Id_Filer;
    const Id_CLIENT = location.state.Id_CLIENT;
    const Id_SOUS_CLIENT = location.state.Id_SOUS_CLIENT;
  
    const NameCtt = location.state.NameCtt;
    const NAME_Filer = location.state.NAME_Filer;
    const Name_sous_client = location.state.Name_sous_client;
   
    const [formdata, setformdata] = useState({
      _id: Id_Filer,
      Name_filer: "",
      Name_client: "",
      Name_SousClient: "",
    });
    const [myiduser, setMyiduser] = useState({iduser: Id_Filer});
  
    const {Name_filer, Name_client, Name_SousClient} = formdata;
    const handleClick = (url, filename) => {
      axios
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res.data, filename);
          console.log(filename);
        });
    };
  
    const [formdata2, setformdata2] = useState({
      _id: Id_CLIENT,
      EmailCt: "",
      NameCt: "",
      NumCt: "",
      Pass: "",
      TelCt: "",
    });
    const [rapport, setrapport] = useState();
  
    const [stast, setstast] = useState({
      xr: "",
      pr: "",
      dr: "",
      causseLis: null,
      emmList: null,
    });
    const {xr, pr, dr, causseLis , emmList} = stast;
    useEffect(() => {
      readOneFiler(formdata).then((response) => {
        //  setformdata(response.data);
        setformdata(response.data);
      });
  
       StatRap(myiduser).then((response) => {
        const datas = response.data.statisque;
        console.log(datas);
        setrapport(datas);
        // raps.push(... data[0].rap)
      });

      Table_Expert_Simister()
        .then((response) => {
          const data = response.data;
  
          const reslut = response.data.simster;
          reslut.map((m) => {
            if (m.ID_Filer_Client == Id_Filer) {
              x = x + 1;
              causseList.push(...m.causes);
              emmdList.push(...m.endommagees);
  
              m.rapp_preminare.map((mm) => {
                p = p + Number(mm.montant_preminare);
              });
              m.rapp_definitif.map((mm) => {
                d = d + Number(mm.montant_definitif);
              });
            }
          });
          setstast({xr: x, pr: p, dr: d, causseLis: causseList ,emmList : emmdList });
        })
        .catch((err) => {
          alert("Error retrieving data!!!");
        });
  
      readOneClient(formdata2).then((response) => {
        //  setformdata(response.data);
        setformdata2(response.data);
      });
    }, []);
  
    return (
      <div>
        <CollapsibleExample
          Id_Filer={Id_Filer}
          Id_CLIENT={Id_CLIENT}
        Id_SOUS_CLIENT = {Id_SOUS_CLIENT}

          Name_sous_client={Name_sous_client}
          NameCtt={NameCtt}
          NAME_Filer={NAME_Filer}
        />
  
        <Container>
          <h1 style={{margin: "30px"}}>Stastistique</h1>
  
          <Row>
            <Col>
              <p
                style={{
                  margin: "10px",
                  "font-size": "20px",
                  "font-family": "georgia",
                }}
              >
                {" "}
                Nom de client : {Name_client}{" "}
              </p>
            </Col>
            <Col>
              <p
                style={{
                  margin: "10px",
                  "font-size": "20px",
                  "text-align": "right",
                  "font-family": "georgia",
                }}
              >
                Numéro du compte : {formdata2.NumCt}
              </p>
            </Col>
          </Row>
          <Row>
          <p
          style={{
            "margin-left": "20px",
            "font-size": "15px",
            "font-family": "georgia",
          }}
        >
          <Link
            exact
            to={{
              pathname: "/DashBord",
              state: {
                Id_CLIENT: Id_CLIENT,
              },
            }}
          >
            {NameCtt}
          </Link>
          {">"}
          <Link
            exact
            to={{
              pathname: "/Accueil_sous_Client",
              state: {
                Id_CLIENT: Id_CLIENT,
                Id_SOUS_CLIENT: Id_SOUS_CLIENT,
                NAME_SOUSCLIENT: Name_sous_client,
                NAmeClient: NameCtt,
              },
            }}
          >
            {Name_sous_client}
          </Link>
        
                
   
              {">"}
              <Link
                to={{
                  pathname: "/Filiere",
                  state: {
                    Id_Filer: Id_Filer,
                    Id_CLIENT: Id_CLIENT,
                    NameCtt: NameCtt,
                    NAME_Filer: NAME_Filer,
                    Name_sous_client: Name_sous_client,
                  },
                }}
              >
                {NAME_Filer}
              </Link>
            </p>
          </Row>
  
          <Row style={{margin: "30px"}}>
            <Col>
              <Card
                bg={"info"}
                text={"light"}
                style={{width: "18rem"}}
                className="mb-2"
              >
                <Card.Header>Expertise Sinistre </Card.Header>
                <Card.Body style={{"text-align": "center"}}>
                  <Card.Title> Nombre de Sinistres</Card.Title>
                  <Card.Text style={{fontSize: "50px"}}>{stast.xr}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
  
            <Col>
              <Card
                bg={"info"}
                text={"light"}
                style={{width: "18rem"}}
                className="mb-2"
              >
                <Card.Header>Expertise Sinistre</Card.Header>
                <Card.Body style={{"text-align": "center"}}>
                  <Card.Title>
                    {" "}
                    Montant total des Rapports Préliminaires{" "}
                  </Card.Title>
                  <Card.Text style={{fontSize: "40px"}}>{stast.pr} DT</Card.Text>
                </Card.Body>
              </Card>
            </Col>
  
            <Col>
              <Card
                bg={"info"}
                text={"light"}
                style={{width: "18rem"}}
                className="mb-2"
              >
                <Card.Header>Expertise Sinistre</Card.Header>
                <Card.Body style={{"text-align": "center"}}>
                  <Card.Title>
                    {" "}
                    Montant total des Rapports définitifs{" "}
                  </Card.Title>
                  <Card.Text style={{fontSize: "40px"}}>{stast.dr} DT</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>


          <Row>
          <Col style={{margin: "30px"}}>
          
            <div>
              {rapport &&
                rapport.map((item) => (
                  <div key={item._id}>
                    {item.rap.map((rapItem) => (
                      <div>
                        <br />
                        <Button
                          className="btn btn-light"
                          onClick={() =>
                            handleClick(
                              "../../../../../../../../uploads/" + rapItem,
                              rapItem
                            )
                          }
                        >
                          {rapItem}
                          {"  "}
                          <BsDownload />
                        </Button>
                        <br />
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </Col>
        </Row>

          <Row>
            <Col>
            <h5>Causes de sinistres </h5>
              <ListGroup as="ol" numbered>
                {stast.causseLis ? (
                  stast.causseLis.map((item, index) => (
                    <ListGroup.Item key={index}>{item}</ListGroup.Item>
                  ))
                ) : (
                  <ListGroup.Item>This element is not visible</ListGroup.Item>
                )}
              </ListGroup>
            </Col>
  
            <Col>
            <h5>Parties endommagées </h5>
              <ListGroup as="ol" numbered>
                {stast.emmList ? (
                  stast.emmList.map((item, index) => (
                    <ListGroup.Item key={index}>{item}</ListGroup.Item>
                  ))
                ) : (
                  <ListGroup.Item>This element is not visible</ListGroup.Item>
                )}
              </ListGroup>
            </Col>
          </Row>
          <Row></Row>
        </Container>
      </div>
    );
  };
  
  export default Stastistique;
  