
import React, {   useState , useEffect} from 'react' ;
import { Container,  Row, Col } from 'react-bootstrap';
 
import { useLocation } from 'react-router-dom';
import { readOneClient  ,readOneFiler } from '../../../../api/Clinets';
import TableVisitedeperetion from './table_Visite_de_peretion'; 
import Saidbar_disable from './../../Saidbar_disable';
const  Visiteperetion7 = () =>  {


  const location = useLocation();

  console.log("id: " , location.state);
  
  const Id_CLIENT = location.state.Id_CLIENT ;   
 
  
  
 
const [formdata2 , setformdata2]  = useState ({
  _id : Id_CLIENT ,
  EmailCt: "",
  NameCt: "",
  NumCt: "",
  Pass: "",
  TelCt: "",
}) ;
   
  

useEffect(() => {
 
readOneClient(formdata2)

  .then( (response ) => {
//  setformdata(response.data);
  console.log ("hi2",response.data)
  setformdata2(response.data);

})
 
 // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
   
 

 

return (
  <div> 
  
  <Saidbar_disable Id_CLIENT={Id_CLIENT} />
  <Container fluid>
 
    
      <div   >
          <h1 style={{"margin" : "30px" }}>Visite de prévention </h1>
        
           <div>
           <Row>
           <Col>
           <p  style={{"margin" : "10px" , "font-size": "20px"  , "font-family": 'georgia'}}> Nom de client :  {formdata2.NameCt} </p>
           </Col>
           <Col>
           <p style={{"margin" : "10px" , "font-size": "20px"  , "font-family": 'georgia'}}>
           Numéro du compte :   {formdata2.NumCt} 
           </p>
           </Col>
           </Row>
         
         
         
           <Row>
           
           <Col style={{"margin" : "20px" , "font-size": "15px"   }}>
           
         
<TableVisitedeperetion  numCt= {Id_CLIENT} > </TableVisitedeperetion>
 
           </Col>
           
           </Row>

           
           
           </div>
          </div>
    
</Container>
 
  </div>


  );
} 

export default Visiteperetion7 ;