import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {NavLink} from "react-router-dom";
import "./nav.scss";

function CollapsibleExample(props) {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/Filiere",
                state: {
                  Id_Filer: props.Id_Filer,
                  Id_CLIENT: props.Id_CLIENT,
                  NameCtt: props.NameCtt,
                  NAME_Filer: props.NAME_Filer,
                  Name_sous_client: props.Name_sous_client,
                },
              }}
              activeClassName="activeClicked"
            >
              Accueil{"  "}
            </NavLink>

            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/Expertis",
                state: {
                  Id_Filer: props.Id_Filer,
                  Id_CLIENT: props.Id_CLIENT,
                  NameCtt: props.NameCtt,
                  NAME_Filer: props.NAME_Filer,
                  Name_sous_client: props.Name_sous_client,
                },
              }}
              activeClassName="activeClicked"
            >
              Expertise Sinistre{" "}
            </NavLink>

            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/visite_risque_",
                state: {
                  Id_Filer: props.Id_Filer,
                  Id_CLIENT: props.Id_CLIENT,
                  NameCtt: props.NameCtt,
                  NAME_Filer: props.NAME_Filer,
                  Name_sous_client: props.Name_sous_client,
                },
              }}
              activeClassName="activeClicked"
            >
              Visite de Risque
            </NavLink>

            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/visite de peretion",
                state: {
                  Id_Filer: props.Id_Filer,
                  Id_CLIENT: props.Id_CLIENT,
                  NameCtt: props.NameCtt,
                  NAME_Filer: props.NAME_Filer,
                  Name_sous_client: props.Name_sous_client,
                },
              }}
              activeClassName="activeClicked"
            >
              Visite de prévention{" "}
            </NavLink>

            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/risk survey",
                state: {
                  Id_Filer: props.Id_Filer,
                  Id_CLIENT: props.Id_CLIENT,
                  NameCtt: props.NameCtt,
                  NAME_Filer: props.NAME_Filer,
                  Name_sous_client: props.Name_sous_client,
                },
              }}
              activeClassName="activeClicked"
            >
              Risk Survey
            </NavLink>

            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/Estimation prelible",
                state: {
                  Id_Filer: props.Id_Filer,
                  Id_CLIENT: props.Id_CLIENT,
                  NameCtt: props.NameCtt,
                  NAME_Filer: props.NAME_Filer,
                  Name_sous_client: props.Name_sous_client,
                },
              }}
              activeClassName="activeClicked"
            >
              Estimation préalable
            </NavLink>

            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/stastistique",
                state: {
                  Id_Filer: props.Id_Filer,
                  Id_CLIENT: props.Id_CLIENT,
                  NameCtt: props.NameCtt,
                  NAME_Filer: props.NAME_Filer,
                  Name_sous_client: props.Name_sous_client,
                },
              }}
              activeClassName="activeClicked"
            >
              Statistique
            </NavLink>

            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/",
              }}
            >
              Déconnexion
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;
