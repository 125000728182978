import React, {useState, useEffect} from "react";
import {Container, Button, Row, Col} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {
  readOneClient,
  Table_Expert_Simister_exacte,
} from "../../../../../api/Clinets";
import {Link} from "react-router-dom";
import AjouterFiler from "./Ajouter_Filer";
import Listfiler from "./List_filer";
import NavbarClientleve2 from "./NavbarClientleve2";
import ItemListtoutl from "./Itemtous";

const Accueil_sous_Client = (porps) => {
  const location = useLocation();
  let x = 0;
  const [stast, setstast] = useState({
    xr: "",
  });
  const {xr} = stast;

  const Id = location.state.Id_CLIENT;
  const NAME = location.state.NAME_SOUSCLIENT;
  const NAmeClient = location.state.NAmeClient;
  const Id_SOUS_CLIENT = location.state.Id_SOUS_CLIENT;
  const [formdata, setformdata] = useState({
    _id: Id,
    EmailCt: "",
    NameCt: "",
    NumCt: "",
    Pass: "",
    TelCt: "",
  });

  const {NameCt} = formdata;
  const [myiduser, setMyiduser] = useState({iduser: Id_SOUS_CLIENT});

  useEffect(() => {
    Table_Expert_Simister_exacte(myiduser)
      .then((response) => {
        const data = response.data;

        const reslut = response.data.simster;
        console.log(reslut.length);
        x = reslut.length;

        setstast({xr: x});
      })
      .catch((err) => {
        alert("Error retrieving data!!!");
      });

    readOneClient(formdata).then((response) => {
      //  setformdata(response.data);
      setformdata(response.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gohome = () => {
    window.location = "/HomeAdmin";
  };

  return (
    <div>
      <NavbarClientleve2
        Id_SOUS_CLIENT={Id_SOUS_CLIENT}
        NAME_SOUSCLIENT={NAME}
        NAmeClient={NAmeClient}
        Id_CLIENT={Id}
      />
      <Container>
        <Row>
          <div>
            <Row>
              <Col>
                <h1 style={{margin: "30px"}}>Accueil</h1>
              </Col>
              <Col md={{span: 3, offset: 3}}>
                <Button
                  style={{margin: "30px"}}
                  onClick={gohome}
                  className="btn-info"
                >
                  Retour Menu Principal
                </Button>
              </Col>
            </Row>

            <div>
              <Row>
                <Col>
                  <p
                    style={{
                      margin: "10px",
                      "font-size": "20px",
                      "font-family": "georgia",
                    }}
                  >
                    {" "}
                    Nom de client : {NameCt}{" "}
                  </p>
                </Col>
                <Col>
                  <p
                    style={{
                      margin: "10px",
                      "font-size": "20px",
                      "font-family": "georgia",
                    }}
                  >
                    Numéro du compte : {formdata.NumCt}
                  </p>
                </Col>
              </Row>
              <Row>
                <p
                  style={{
                    "margin-left": "20px",
                    "font-size": "15px",
                    "font-family": "georgia",
                  }}
                >
                  <Link
                    exact
                    to={{
                      pathname: "/Admin/accuile",
                      state: {
                        Id_CLIENT: Id,
                      },
                    }}
                  >
                    {NameCt}
                  </Link>
                  {">"}
                  <Link
                    exact
                    to={{
                      pathname: "/Admin/GroupSousClient",
                      state: {
                        Id_CLIENT: Id,
                        Id_SOUS_CLIENT: Id_SOUS_CLIENT,
                        NAME_SOUSCLIENT: NAME,
                        NAmeClient: NAmeClient,
                      },
                    }}
                  >
                    {NAME}
                  </Link>
                </p>
              </Row>
              <Row>
                <Col md={{span: 3}}>
                  <p
                    style={{
                      margin: "10px",

                      "font-size": "20px",
                      "font-family": "georgia",
                    }}
                  ></p>
                </Col>
              </Row>

              <Row className=" justify-content-md-center ">
                <Col md="6">
                  <Row>
                    <Col md="12"></Col>
                    <ItemListtoutl
                      idcl={Id_SOUS_CLIENT}
                      NAME_SOUSCLIENT={NAME}
                    />
                  </Row>
                </Col>
              </Row>
              <Row>
                <Listfiler
                  Id_client={Id}
                  Id_SOUS_CLIENT={Id_SOUS_CLIENT}
                  NameCt={NAME}
 
                ></Listfiler>
              </Row>
              <br />
              <Row>
                <AjouterFiler
                  namect={NAME}
                  Name_Clent={NAmeClient}
                ></AjouterFiler>
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Accueil_sous_Client;
