
import React, {   useState  , useEffect} from 'react' ;
import { Container} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { readOnesiniter  } from '../../../../../../../../api/Clinets'; 
const  Modifier_sinistre = () =>  {


  const location = useLocation();
  
  console.log("id: " , location.state);
  const Id = location.state; 

   
     
const [formdata , setformdata]  = useState ({
    numClt :  Id ,
    rapp : [] ,
    numSIM: "",
    DateSim: "",
    NumCont: "",
    ID_Filer_Client: "",
    
}) ;




 
 

 



useEffect(() => {
     
    readOnesiniter(formdata)

  .then( (response ) => {
//  setformdata(response.data);
  console.log ("hi",response.data)
  setformdata(response.data);

  

}) 
 
 // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
 

  

 

return (
  <div>

  <Container >
  <div style={{
    margin : "20%",
    "border-radius" : "30px" ,
     
  }}>
  </div>
    
  
  
</Container>
 
  </div>


  );
} 

export default Modifier_sinistre ;