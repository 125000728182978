import React from "react";
import { ClientList } from "../../../api/Clinets";
import {   Row, Col  } from "react-bootstrap";
import { Link } from "react-router-dom";
import ItemListtoutl from "../../Adimn/Gestion de compts/Accuile/sous_cleint/Itemtous";
 
class ListClient extends React.Component {
  state = {
    NameCt: "",

    posts: [],
  };

  componentDidMount = () => {
    this.getBlogPost();
  };

  getBlogPost = () => {
    ClientList()
      .then((response) => {
        const data = response.data;
        this.setState({ posts: data.sousClient });
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  };

  render() {
    const num = this.props.numCt;
    const Id = this.props.Id;
    const name_Client = this.props.Name_Client;

    return (
      <div>
        <Row className=" justify-content-md-center " >
          <Col md="6"  >
            <Row>
              <Col  md="12" >
                <ul className="list-group">
                  {this.state.posts.map((post) => {
                    if (post.numClt === num) {
                      return (
                        <li
                          style={{ marginLeft: 10 }}
                          className="list-group-item"
                        >
                          {post.NameCt}
                          {"  "}
                          <ItemListtoutl idcl={post._id} NAME_SOUSCLIENT={post.NameCt} />
                          <Link
                            className="float-right btn btn-info btn-sm"
                            to={{
                              pathname: "/Accueil_sous_Client",
                              state: {
                                NAME_SOUSCLIENT: post.NameCt,
                                Id_CLIENT: Id,
                                NAmeClient :name_Client ,
                                Id_SOUS_CLIENT : post._id,

                              },
                            }}
                          >
                          {console.log( "test" , post._id)}
                            Consulter
                          </Link>
                        </li>
                      );
                    }
                    return true ;
                  })}
                </ul>
              </Col>
            </Row>
            
           
          </Col>
        </Row>
      </div>
    );
  }
}

export default ListClient;
