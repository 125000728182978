
import React from 'react'
import {  Container , Row, Col } from 'react-bootstrap';
import "./../../../style2.scss" ; 
import "./../../../css/style.css"
 
import Navbarstnd from '../../../Navbar/Navbarstnd'; 
import Card2 from '../Card2';
import Card3 from '../Card3';
import Card4 from '../Card4';
import Card5 from '../Card5';
import Card6 from '../Card6';
import Card7 from '../Card7';
import Card from '../Card';
import Card9 from '../Card9';
import Card10 from '../Card10';
import ApprochFormulaire from './../../approche/ApprochFormulaire';
import NavbarOffres from './../../../Navbar/NavbarOffres';
import Footter from './../../../Footer/Footer1';

const Dangerrisqueaccident = ()=> { 
       
    return ( 
 
        <div>
      
        <div className='backgroundOffres'>
        <div className='backr'>
                <div className='backr'>
                <Navbarstnd />
                </div>
                <NavbarOffres /> 
        <div style={{paddingBottom : 30 , margin : 40 }} ></div>
      </div>
      </div>
   
   
      <div className="secandparts">
       
      <div className='secands'>
      <Container>
      <Row className=' justify-content-md-center'> 
        <Col   style={{marginTop : 30  }} >
          <h1 >Danger, risque, accident : des notions communes    </h1>
           <p>
           La prévention des risques industriels, qu’ils soient professionnels ou environnementaux, s’appuie sur les principales notions suivantes : danger, risque, accident ou dommage. Le Code du travail et le Code de l’environnement définissent le risque de la même manière ; la notion d’exposition d’une cible à un danger y est intégrée.
           </p>
           <p>
           Les deux codes exigent une évaluation des risques, sur laquelle va reposer sur une identification des dangers puis une analyse détaillée des conditions d’exposition aux dangers.
           </p>
            </Col>  
            </Row>
   

         
            </Container>   
            </div>

            <div style={{marginTop : "10%",marginBottom : "5%" }}>
            <ApprochFormulaire />
            
            </div>

            
            <div className="autreOffer">
            <Container>
            <Row className=' justify-content-md-center'> 
          <h1 > Nos autres offres   </h1>
          <Col md={4}>
          <Card />
          </Col>
            <Col md={4}>
            <Card2 />
            </Col> 
            <Col md={4}>
            <Card3 />
            </Col>   <Col md={4}>
            <Card4 />
            </Col>   <Col md={4}>
            <Card5 />
            </Col>   <Col md={4}>
            <Card6 />
            </Col>   <Col md={4}>
            <Card7 />
            </Col>    <Col md={4}>
            <Card9 />
            </Col>   <Col md={4}>
            <Card10 />
            </Col> 
            </Row>
            </Container>   
            </div>



      </div>
   
    
   
    
      <Footter /> 
   
   
   </div>  
      
   
);
}
export default Dangerrisqueaccident ;

 
