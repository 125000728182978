import React, {useState, useEffect} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {readOneFiler, readOneClient} from "../../../../../../api/Clinets";
import AjouterSmister from "./Details/AjouterSmister";
import TableauSimster from "./Details/tableSimiste";
import {useLocation} from "react-router-dom";
import NavbarClientleve1 from "../../NavbarClientleve1";

const ExpertisSinister7 = () => {
  const location = useLocation();

  const Id_CLIENT = location.state.Id_CLIENT;

  const [formdata2, setformdata2] = useState({
    _id: Id_CLIENT,
    EmailCt: "",
    NameCt: "",
    NumCt: "",
    Pass: "",
    TelCt: "",
  });

  useEffect(() => {
    readOneClient(formdata2).then((response) => {
      //  setformdata(response.data);
      console.log("hi2", response.data);
      setformdata2(response.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <NavbarClientleve1 Id_CLIENT={Id_CLIENT} />

      <Container fluid>
        <h1 style={{margin: "30px"}}> Expertise Sinistre</h1>

        <div>
          <Row>
            <Col>
              <p
                style={{
                  margin: "10px",
                  "font-size": "20px",
                  "font-family": "georgia",
                }}
              >
                {" "}
                Nom de client : {formdata2.NameCt}{" "}
              </p>
            </Col>
            <Col>
              <p
                style={{
                  margin: "10px",
                  "font-size": "20px",
                  "font-family": "georgia",
                }}
              >
                Numéro du compte : {formdata2.NumCt}
              </p>
            </Col>
          </Row>

          <Row>
            <Col style={{margin: "20px", "font-size": "15px"}}>
              <TableauSimster Id_CLIENT={Id_CLIENT}> </TableauSimster>
            </Col>
          </Row>

          <Row>
            <Row>
              <div>
                <AjouterSmister Id_CLIENT={Id_CLIENT}> </AjouterSmister>
              </div>
            </Row>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ExpertisSinister7;
