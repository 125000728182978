import React from "react";
import "./../../css/style.css";
import {FaPhoneAlt} from "react-icons/fa";
import {Link} from "react-router-dom";

const ApprochFormulaire = () => {
  return (
    <div>
      <div className="ApprocheFrom">
        <p>
          Pour avoir une présentation de notre cabinet et nos services n’hésitez
          pas à nous contacter directement en appelant &emsp;
          <FaPhoneAlt />
          {"  "}(00 216) 70 298 170 / 95 999 644 <br /> via notre
          <Link
            to={{
              pathname: "/Form",
              state: "Formulaire de contact",
            }}
            style={{color: "#081b36"}}
          >
            {" "}
            formulaire de contact
          </Link>{" "}
        </p>
      </div>
    </div>
  );
};

export default ApprochFormulaire;
