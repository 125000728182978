



import React  from 'react';


  function AlertDanger(text) { 
    if (!(text)  ){
  
    return <div> 
    
  </div>
  }else{
    return <div> 
    <div className="alert alert-danger" role="alert">
  {text}
  </div>
  </div>
  
  }}  
   







export default  AlertDanger  ;