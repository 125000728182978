import {React ,useState ,useEffect }  from 'react';  
import { Container,Button, Row , Col } from 'react-bootstrap';
import { readOne  } from './../../../api/auth'; 
import {Link, useLocation} from "react-router-dom";
import './../../css/style.css' ; 
import { Text} from 'react-native'; 
import NavbarAdmin from '../../Navbar/NavbarAdmin';
import { isAuthenticated} from '../../../helpers/auth';



const LireAdmin = () => {
    const location = useLocation();
  
    console.log(location.state);
    const Id = location.state;  
    const [formdata , setformdata]  = useState ({

    _id : Id , titre :'' ,text :'' ,source :'' ,image: null ,video: null ,linke:'' ,createdAt:'',
  
    }) ;
  
  
    
    const {
     _id ,   titre  ,text  ,source  ,image ,video ,linke ,createdAt,
    } = formdata; 
      
        useEffect(() => {
   
          readOne(formdata)
      
        .then( (response ) => {
        //  setformdata(response.data);
          console.log (response.data)
          setformdata(response.data);
      
   
    })

  }, [formdata])

 
        return(
          <div> 
   
          {(() => {
            if(isAuthenticated())
            {
                return (
                  <div>

     
                  <NavbarAdmin /> 


                  <Container>
                  <Row className="justify-content-md-center">
                    <Col xs lg="2">
                    <Link    to={{
                      pathname: "/Modifier",
                      state: _id  ,
                      }} >
                    <Button className='btnLir' variant="outline-info">Modifier</Button>
                   </Link>
                    </Col>
                    <Col xs lg="2">
                    <Link    to={{
                      pathname: "/Supprimer",
                      state: _id  ,
                      }} >
                       <Button  className='btnLir' variant="outline-danger">Supprimer</Button>
                   </Link>
                    </Col>
                  </Row>
                  </Container>
           
         
       
         

     
    <div className="header">
        <h2>{titre}</h2>
        <hr className='style-one'/>
        <Container>
          <Row>
           
          <Col>
            <h5 className='dateh5'><i>Title description, {createdAt.substring(0,10)}</i></h5> 
            <h5 className='dateh5' ><i>Source : {source}</i></h5>
            <h5 className='dateh5' ><i> linke : {linke}</i></h5>
          </Col>
        
          </Row>
        </Container>
    </div>

    <div className="row">
    <div  >
    <div className="card">
    <Container>
        <h2>TITLE HEADING</h2>
         
        <div >
        {(() => {
          if(image != null)
          {
              return (
                 <img  alt="" style={{"height":"300px" , "object-fit": "cover"}} className="fakeimg" width='90%' src = {'/uploads/'+image }  />
              )
          }
          
          return (<p>no image</p>);
        })()}
        
        
       </div>
       <div  style={{  "font-family": "'Open Sans'" ,    fontSize: 20,  textAlign: 'justify' , "line-height": "40px"}} className='textblogdiv'>
    <Text style={{  "font-family": "'Open Sans'" ,    fontSize: 18, "line-height": "40px"}} >{ ((text.replace('\n', '. \n\n')).replace('  ', ' ')).replace('  ', ' ')}</Text> 

    </div>
<div className="video"  >







     {(() => {
      if(video != null)
      {
          return (
            <video 
            src={"/uploads/"+video} autoPlay muted 
            width="700" 
            shouldPlay  controls="controls" 
            
            />
          )
      }
      
      return (<p>no video</p>);
    })()}
    
 </div>

 </Container>

    </div>


  </div>
 </div>
 
 </div>
 )
}else{ window.location = '/loginAdmin'}

return null;
})()}

 </div>
        );
      } 
    
export default LireAdmin ;
 
   



 