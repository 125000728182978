import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./../../pages/css/style.css";
import logov from "./../../img/PREV-EX AFRICA.png";
import { FaLinkedinIn } from "react-icons/fa";
//import MapContainer from './Maps';

const Footter = () => {
  return (
    <div>
      <div className="footer">
        <Container>
          <Row>
            <Col style={{ "margin-top": "50px " }}>
              {" "}
              <img src={logov} className="logoFotter" alt="Logo PREVEX" />
            </Col>

            <Col>
              <Row>
                <p className="titreFooter">INFORMATIONS</p>
              </Row>
              <Row>
                <a href="plan-du-site">Plan du site</a>
              </Row>
              <Row>
                <a href="mentions-légales">Mentions légales</a>
              </Row>
              <Row>
                <a href="politiques-de-confidentialité">
                  Politique de Confidentialité
                </a>
              </Row>
            </Col>

            <Col>
              <p className="titreFooter">SUIVEZ-NOUS SUR LES RÉSEAUX </p>
              <div>
                <a href="https://www.linkedin.com/company/union-d-experts-tunisie/">
                  <FaLinkedinIn className="iconsFooter" /> &nbsp; Linkedin
                </a>
              </div>
            </Col>
            <Col></Col>
          </Row>

          <Row>
            <div className="buttombutter">
              <p>{new Date().getFullYear()} © Union d'Experts</p>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Footter;
