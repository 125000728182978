
import React from 'react'
import {   Container  , Row, Col } from 'react-bootstrap';
import "./../../../style2.scss" ; 
import "./../../../css/style.css";
import {Link } from "react-router-dom";
 
import Navbarstnd from '../../../Navbar/Navbarstnd'; 
import Card3 from '../Card3';
import Card4 from '../Card4';
import Card5 from '../Card5';
import Card6 from '../Card6';
import Card7 from '../Card7';
import Card9 from '../Card9';
import Card10 from '../Card10';
import Card from './../Card';
import NavbarOffres from './../../../Navbar/NavbarOffres';
import Footter from './../../../Footer/Footer1';

const APCI = ()=> { 
       
    return ( 
 
        <div>
      
        <div className='backgroundOffres'>
        <div className='backr'>
                <div className='backr'>
                <Navbarstnd />
                </div>
                <NavbarOffres/> 
        <div style={{paddingBottom : 30 , margin : 40 }} ></div>
      </div>
      </div>
   
   
      <div className="secandparts">
       
      <div className='secands'>
      <Container>
      <Row className=' justify-content-md-center'> 
        <Col   style={{marginTop : 30  }} >
          <h1 > APCI : audit prévention et conseil incendie </h1>
           <p>
           Processus intégrant la réalisation d’une analyse de risque incendie et/ou d’une analyse de vulnérabilité incendie
           </p>
           <p>
           Demandeur de la mission APCI
           </p> 
           
           <p>
           Entité à l’origine de la réalisation de la mission APCI au sein de l’organisme. Il peut s’agir de l’organisme lui-même ou d’une structure de financement de type banque d’un client/donneur d’ordre, d’un courtier, d’un assureur ou d’un réassureur avant ou après la souscription d’un contrat d’assurances.
           </p>
            </Col>  
            </Row>
   
            <Row>
            <Link    to={{
                pathname: "/Form",
                state: "APCI : audit prévention et conseil incendie "  ,
                }} >
                <button className='demandeBTN'>Demander un devis</button>
             </Link>
                </Row>
            </Container>   
            </div>


            
            <div className="autreOffer">
            <Container>
            <Row className=' justify-content-md-center'> 
          <h1 > Nos autres offres   </h1>
                
        

          <Col md={4}>
          <Card />
      </Col> 
       
      <Col md={4}>
           <Card3 />
      </Col> 
      <Col md={4}>
           <Card4 />
      </Col> 
      <Col md={4}>
            <Card5 />
      </Col>  
       <Col md={4}>
           <Card6 />
      </Col>   
      <Col md={4}>
            <Card7 />
      </Col>    
       <Col md={4}>
           <Card9 />
      </Col>   
      <Col md={4}>
            <Card10 />
      </Col> 


    
            </Row>
            </Container>   
            </div>



      </div>
   
    
   
    
   
   
      <Footter /> 
   </div>  
      
   
);
}
export default APCI ;

 
