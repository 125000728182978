import { Container, Row, Form, Button } from 'react-bootstrap'; 
import React , {useState  ,useEffect} from 'react';
import './../../css/style.css' ; 
import NavbarAdmin from '../../Navbar/NavbarAdmin';
import { Addvideo , readOne} from '../../../api/auth';
import { isAuthenticated} from '../../../helpers/auth';
import Card from 'react-bootstrap/Card';

import { useLocation} from "react-router-dom"; 

const Ajoutervideo = ()=> { 
    
  const location = useLocation();
   
  console.log(location.state);
  const Id = location.state; 

    const [formdata , setformdata]  = useState ({

      _id : Id ,titre :'' ,text :'' ,source :'' ,image: null ,video: null ,linke:'' ,heading:'',errorMSg :'',
    
    
      }) ;
    
    
    
      const {
        _id,    titre  ,text  ,source  ,image ,video ,linke ,heading ,
      } = formdata; 
        

      useEffect(() => {
          
        readOne(formdata)
        
       .then( (response ) => {
           const dataArticles = response.data ;
             
            console.log (dataArticles)
            setformdata(dataArticles);
        
     
      }) }, [formdata])  
      const handleChangeFiles = (evt) => {
      
        setformdata({
          ...formdata,
          [evt.target.name] : evt.target.files[0],
        });
     };

     
    
     
    
     
     
    const handleSubmit = (evt) => {
      
        evt.preventDefault();
        

      let formData = new FormData();

      formData.append('_id',_id);
      formData.append('titre', titre);
      formData.append('text', text);
      formData.append('source', source);
      formData.append('image', image);
      formData.append('video', video);
      formData.append('linke', linke);
      formData.append('heading', heading);
        
      
      Addvideo(formData)

        .then(response => {
          alert('Vous avez ajout LE VIDEO ');
           window.location = '/HomeAdmin' ; 
        
        })
        .catch ((err,response) =>{
          console.log('login error est',err);
          
        });
      

 
  }
    return ( 
 
        <div>
        {(() => {
          if(isAuthenticated())
          {
              return (
                
                  <div>   
                   <NavbarAdmin />
       


                  <div>
                  <Container>
                  <div style={{'marginTop':'30px'}}>

                  <Card style={{'padding': '0'}}>
                  <Card.Header></Card.Header>
                
                  <Card.Body>
                    <Card.Title>Vous ajouterez une vidéo à cet article : {titre}</Card.Title>

                  <Row>
                   
                  <Form  onSubmit={handleSubmit} >
                 
        <Row className="mb-3">
                     
      
                      <Form.Group  className='custom-file' controlId="formGridName ">
                  <Form.Label className='custom-file-label'>Ajouter une video... </Form.Label>
                  <Form.Control  
                  className='custom-file-input'
                  type ='file'
                  name="video"
                  onChange={handleChangeFiles}
                   /> 
              </Form.Group>
                  </Row>
            
       
                  <Button variant="primary" type="submit">
                      Ajouter
                  </Button>
              </Form>
                      
                 
               
              </Row>
              </Card.Body>
              </Card>
                 </div>
                  </Container>
                     </div>  </div>
                  )
              }else{ window.location = '/loginAdmin'}
              
              return null;
            })()}
     
        </div>
 
      );
    } 

export default  Ajoutervideo  ;