import React from "react";
import { Link } from "react-router-dom";
import Navbarstnd from "./Navbar/Navbarstnd";
import Navbar from "./Navbar/navbar";
import Footter from "./Footer/Footer1";

import { Container, Row, Col } from "react-bootstrap";

const Politiques = () => {
  return (
    <div>
      <div className="backgroundSpecialite">
        <div className="backr">
          <div className="backr">
            <Navbarstnd />
          </div>
          <Navbar />
        </div>
      </div>
      <Container>
        <Row>
          <Col>
            <div>
              <h1 style={{ textAlign: "center", marginBlock: "50px" }}>
                Politique de confidentialité
              </h1>

              <p>
                Cette politique de confidentialité décrit comment nous
                collectons, utilisons et protégeons les informations
                personnelles que vous nous fournissez lorsque vous visitez notre
                site web.
              </p>
              <h2 className="subtitle">Collecte d'informations personnelles</h2>
              <p>
                Lorsque vous visitez notre site web, nous pouvons collecter
                certaines informations personnelles vous concernant, telles que
                votre nom, votre adresse e-mail et votre numéro de téléphone.
                Nous collectons ces informations uniquement lorsque vous les
                fournissez volontairement en remplissant des formulaires de
                contact ou en vous abonnant à notre newsletter.
              </p>
              <h2 className="subtitle">Utilisation des informations</h2>
              <p>
                Les informations personnelles que nous collectons sont utilisées
                dans le but de vous fournir les services et les informations
                demandés, de vous contacter concernant vos demandes et de vous
                envoyer des communications marketing si vous avez donné votre
                consentement à cet effet.
              </p>
              <h2 className="subtitle">Protection des informations</h2>
              <p>
                Nous prenons au sérieux la protection de vos informations
                personnelles. Nous mettons en œuvre des mesures de sécurité
                appropriées pour protéger vos informations contre tout accès non
                autorisé, utilisation abusive ou divulgation.
              </p>
              <h2 className="subtitle">Cookies</h2>
              <p>
                Notre site web peut utiliser des cookies pour améliorer votre
                expérience. Les cookies sont de petits fichiers texte placés sur
                votre appareil lorsque vous visitez notre site. Vous pouvez
                configurer votre navigateur pour refuser tous les cookies ou
                pour indiquer quand un cookie est envoyé.
              </p>
              <h2 className="subtitle">
                Modification de la politique de confidentialité
              </h2>
              <p>
                Nous nous réservons le droit de modifier cette politique de
                confidentialité à tout moment. Les modifications prendront effet
                dès leur publication sur notre site web.
              </p>
              <p>
                Pour toute question concernant notre politique de
                confidentialité, veuillez nous contacter à l'adresse e-mail
                suivante : contact@uesas.com.tn.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footter />
    </div>
  );
};

export default Politiques;
