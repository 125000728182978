import React from "react";
import "./../css/style.css";
import Navbarstnd from "./../Navbar/Navbarstnd";
import NavbarContact from "./../Navbar/NavbarContact";
import CardActualites from "./CardActualites";
import Footter from "../Footer/Footer1";

class Actualites extends React.Component {
  render() {
    return (
      <div style={{background: "#f3f5f7"}}>
        <div className="backgroundContact">
          <div className="backr">
            <div className="backr">
              <Navbarstnd />
              <NavbarContact />
            </div>
          </div>
          <div></div>
        </div>
        <CardActualites />

        <Footter />
      </div>
    );
  }
}

export default Actualites;
