import axios from 'axios' ;



export const login = async (data) => {
    const config = {
        headers : {
            'Content-Type' : 'application/json',
        },
    };
     
    const response = await axios.post('/api/clientAuth/login',data,config);
    return response ;
};
