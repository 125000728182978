import React, {useState, useEffect} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {readOneClient, readOneFiler} from "../../../../../../api/Clinets";
import NavbarClientleve2 from "../../NavbarClientleve2";
import TableVISITEDERISQUE from "./Table_VISITE_DE_RISQUE";
import {Link} from "react-router-dom";

const Visite_risque5 = () => {
  const location = useLocation();

  console.log("id: ", location.state);

  const Id_CLIENT = location.state.Id_CLIENT;
  const NAME = location.state.NAME_SOUSCLIENT;
  const NAmeClient = location.state.NAmeClient;
  const Id_SOUS_CLIENT = location.state.Id_SOUS_CLIENT;

  const [formdata2, setformdata2] = useState({
    _id: Id_CLIENT,
    EmailCt: "",
    NameCt: "",
    NumCt: "",
    Pass: "",
    TelCt: "",
  });

  useEffect(() => {
    readOneClient(formdata2).then((response) => {
      //  setformdata(response.data);
      console.log("hi2", response.data);
      setformdata2(response.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <NavbarClientleve2
        Id_SOUS_CLIENT={Id_SOUS_CLIENT}
        NAME_SOUSCLIENT={NAME}
        NAmeClient={NAmeClient}
        Id_CLIENT={Id_CLIENT}
      />{" "}
      <Container fluid>
        <div>
          <h1 style={{margin: "30px"}}> Visite de Risque</h1>

          <div>
            <Row>
              <Row>
                <p
                  style={{
                    "margin-left": "20px",
                    "font-size": "15px",
                    "font-family": "georgia",
                  }}
                >
                  <Link
                    exact
                    to={{
                      pathname: "/DashBord",
                      state: {
                        Id_CLIENT: Id_CLIENT,
                      },
                    }}
                  >
                    {formdata2.NameCt}
                  </Link>
                  {">"}
                  <Link
                    exact
                    to={{
                      pathname: "/Accueil_sous_Client",
                      state: {
                        Id_CLIENT: Id_CLIENT,
                        Id_SOUS_CLIENT: Id_SOUS_CLIENT,
                        NAME_SOUSCLIENT: NAME,
                        NAmeClient: NAmeClient,
                      },
                    }}
                  >
                    {NAME}
                  </Link>
                </p>
              </Row>
              <Col>
                <p
                  style={{
                    margin: "10px",
                    "font-size": "20px",
                    "font-family": "georgia",
                  }}
                >
                  Numéro du compte : {formdata2.NumCt}
                </p>
              </Col>
            </Row>

            <Row>
              <Col style={{margin: "20px", "font-size": "15px"}}>
                <TableVISITEDERISQUE numCt={Id_SOUS_CLIENT}>
                  {" "}
                </TableVISITEDERISQUE>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Visite_risque5;
