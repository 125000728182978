import React, { Component } from 'react' ;
import NavbarAdmin from '../../../Navbar/NavbarAdmin';
import { Container , Tabs ,Tab} from 'react-bootstrap';
import AjouterUnClient from './AjouterClient'; 
import ListeScoiete from './ListeScoiete';
export default class GestiondDeComptes extends Component {
  render() {
    return (
        <div>
            <NavbarAdmin />
                <Container >
                <div style={{"marginTop" : "30px"}} ></div>
                <Tabs defaultActiveKey="listeScoiete" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="listeScoiete" title="liste des sociétés ">
                <ListeScoiete />
                </Tab>
                    <Tab eventKey="home" title="Ajouter un client">
                        <AjouterUnClient />
                    </Tab>
                   
               
  
                </Tabs>
                </Container>
        </div>
     
    )
  }
}