import React  from 'react'; 
import { Container, Row  } from 'react-bootstrap'; 
import NavbarAdmin from '../../Navbar/NavbarAdmin';
import CardConsulterAdmin from './CardConsulter';
import { isAuthenticated} from '../../../helpers/auth';

const ConsulterARTicle = ()=> { 
    

    return ( 
 
        <div>
        {(() => {
          if(isAuthenticated())
          {
              return (
                <div>
             <NavbarAdmin /> 
            <div className="ConsulterARTicle"  >
            <Container>
            <div style={{fontSize : "16px" ,margin :"60px" , textAlign :"center"}}>
              <h1>La liste des articles</h1>
            </div>
            <Row>
            <CardConsulterAdmin />
            
            </Row>
            
            </Container>
            </div>
            </div>
            )
          }else{ window.location = '/loginAdmin'}
          
          return null;
        })()}
        </div>
 
      );
    } 

export default  ConsulterARTicle  ;