
import React, {   useState , useEffect} from 'react' ;
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { readOneClient ,  } from '../../../../api/Clinets';
 
import Listfiler from './List_filer';
import Saidbardisable from './../../Saidbar_disable';
import NavbarClientleve2 from './NavbarClientleve2';
import ItemListtoutl from "../../../Adimn/Gestion de compts/Accuile/sous_cleint/Itemtous";
import {Link} from "react-router-dom";

const  Accueil_sous_Client2 = () =>  {



  const location = useLocation();

 
    const Id = location.state.Id_CLIENT; 
    const NAME = location.state.NAME_SOUSCLIENT ; 
    const NAmeClient = location.state.NAmeClient ; 
 
const Id_SOUS_CLIENT = location.state.Id_SOUS_CLIENT ;

 

     
     
  const [formdata , setformdata]  = useState ({
    _id : Id ,
    EmailCt: "",
    NameCt: "",
    NumCt: "",
    Pass: "",
    TelCt: "",
}) ;



const {
NameCt ,
} = formdata; 
 

useEffect(() => {
     
  readOneClient(formdata)

  .then( (response ) => {
//  setformdata(response.data);
  console.log ("hi",response.data)
  setformdata(response.data);


})

  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
 

 

return (
  <div>
  <NavbarClientleve2   Id_SOUS_CLIENT={Id_SOUS_CLIENT}
  NAME_SOUSCLIENT={NAME}
  NAmeClient={NAmeClient}
  Id_CLIENT={Id}/>
  <Container fluid>
  <Row> 


      <Col   xs={10} id="page-content-wrapper">
    
      <div   >
          <h1 style={{"margin" : "30px" }}>Accueil </h1>
        
           <div>
           <Row>
           <Col>
           <p  style={{"margin" : "10px" , "font-size": "20px"  , "font-family": 'georgia'}}> Nom de client :  {NameCt} </p>
           </Col>
           <Col>
           <p style={{"margin" : "10px" , "font-size": "20px"  , "font-family": 'georgia'}}>
           Numéro du compte :   {formdata.NumCt} 
           </p>
           </Col>
           </Row>
           <Row> <p
           style={{
             "margin-left": "20px",
             "font-size": "15px",
             "font-family": "georgia",
           }}
         >
           <Link
             exact
             to={{
               pathname: "/DashBord",
               state: {
                 Id_CLIENT: Id,
               },
             }}
           >
             {NAmeClient}
           </Link>
           {">"}
           <Link
             exact
             to={{
               pathname: "/Accueil_sous_Client",
               state: {
                 Id_CLIENT: Id,
                 Id_SOUS_CLIENT: Id_SOUS_CLIENT,
                 NAME_SOUSCLIENT: NAME,
                 NAmeClient: NAmeClient,
               },
             }}
           >
             {NAME}
           </Link>
         </p> </Row>

           <Row className=" justify-content-md-center ">
           <Col md="6">
             <Row>
               <Col md="12"></Col>
               <ItemListtoutl
                 idcl={Id_SOUS_CLIENT}
                 NAME_SOUSCLIENT={NAME}
               />
             </Row>
           </Col>
         </Row>
           <Row>
            <Listfiler Id_client={Id}  NameCt={NAME} Id_SOUS_CLIENT ={Id_SOUS_CLIENT}></Listfiler>
           
           </Row>
           <br />
         
          
           </div>
          </div>
      </Col> 
  </Row>

</Container>
 
  </div>


  );
} 

export default Accueil_sous_Client2 ;