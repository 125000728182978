
import React from 'react'
import {  Container  , Row, Col } from 'react-bootstrap';
import "./../../../style2.scss" ; 
import "./../../../css/style.css"
 
import {Link} from "react-router-dom";
import Navbarstnd from '../../../Navbar/Navbarstnd'; 
import Card2 from '../Card2';
import Card3 from '../Card3';
import Card4 from '../Card4';
import Card5 from '../Card5';
import Card6 from '../Card6';
import Card7 from '../Card7';
import Card from '../Card';
import Card10 from '../Card10';
import NavbarOffres from './../../../Navbar/NavbarOffres';
import Footter from './../../../Footer/Footer1';

const ExpertisesinistreIARD = ()=> { 
       
    return ( 
 
        <div>
      
        <div className='backgroundOffres'>
        <div className='backr'>
                <div className='backr'>
                <Navbarstnd />
                </div>
                <NavbarOffres/>  
        <div style={{paddingBottom : 30 , margin : 40 }} ></div>
      </div>
      </div>
   
   
      <div className="secandparts">
       
      <div className='secands'>
      <Container>
      <Row className=' justify-content-md-center'> 
        <Col   style={{marginTop : 30  }} >
          <h1 > Expertise sinistre IARD    </h1>
           <p>
           En cas de sinistre, nous vous accompagnons dans tout le processus d’évaluation et de gestion des sinistres.
           </p>
           <p>
           Nos experts savent analyser les situations de sinistres et identifier les causes directes et indirectes. Grâce à nos outils d’expertise à distance et sur place nous pouvons visualiser les sinistres à l’instant (t) , nous garantissons donc une réactivité optimisée et une recherche efficiente des causes.
           </p>
            </Col>  
            </Row>
   

            <Row>
            <Link    to={{
                pathname: "/Form",
                state: "Expertise sinistre IARD  "  ,
                }} >
                <button className='demandeBTN'>Demander un devis</button>
             </Link>
                </Row>
            </Container>   
            </div>


            
            <div className="autreOffer">
            <Container>
            <Row className=' justify-content-md-center'> 
          <h1 > Nos autres offres   </h1>
          <Col md={4}>
          <Card />
          </Col> 
            <Col md={4}>
            <Card2 />
            </Col> 
            <Col md={4}>
            <Card3 />
            </Col>   <Col md={4}>
            <Card4 />
            </Col>   <Col md={4}>
            <Card5 />
            </Col>   <Col md={4}>
            <Card6 />
            </Col>   <Col md={4}>
            <Card7 />
            </Col>    <Col md={4}>
            <Card10 />
            </Col> 
            </Row>
            </Container>   
            </div>



      </div>
   
    
   
    
   
      <Footter /> 
   
   </div>  
      
   
);
}
export default ExpertisesinistreIARD ;

 
