
import React from 'react'
import {  Container  , Row, Col } from 'react-bootstrap';
import "./../../../style2.scss" ; 
import "./../../../css/style.css"
 
import {Link} from "react-router-dom";
import Navbarstnd from '../../../Navbar/Navbarstnd'; 
import Card2 from '../Card2';
import Card3 from '../Card3';
import Card4 from '../Card4';
import Card from '../Card';
import Card6 from '../Card6';
import Card7 from '../Card7';
import Card9 from '../Card9';
import Card10 from '../Card10';
import NavbarOffres from './../../../Navbar/NavbarOffres';
import Footter from './../../../Footer/Footer1';

const Analysedelavulnérabilite = ()=> { 
       
    return ( 
 
        <div>
      
        <div className='backgroundOffres'>
        <div className='backr'>
                <div className='backr'>
                <Navbarstnd />
                </div>
                <NavbarOffres/> 
        <div style={{paddingBottom : 30 , margin : 40 }} ></div>
      </div>
      </div>
   
   
      <div className="secandparts">
       
      <div className='secands'>
      <Container>
      <Row className=' justify-content-md-center'> 
        <Col   style={{marginTop : 30  }} >
          <h1 >Analyse de la vulnérabilité </h1>
           <p>
           
           Processus permettant d’identifier l’exposition d’un organisme à un phénomène qui va interrompe la continuité de son activité : incendie explosion, foudre, bris d’une machine, arrêt d’une ligne de production…. 
           </p>
           <p>
           Elle implique l’identification des points névralgiques des points dangereux et des conséquences possibles d’un sinistre sur le fonctionnement d’un organisme </p>
            </Col>  
            </Row>
   

            <Row>
            
            <Link    to={{
              pathname: "/Form",
              state: "Analyse de la vulnérabilité"  ,
              }} >
              <button className='demandeBTN'>Demander un devis</button>
           </Link>
                </Row>
            </Container>   
            </div>


            
            <div className="autreOffer">
            <Container>
            <Row className=' justify-content-md-center'> 
          <h1 > Nos autres offres   </h1>
          <Col md={4}>
          <Card />
          </Col> 
            <Col md={4}>
            <Card2 />
            </Col> 
            <Col md={4}>
            <Card3 />
            </Col>   <Col md={4}>
            <Card4 />
            </Col>   <Col md={4}>
            <Card6 />
            </Col>   <Col md={4}>
            <Card7 />
            </Col>      <Col md={4}>
            <Card9 />
            </Col>   <Col md={4}>
            <Card10 />
            </Col> 
            </Row>
            </Container>   
            </div>



      </div>
   
    
      <Footter /> 
    
   
   
   
   </div>  
      
   
);
}
export default Analysedelavulnérabilite ;

 
