import React, {useState, useEffect} from "react";
import {Container, Row, Col , Form , Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {BsDownload} from "react-icons/bs";
import fileDownload from "js-file-download";
import axios from "axios";
import {useLocation} from "react-router-dom";
import {
  readOneClient,
  readOneFiler,
  StatRap,
  AjouterRapsata,
  Table_Expert_Simister_exacte,
} from "../../../../../../../api/Clinets";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import ItemListtoutl from "./Itemtous";
import ItemListtoutl2 from "./Itemtous2";
import ItemListtoutl3 from "./Itemtous3";

import NavbarClientleve2 from "../../NavbarClientleve2";

const Stastistique3 = () => {
  const location = useLocation();
  let x = 0;
  let p = 0;
  let d = 0;
  let str = "";

  const reslut = [];
  const causseList = [];
  const emmdList = [];

  const Id_CLIENT = location.state.Id_CLIENT;
  const NAME = location.state.NAME_SOUSCLIENT;
  const NAmeClient = location.state.NAmeClient;
  const Id_SOUS_CLIENT = location.state.Id_SOUS_CLIENT;
  const [rapport, setrapport] = useState();
  const [myiduser, setMyiduser] = useState({iduser: Id_SOUS_CLIENT});
  const [formdata2, setformdata2] = useState({
    _id: Id_CLIENT,
    EmailCt: "",
    NameCt: "",
    NumCt: "",
    Pass: "",
    TelCt: "",
  });

  const [stast, setstast] = useState({
   
    causseLis: null,
    emmList: null,
  });
  const {xr, pr, dr, causseLis, emmList} = stast;
  useEffect(async () => {
    readOneClient(formdata2).then(async (response) => {
      //  setformdata(response.data);
      setformdata2(response.data);
    });

    await StatRap(myiduser).then((response) => {
      const datas = response.data.statisque;
      console.log(datas);
      setrapport(datas);
      // raps.push(... data[0].rap)
    });



    await Table_Expert_Simister_exacte(myiduser)
      .then((response) => {
        const data = response.data;

        const reslut = response.data.simster;
        reslut.map((m) => {
          causseList.push(...m.causes);
          emmdList.push(...m.endommagees);

        });
        setstast({
          causseLis: causseList,
          emmList: emmdList,
        });
      })
      .catch((err) => {
        console.log("====================================");
        console.log(err);
        console.log("====================================");
        alert("Error retrieving data!!!");
      });
  }, []);

  const [formSmister, setSmister] = useState({
    numClt: Id_CLIENT,
    rapp: null,
  });

  const {rapp} = formSmister;

  const handleChangeFiles = (evt) => {
    console.log(evt.target.files[0].name);
    setInputValue(evt.target.files[0].name);
    setSmister({
      ...formSmister,
      [evt.target.name]: evt.target.files[0],
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    let formData = new FormData();

    formData.append("Id_CLIENT", Id_SOUS_CLIENT);
    formData.append("rapp", rapp);
    console.log(formData);

    AjouterRapsata(formData)
      .then(() => {
        alert("Vous avez ajout un rapport ");
        //window.location = '/HomeAdmin' ;
        setInputValue("");
        StatRap(myiduser).then((response) => {
          const datas = response.data.statisque;
          console.log(datas);
          setrapport(datas);
          // raps.push(... data[0].rap)
        });
      })
      .catch((err) => {
        console.log("login error est", err);
        setSmister({
          ...formSmister,
          errorMSg: err.response.data.errorMessage,
        });
      });
  };
  const handleClick = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
        console.log(filename);
      });
  };

  const [inputValue, setInputValue] = useState("Insérer un Fisher");
  return (
    <div>
      <NavbarClientleve2
        Id_CLIENT={Id_CLIENT}
        Id_SOUS_CLIENT={Id_SOUS_CLIENT}
        NAME_SOUSCLIENT={NAME}
        NAmeClient={NAmeClient}
      />
      <Container>
        <h1 style={{margin: "30px"}}>Statistiques</h1>
        
    
        <Row>
          <Col>
            <Row>
              <p
                style={{
                  "margin-left": "20px",
                  "font-size": "15px",
                  "font-family": "georgia",
                }}
              >
                <Link
                  exact
                  to={{
                    pathname: "/Admin/accuile",
                    state: {
                      Id_CLIENT: Id_CLIENT,
                    },
                  }}
                >
                  {formdata2.NameCt}
                </Link>
                {">"}
                <Link
                  exact
                  to={{
                    pathname: "/Admin/GroupSousClient",
                    state: {
                      Id_CLIENT: Id_CLIENT,
                      Id_SOUS_CLIENT: Id_SOUS_CLIENT,
                      NAME_SOUSCLIENT: NAME,
                      NAmeClient: NAmeClient,
                    },
                  }}
                >
                  {NAME}
                </Link>
              </p>
            </Row>
          </Col>
          <Col>
            <p
              style={{
                margin: "10px",
                "font-size": "20px",
                "text-align": "right",
                "font-family": "georgia",
              }}
            >
              Numéro du compte : {formdata2.NumCt}
            </p>
          </Col>
        </Row>

        <Row style={{margin: "30px"}}>
          <Col>
          <ItemListtoutl3
          idcl={Id_SOUS_CLIENT}
          NAME_SOUSCLIENT={NAME}
        />
          </Col>

          <Col>
          <ItemListtoutl
          idcl={Id_SOUS_CLIENT}
          NAME_SOUSCLIENT={NAME}
        />
                </Col>

          <Col>
          <ItemListtoutl2
          idcl={Id_SOUS_CLIENT}
          NAME_SOUSCLIENT={NAME}
        />
            </Col>
        </Row> 


        <Row>
        <Col style={{margin: "30px"}}>
          <p> Insérer un fichier Excel ou un graphique</p>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Form.Group
                  as={Col}
                  className="custom-file"
                  controlId="formGridName "
                >
                  <Form.Label className="custom-file-label"> {inputValue}</Form.Label>
                  <Form.Control
                    className="custom-file-input"
                    type="file"
                    name="rapp"
                   
                    onChange={handleChangeFiles}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button className="btn xxbtn-info " type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
          <div>
            {rapport &&
              rapport.map((item) => (
                <div key={item._id}>
                  {item.rap.map((rapItem) => (
                    <div>
                      <br />
                      <Button
                        className="btn btn-light"
                        onClick={() =>
                          handleClick(
                            "../../../../../../../../uploads/" + rapItem,
                            rapItem
                          )
                        }
                      >
                        {rapItem}
                        {"  "}
                        <BsDownload />
                      </Button>
                      <br />
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </Col>
      </Row>
        <Row>
          <Col>
            <h5>Causes de sinistres </h5>
            <ListGroup as="ol" numbered>
              {stast.causseLis ? (
                stast.causseLis.map((item, index) => (
                  <ListGroup.Item key={index}>{item}</ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item>This element is not visible</ListGroup.Item>
              )}
            </ListGroup>
          </Col>

          <Col>
            <h5>Parties endommagées </h5>
            <ListGroup as="ol" numbered>
              {stast.emmList ? (
                stast.emmList.map((item, index) => (
                  <ListGroup.Item key={index}>{item}</ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item>This element is not visible</ListGroup.Item>
              )}
            </ListGroup>
          </Col>
        </Row>
        <Row></Row>
      </Container>
    </div>
  );
};

export default Stastistique3;
