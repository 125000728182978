import React , {useState} from 'react';
import './../../css/style.css' ; 
 
import isEmail   from 'validator/lib/isEmail';
import   isEmpty  from 'validator/lib/isEmpty'; 
import "./../../style2.scss" ;
import { Container, Row, Col, Form, Button } from 'react-bootstrap'; 
import emailjs from 'emailjs-com';
import { useLocation } from 'react-router-dom'
import AlertDanger from './../Alert/AlertDanger';
import AlertSuss from './../Alert/AlertSucc';
import { contactOffre } from '../../../api/Clinets';

const SaideRitheFromuler = ()=> { 
    

  const location = useLocation()
   
  console.log(location.state);
  const titre = location.state;

  const [formdata , setformdata]  = useState ({
 
     
    NameSociete : '', 
    Name :'',
    LastName : '' , 
    Email : '', 
    tel : '',
    Adress : '',
    Ctiy :'',
    CodePostal :'',
    text :'',
    titreoffre:titre,
    errorMSg:'',
    sussMsg:'',

  }) ;



  const {
            
    NameSociete, 
    Name ,
    LastName , 
    Email, 
    tel,
    Adress,
    Ctiy ,
    CodePostal ,
    text,
    
    errorMSg,sussMsg,
  } = formdata; 

  const handleChange = (evt) => {
  
    setformdata({
      ...formdata,
      [evt.target.name] : evt.target.value,
      errorMSg :'',
    });
 };

 
 
const handleSubmit = async (evt) => {
  
    evt.preventDefault();
    if( isEmpty(NameSociete)  || isEmpty(Name) || isEmpty(LastName) || isEmpty(Email) || isEmpty(tel) || isEmpty(Adress) || isEmpty(Ctiy) || isEmpty(CodePostal)   ) {
    
      setformdata({
          ...formdata, errorMSg : 'Tous les champs sont requis'

      });
      console.log(formdata.errorMSg);

}else if(!isEmail(Email)){
      setformdata({
        ...formdata, errorMSg : 'Email invalide' 

      });
      console.log(formdata.errorMSg);
}else{
  

  
        
          await contactOffre(formdata);          
         
                
                     
            setformdata({
              ...formdata, sussMsg : " Merci d'utiliser ce formulaire. Réponse rapide garantie !" 
        
            });






}

   
   
                

  }





      
    return ( 
 
     <div>
        
     

     <div> 
     <Container>
     <div>
     <div className="TitreFormulehr">
     <h1 style={{'font-size':'26px', 'margin-bottom':'20px'}}>{titre}</h1>
     </div>
     <Form onSubmit={handleSubmit}>
     
     {AlertDanger(errorMSg) }
     {AlertSuss(sussMsg)}
    
     <Form.Group  className="mb-2" controlId="formGridNomsociete">
      <Form.Label>Nom de la société</Form.Label>
      <Form.Control  
        placeholder="Nom de la société" 
        type ='text'
        name="NameSociete"
        onChange={handleChange}
        value = {NameSociete} />
    </Form.Group>



  <Row className="mb-3">
  <Form.Group as={Col} controlId="formGridName ">
  <Form.Label>Nom</Form.Label>
  <Form.Control  
  placeholder="Votre nom" 
  type ='text'
  name="Name"
  onChange={handleChange}
  value = {Name} /> 
</Form.Group>

<Form.Group as={Col} controlId="formGridprenom ">
<Form.Label>Prenom</Form.Label>
<Form.Control  
  placeholder="Votre prenom" 
  type ='text'
  name="LastName"
  onChange={handleChange}
  value = {LastName} /> 
</Form.Group>
  <Form.Group as={Col} controlId="formGridEmail">
  <Form.Label>Email</Form.Label>
  <Form.Control  
  placeholder="Votre Email" 
  type ='Email'
  name="Email"
  onChange={handleChange}
  value = {Email} /> 
</Form.Group>

    

    <Form.Group  as={Col}  controlId="formGridAddress2">
    <Form.Label>Téléphone </Form.Label>
     
    <Form.Control  
      placeholder="numro de telephone " 
      type ='tel'
      name="tel"
      onChange={handleChange}
      value = {tel} /> 
  </Form.Group>

  </Row>

 

  
  <Row className="mb-3" >


  <Form.Group  as={Col}  controlId="formGridAddress1">
  <Form.Label>Addresse</Form.Label>
  <Form.Control  
      type ='text'
      name="Adress"
      onChange={handleChange}
      value = {Adress} /> 
</Form.Group>


    <Form.Group as={Col} controlId="formGridVille">
      <Form.Label>Ville</Form.Label>
      <Form.Control  
      type ='text'
      name="Ctiy"
      onChange={handleChange}
      value = {Ctiy} /> 
    </Form.Group>

    

    <Form.Group as={Col} controlId="formGridposte">
      <Form.Label>Code Postale</Form.Label>
      <Form.Control  
      type ='text'
      name="CodePostal"
      onChange={handleChange}
      value = {CodePostal} /> 
    </Form.Group>
  </Row>
  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
  <Form.Label>Votre message</Form.Label>
  <Form.Control  
  type ='text'
  name="text"
  onChange={handleChange}
  value = {text}
  as="textarea" rows={3} 
   /> 
</Form.Group>

  <Form.Group className="mb-3" id="formGridCheckbox">
    <Form.Check type="checkbox" label="Enregistrer mon nom, mon e-mail et mon site dans le navigateur pour mon prochain commentaire." required />
  </Form.Group>

  <Button variant="primary" type="submit">
    Envoyer
  </Button>
</Form>
</div>
</Container>
     </div>
 
    </div>
 
      );
    } 

export default  SaideRitheFromuler  ;