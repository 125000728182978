import React , {useState , useEffect} from 'react';
import './../../css/style.css' ; 
 
import   isEmpty  from 'validator/lib/isEmpty'; 
import "./../../style2.scss" ;
import { Container, Row, Form, Button } from 'react-bootstrap'; 
import  logov from './../../../img/PREV-EX AFRICA.jfif';
import { login } from '../../../api/auth';
import {setAuthentication , isAuthenticated} from '../../../helpers/auth';
import AlertDanger from './../../Compusnte/Alert/AlertDanger';

const FromulerLogin = ()=> { 
     
 


  useEffect(()=>{
  
  
    if (isAuthenticated() ) {
      window.location = '/HomeAdmin'
    }else{
      console.log('error dash bord');
    }
  
  },[])

 

  const [formdata , setformdata]  = useState ({

     
    userName : '', 
    password :'',
    errorMSg :'',



  }) ;



  const {
            
    userName, 
    password ,
    errorMSg  ,

  } = formdata; 

  const handleChange = (evt) => {
  
    setformdata({
      ...formdata,
      [evt.target.name] : evt.target.value,
      errorMSg :'',
    });
 };

 
 
const handleSubmit = (evt) => {
  
    evt.preventDefault();
    if( isEmpty(userName) || isEmpty(password)   ) {
    
      setformdata({
          ...formdata, errorMSg : 'Tous les champs sont requis'

      });
      console.log(formdata.errorMSg);

}else{
  const {userName, password  } = formdata ; 
  const data = {userName, password  } ;

  login(data)

  .then(response => {

    setAuthentication(response.data.token , response.data.user);

    if (isAuthenticated() ) {
      console.log('admin dash bord'); 
      
      window.location = '/HomeAdmin' ; 
    }
    else{
      console.log('error dash bord');
    }
  })
  .catch ((err,response) =>{
    console.log('login error est',err);
    setformdata({
      ...formdata, errorMSg : 'Vérifiez votre UserName ou mot de pass'


   });
  });


  
}

   
   
                

  }





return ( 
    <div> 
        <Container>
        <Row className="justify-content-md-center">
             <div className="LoginFrom"> 
                <img src={logov} className='logoLogin' alt='' />
                <Form onSubmit={handleSubmit}>
                    
                    <Row className="mb-3">
                              {AlertDanger(errorMSg) }

                        <Form.Group  className="mb-2" controlId="formGridNomsociete">
                            <Form.Label>User name</Form.Label>
                            
                            <Form.Control  
                                type ='text'
                                className='inputlogin'
                                name="userName"
                                onChange={handleChange}
                                value = {userName} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group  controlId="formGridName ">
                            <Form.Label>Mot de passe</Form.Label>

                            <Form.Control  
                            type ='password'
                            name="password"
                            onChange={handleChange}
                            value = {password} /> 
                        </Form.Group>
                    </Row>


                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </div>
            </Row>
        </Container>
    </div>
 
 
      );
    } 

export default  FromulerLogin  ;