/* eslint-disable no-unused-vars */
import React from 'react'; 
 
import './../css/style articles.scss';
import { readall   } from './../../api/auth';  
import AOS from 'aos';

class CardActualites extends React.Component {
    

    state = { 
    titre: '', 
    text : '',
    source : '',
    image: null ,
    video: null ,
    linke : '',
        posts: []
      };
    
      componentDidMount = () => {
        this.getBlogPost();
      };
    
    
      getBlogPost = () => {
        readall()
          .then((response) => {
            const data = response.data;
            this.setState({ posts: data.article });
            console.log('Data has been received!!');
            console.log(data.article);
          })
          .catch(() => {
            alert('Error retrieving data!!!');
          });
      }
     
    
     
     
 
    
     
      displayBlogPost = (posts) => {
   
    
        if (!posts.length) return null;

        const  handleClickSignIn = (refARticle) => {
      // this function : it's get the id of the blog ane stored in loclalstorge to reused leater 
          localStorage.setItem('_id', refARticle);
          localStorage.setItem('_id', refARticle ? refARticle : '');
               
          window.location = '/Artcle';   
        
        }
        let ima = '';
        let x = 0 ;
        AOS.init({
    
        })
        return posts.map((post, index) => (
   
          


          <article className="postcard light blue" data-aos="fade-right" data-aos-offset="300">
         

         
          <img className="postcard__img"  src = { ima = '/uploads/'+ post.image }   alt="Title" />
       
          <div className="postcard__text t-dark">
            <h1 className="postcard__title blue">{post.titre}</h1>
            <div className="postcard__subtitle small">
              <time datetime="2020-05-25 12:00:00">
              <i class="fas fa-calendar-alt mr-2"></i> {post.createdAt.substring(0,10)}
              </time>
            </div>
            <div className="postcard__bar"></div>
            <div className="postcard__preview-txt">{post.text.length  >600 ? post.text.substring(0 , 200) +'...' : post.text.substring(0, 100) }</div>
            <ul className="postcard__tagbox"> 
             
              <button className='demandeBTN'  onClick ={()=> {handleClickSignIn(post._id)}}  >Lire la suite</button> 

            </ul>
          </div> 
        </article>
        
            
             
        ));
      };
    
      render() {
    
        console.log('State: ', this.state);
    
      
        return(
          <div >
     


 
          
          <section class="light">
            <div class="container py-2">
              <div class="h1 text-center text-dark" id="pageHeaderTitle">Prevex  Blog</div>
            {this.displayBlogPost(this.state.posts)} 

            </div>
          </section>
          
        
       




 


            

          </div>
        );
      }
    }
    
export default CardActualites ;
 
   



 