import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Home from "./pages/Home";
import Specialites from "./pages/Specialites";
import Contact from "./pages/Contact";
import Offres from "./pages/Offres";
import Approche from "./pages/Approche";
import Analyserisqueincendie from "./pages/Compusnte/Carde/Offers/Analyserisqueincendie";
import APCI from "./pages/Compusnte/Carde/Offers/APCI";

import AnalysedeRisqueBris from "./pages/Compusnte/Carde/Offers/AnalysedeRisqueBris";
import AuditDesEnstallationsElectriques from "./pages/Compusnte/Carde/Offers/AuditDesEnstallationsElectriques";
import Analysedelavulnérabilite from "./pages/Compusnte/Carde/Offers/Analysedelavulnérabilite";
import Visitederisquesalasouscription from "./pages/Compusnte/Carde/Offers/Visite-de-risques-à-la-souscription";
import Visitedeprevention from "./pages/Compusnte/Carde/Offers/Visite-de-prévention";
import Dangerrisqueaccident from "./pages/Compusnte/Carde/Offers/Danger-risque-accident";
import ExpertisesinistreIARD from "./pages/Compusnte/Carde/Offers/Expertise-sinistre-IARD";
import Estimationprealabledescapitaux from "./pages/Compusnte/Carde/Offers/Estimation-prealable-des-capitaux";

import Actualites from "./pages/Actualites/Actualites";
import Formuler from "./pages/Compusnte/formuler/Formuler";
import Login from "./pages/Adimn/login/Login";
import HomeAdmin from "./pages/Adimn/HomeAdmin";
import AddBlog from "./pages/Adimn/Actuailte/AddBlog";
import ConsulterARTicle from "./pages/Adimn/Actuailte/ConsulterArticle";
import Artcle from "./pages/Actualites/Artcle";
import SupprimeArticle from "./pages/Adimn/Actuailte/SupprimerArticle";
import ModifierArticle from "./pages/Adimn/Actuailte/ModifierArticle";
import LireAdmin from "./pages/Adimn/Actuailte/LireAdmin";
import Ajoutervideo from "./pages/Adimn/Actuailte/Ajoutervideo";
import Ajouterimage from "./pages/Adimn/Actuailte/Ajouterimage";
import Connexion from "./pages/client/connexion";
import DashBord from "./pages/client/dashbord_client";

import Ajouterrap from "./pages/Adimn/gestion client/Ajouter_rap";
import GestiondDeComptes from "./pages/Adimn/Gestion de compts/Compte/GestiondDeComptes";
import Accueil from "./pages/Adimn/Gestion de compts/Accuile/Accueil";
import AccueilsousClient from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Accueil_sous_Client";
import AccueilFiliere from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/Accueil_Filiere";

import Visiterisque from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/Risque/visite_risque";
import Rp from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/Stastistique/ttt";
import Estimationprelible from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/Estimation prelible/Estimationprelible";
import Expertiss from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/Expertise Sinistre/Expertis";
import Stastistique from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/Stastistique/Stastistique";
import Risksurvey from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/Risksurvey/Risksurvey";
import Visiteperetion from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/visite de peretion/Visiteperetion";
import Ajouterrapsinister from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/Expertise Sinistre/Details/Ajouter_rapp_sinistre";
import AjouterrapRisque from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/Risque/Ajouter_rapp_visite_risque";
import Ajouterrappvisiteprevention from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/visite de peretion/Ajouter_rapp_visite_prevention";
import Ajouterrapprisksurvey from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/Risksurvey/Ajouter_rapp_Risk_survey";
import AjouterrappEstimaionsprealable from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/Estimation prelible/Ajouter_rapp_Estimaions_prealable";
import Modifiersinistre from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/Expertise Sinistre/Details/Modifier_sinistre";
import AccueilsousClient2 from "./pages/client/Details/Details/Accueil_sous_Client";
import AccueilFiliere2 from "./pages/client/Details/Details/Details/Accueil_Filiere";
import Estimationprelible2 from "./pages/client/Details/Details/Details/Estimation prelible/Estimationprelible";
import Expertiss2 from "./pages/client/Details/Details/Details/Expertise Sinistre/Expertis";
import Risksurvey2 from "./pages/client/Details/Details/Details/Risksurvey/Risksurvey";
import Visiterisque2 from "./pages/client/Details/Details/Details/Risque/visite_risque";
import Visiteperetion2 from "./pages/client/Details/Details/Details/visite de peretion/Visiteperetion";
import Stastistique2 from "./pages/client/Details/Details/Details/Stastistique/Stastistique";
import Ajouterrappdrfinitif from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Filière/Expertise Sinistre/Details/Ajouter_rappdrfinitif";
import ExpertisSinister from "./pages/Adimn/Gestion de compts/Accuile/Details/Expertise Sinistre/Expertis1";
import Risksurvey1 from "./pages/Adimn/Gestion de compts/Accuile/Details/Risksurvey/Risksurvey";
import Estimationprelible1 from "./pages/Adimn/Gestion de compts/Accuile/Details/Estimation prelible/Estimationprelible";
import Visite_risque1 from "./pages/Adimn/Gestion de compts/Accuile/Details/Risque/visite_risque";
import Stastistique1 from "./pages/Adimn/Gestion de compts/Accuile/Details/Stastistique/Stastistique";
import Visiteperetion1 from "./pages/Adimn/Gestion de compts/Accuile/Details/visite de peretion/Visiteperetion";
import Estimationprelible3 from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Details/Estimation prelible/Estimationprelible";
import ExpertisSinister3 from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Details/Expertise Sinistre/Expertis1";
import Risksurvey3 from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Details/Risksurvey/Risksurvey";
import Visite_risque3 from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Details/Risque/visite_risque";
import Stastistique3 from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Details/Stastistique/Stastistique";
import Visiteperetion3 from "./pages/Adimn/Gestion de compts/Accuile/sous_cleint/Details/visite de peretion/Visiteperetion";
import Visiteperetion7 from "./pages/client/Details copy/visite de peretion/Visiteperetion";
import Stastistique7 from "./pages/client/Details copy/Stastistique/Stastistique";
import Visite_risque7 from "./pages/client/Details copy/Risque/visite_risque";
import Risksurvey7 from "./pages/client/Details copy/Risksurvey/Risksurvey";
import Estimationprelibleclient from "./pages/client/Details copy/Estimation prelible/Estimationprelible"; /* 
import ExpertisSinister7 from "./pages/Adimn/Gestion de compts/Accuile/Details/Expertise Sinistre/Expertis1"; */
import ExpertisSiniste7 from "./pages/client/Details copy/Expertise Sinistre/Expertis1";
import Visiteperetion5 from "./pages/client/Details/Details/Details copy/visite de peretion/Visiteperetion";
import Estimationprelible5 from "./pages/client/Details/Details/Details copy/Estimation prelible/Estimationprelible";
import ExpertisSinister5 from "./pages/client/Details/Details/Details copy/Expertise Sinistre/Expertis1";
import Risksurvey5 from "./pages/client/Details/Details/Details copy/Risksurvey/Risksurvey";
import Visite_risque5 from "./pages/client/Details/Details/Details copy/Risque/visite_risque";
import Stastistique5 from "./pages/client/Details/Details/Details copy/Stastistique/Stastistique";
import MentionsLegales from "./pages/MentionsLegales";
import Sitemap from "./pages/Sitemap";
import Politiques from "./pages/Politiques";

const App = () => {
  return (
    <Router>
      <main>
        <Switch>
          <Route path="/Admin/Ajouter_rappdrfinitif">
            <Ajouterrappdrfinitif />
          </Route>
          <Route path="/Filiere">
            <AccueilFiliere2 />
          </Route>
          <Route path="/Expertis">
            <Expertiss2 />
          </Route>
          <Route path="/visite_risque_">
            <Visiterisque2 />
          </Route>
          <Route path="/visite de peretion">
            <Visiteperetion2 />
          </Route>
          <Route path="/risk survey">
            <Risksurvey2 />
          </Route>
          <Route path="/Estimation prelible">
            <Estimationprelible2 />
          </Route>
          <Route path="/stastistique">
            <Stastistique2 />
          </Route>
          <Route path="/Accueil_sous_Client">
            <AccueilsousClient2 />
          </Route>
          <Route path="/Admin/GroupSousClient">
            <AccueilsousClient />
          </Route>
          <Route path="/Admin/Filiere">
            <AccueilFiliere />
          </Route>
          <Route path="/Admin/stastistique">
            <Stastistique />
          </Route>
          <Route path="/Admin/risk survey">
            <Risksurvey />
          </Route>
          <Route path="/Admin/Estimation prelible">
            <Estimationprelible />
          </Route>
          <Route path="/Admin/Visite de peretion">
            <Visiteperetion />
          </Route>
          <Route path="/Admin/Expertis">
            <Expertiss />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/HomeAdmin/GestiondDeComptes" exact>
            <GestiondDeComptes />
          </Route>
          <Route path="/Ajouter_rap">
            <Ajouterrap />
          </Route>
          <Route path="/DashBord" exact>
            <DashBord />
          </Route>
          <Route path="/Approche" exact>
            <Approche />
          </Route>
          <Route path="/NOSOFFRES" exact>
            <Offres />
          </Route>
          <Route path="/Specialites" exact>
            <Specialites />
          </Route>
          <Route path="/Contact" exact>
            <Contact />
          </Route>
          <Route path="/Analyse-risque-incendie" exact>
            <Analyserisqueincendie />
          </Route>
          <Route path="/APCI" exact>
            <APCI />
          </Route>
          <Route path="/Analyse-de-risque-Bris-de-machine" exact>
            <AnalysedeRisqueBris />
          </Route>
          <Route path="/Audit-des-installations-électriques" exact>
            <AuditDesEnstallationsElectriques />
          </Route>
          <Route path="/Analyse-de-la-vulnérabilité" exact>
            <Analysedelavulnérabilite />
          </Route>
          <Route path="/Visite-de-risques-à-la-souscription" exact>
            <Visitederisquesalasouscription />
          </Route>
          <Route path="/Visite-de-prévention" exact>
            <Visitedeprevention />
          </Route>
          <Route path="/Danger-risque-accident" exact>
            <Dangerrisqueaccident />
          </Route>
          <Route path="/Expertise-sinistre-IARD" exact>
            <ExpertisesinistreIARD />
          </Route>
          <Route path="/Estimation-prealable-des-capitaux" exact>
            <Estimationprealabledescapitaux />
          </Route>
          <Route path="/Admin/Ajouter_rapp_sinistre" exact>
            <Ajouterrapsinister />
          </Route>
          <Route path="/Admin/Ajouter_rapp_Visite_risque" exact>
            <AjouterrapRisque />
          </Route>
          <Route path="/Admin/Ajouter_rapp_visite_prevention" exact>
            <Ajouterrappvisiteprevention />
          </Route>
          <Route path="/Admin/Ajouter_rapp_risk_survey" exact>
            <Ajouterrapprisksurvey />
          </Route>
          <Route path="/Admin/Ajouter_rapp_Estimaions_prealable" exact>
            <AjouterrappEstimaionsprealable />
          </Route>
          <Route path="/Admin/Modifier_sinistre" exact>
            <Modifiersinistre />
          </Route>
          <Route path="/actualites" exact>
            <Actualites />
          </Route>
          <Route path="/Supprimer" exact>
            <SupprimeArticle />
          </Route>
          <Route path="/visite_risque" exact>
            <Visiterisque />
          </Route>
          <Route path="/Form/">
            <Formuler />
          </Route>
          <Route path="/LoginAdmin" exact>
            <Login />
          </Route>
          <Route path="/HomeAdmin" exact>
            <HomeAdmin />
          </Route>
          <Route path="/HomeAdmin/AddBlog" exact>
            <AddBlog />
          </Route>
          <Route path="/HomeAdmin/ConsulterARTIClE" exact>
            <ConsulterARTicle />
          </Route>
          <Route path="/Artcle" exact>
            <Artcle />
          </Route>
          <Route path="/Modifier" exact>
            <ModifierArticle />
          </Route>
          <Route path="/LireAdmin" exact>
            <LireAdmin />
          </Route>
          <Route path="/Ajoutervideo" exact>
            <Ajoutervideo />
          </Route>
          <Route path="/Ajouterimage" exact>
            <Ajouterimage />
          </Route>
          <Route path="/admin/ExpertisSinister" exact>
            <ExpertisSinister />
          </Route>
          <Route path="/connexion" exact>
            <Connexion />
          </Route>
          <Route path="/t">
            <Rp />
          </Route>
          <Route path="/Admin/accuile" exact>
            <Accueil />
          </Route>
          <Route path="/Admin/risk survey client" exact>
            <Risksurvey1 />
          </Route>
          <Route path="/Admin/Estimation prelible client" exact>
            <Estimationprelible1 />
          </Route>
          <Route path="/admin/visite_risque" exact>
            <Visite_risque1 />
          </Route>
          <Route path="/Admin/stastistique client" exact>
            <Stastistique1 />
          </Route>
          <Route path="/Admin/visite de peretion client" exact>
            <Visiteperetion1 />
          </Route>
          <Route path="/Admin/Estimation prelible sous client" exact>
            <Estimationprelible3 />
          </Route>
          <Route path="/Admin/ExpertisSinister sous client" exact>
            <ExpertisSinister3 />
          </Route>
          <Route path="/Admin/risk survey sous client" exact>
            <Risksurvey3 />
          </Route>{" "}
          <Route path="/admin/visite_risque sous client" exact>
            <Visite_risque3 />
          </Route>{" "}
          <Route path="/Admin/stastistique sous client" exact>
            <Stastistique3 />
          </Route>
          <Route path="/Admin/visite de peretion sous client" exact>
            <Visiteperetion3 />
          </Route>
          <Route path="/client/ExpertisSinister" exact>
            <ExpertisSiniste7 />
          </Route>
          <Route path="/client/visite_risque" exact>
            <Visite_risque7 />
          </Route>
          <Route path="/client/visite de peretion client" exact>
            <Visiteperetion7 />
          </Route>
          <Route path="/client/risk survey client" exact>
            <Risksurvey7 />
          </Route>
          <Route path="/client/Estimation prelible client" exact>
            <Estimationprelibleclient />
          </Route>
          <Route path="/client/stastistique client" exact>
            <Stastistique7 />
          </Route>
          <Route path="/sousclient/ExpertisSinister sous client" exact>
            <ExpertisSinister5 />
          </Route>
          <Route path="/sousclient/visite_risque sous client" exact>
            <Visite_risque5 />
          </Route>
          <Route path="/sousclient/visite de peretion sous client" exact>
            <Visiteperetion5 />
          </Route>
          <Route path="/sousclient/risk survey sous client" exact>
            <Risksurvey5 />
          </Route>
          <Route path="/sousclient/Estimation prelible sous client" exact>
            <Estimationprelible5 />
          </Route>
          <Route path="/sousclient/stastistique sous client" exact>
            <Stastistique5 />
          </Route>
          <Route path="/mentions-légales" exact>
            <MentionsLegales />
          </Route>
          <Route path="/plan-du-site" exact>
            <Sitemap />
          </Route>
          <Route path="/politiques-de-confidentialité" exact>
            <Politiques />
          </Route>
          <Redirect to="/" />
        </Switch>
      </main>
    </Router>
  );
};

export default App;
