import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./../../css/style.css";
import {FcNext, FcMinus} from "react-icons/fc";
import AOS from "aos";
import PREVEX_V from "./../../../videos/PREVEX_02_V05_MIX.mp4";
AOS.init({});
const ApprocheBtn = () => {
  return (
    <div>
      <div className="test">
        <Container>
          <Row>
            <div>
              <div class="item">
                <p>
                  Du préalable jusqu’à la gestion des sinistres en vous
                  garantissant la maîtrise technique :{" "}
                </p>{" "}
              </div>

              <div class="item" data-aos="fade-right">
                <Row>
                  <Col md="auto">
                    <FcNext />
                  </Col>
                  <Col>
                    <p>
                      Process mapping pour identifier les points de risques et
                      évaluer la vulnérabilité globale{" "}
                    </p>
                  </Col>{" "}
                </Row>{" "}
              </div>

              <div class="item" data-aos="fade-right" data-aos-offset="300">
                <Row>
                  <Col md="auto"></Col>
                  <Col md="auto">
                    <FcMinus />{" "}
                  </Col>{" "}
                  <Col>
                    <p>
                      {" "}
                      Analyse de risque incendie /analyse de vulnérabilité /
                      audit prévention et conseil incendie (APCI)
                    </p>
                  </Col>{" "}
                </Row>{" "}
              </div>

              <div class="item" data-aos="fade-right" data-aos-offset="300">
                <Row>
                  <Col md="auto"></Col>
                  <Col md="auto">
                    <FcMinus />{" "}
                  </Col>{" "}
                  <Col>
                    <p> Analyse risque bris de machine </p>
                  </Col>{" "}
                </Row>{" "}
              </div>

              <div class="item" data-aos="fade-right" data-aos-offset="300">
                <Row>
                  <Col md="auto"></Col>
                  <Col md="auto">
                    <FcMinus />{" "}
                  </Col>{" "}
                  <Col>
                    <p> Analyse risque inondations et catastrophe naturelle </p>
                  </Col>{" "}
                </Row>{" "}
              </div>

              <div class="item" data-aos="fade-right" data-aos-offset="300">
                <Row>
                  <Col md="auto">
                    <FcNext style={{margin: 0, padding: 0}} />
                  </Col>
                  <Col>
                    <p>Plan de prévention et d’atténuation des risques </p>
                  </Col>{" "}
                </Row>{" "}
              </div>

              <div class="item" data-aos="fade-right" data-aos-offset="300">
                <Row>
                  <Col md="auto">
                    <FcNext />
                  </Col>
                  <Col>
                    <p>
                      Conseil et accompagnement en matière d’assurance des
                      risques{" "}
                    </p>
                  </Col>{" "}
                </Row>{" "}
              </div>

              <div class="item" data-aos="fade-right" data-aos-offset="300">
                <Row>
                  <Col md="auto">
                    <FcNext />
                  </Col>
                  <Col>
                    <p>Analyse de la sinistralité et de leur portée</p>
                  </Col>{" "}
                </Row>{" "}
              </div>

              <div class="item" data-aos="fade-right" data-aos-offset="300">
                <Row>
                  <Col md="auto">
                    <FcNext />
                  </Col>
                  <Col>
                    <p>
                      Accompagnement des compagnies d’assurances dans le
                      processus de segmentation de leur portefeuille et de
                      tarification ciblée
                    </p>
                  </Col>{" "}
                </Row>{" "}
              </div>

              <div class="item" data-aos="fade-right" data-aos-offset="300">
                <Row>
                  <Col md="auto">
                    <FcNext />
                  </Col>
                  <Col>
                    <p>
                      Mise en place des indicateurs de risque à suivre KRI et
                      des TDB de reporting
                    </p>
                  </Col>{" "}
                </Row>{" "}
              </div>

              <div class="item" data-aos="fade-right" data-aos-offset="300">
                <Row>
                  <Col md="auto">
                    <FcNext />
                  </Col>
                  <Col>
                    <p>Lutte contre le risque de Fraude</p>
                  </Col>{" "}
                </Row>{" "}
              </div>
            </div>
          </Row>

          <Row className=" justify-content-md-center">
            <Col style={{"text-align": "center", margin: "60px"}}>
              <video
                src={PREVEX_V}
                autoPlay
                muted
                shouldPlay
                height="400"
                controls="controls"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ApprocheBtn;
