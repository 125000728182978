import React from "react";
import {Button, Table} from "react-bootstrap";
import {ListeScoietes, suprimeclient} from "../../../../api/Clinets";
import Modal from "react-bootstrap/Modal";
import {Link} from "react-router-dom";
import UpdateClient from "./UpdateClient";

class ListeScoiete extends React.Component {
  state = {
    NumCt: "",
    NameCt: "",
    EmailCt: "",
    TelCt: "",
    errorMSg: "",
    posts: [],
    ID: "",
    isOpen: false,
  };

  componentDidMount = () => {
    this.getBlogPost();
  };

  getBlogPost = () => {
    ListeScoietes()
      .then((response) => {
        const data = response.data;
        this.setState({posts: data.client});
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  };
  openModal = () => {
    this.setState({isOpen: true});
  };

  closeModal = () => {
    this.setState({isOpen: false});
  };
  render() {
    const handleClickSignIn = (id) => {
      // this function : it's get the id of the client and stored in loaclstorge
      localStorage.setItem("ID_Client", id);
      //redurection to dashbord of client
      window.location = "/Admin/accuile";
    };

    const handleClickUpdate = (id) => {
      // this function : it's get the id of the blog ane stored in loclalstorge to reused leater
      localStorage.setItem("ID_Client_update", id);
      this.openModal();
      //redurection to update page of client
      // window.location = "/Admin/updateClient";
    };

    const handleClickSuprime = async (id, name) => {
      if (window.confirm("Are you sure you want to delete " + name + " ?")) {
        // user clicked OK
        console.log("User clicked OK");

        console.log(id);
        // this function deletat client
        await this.setState({ID: id});

        console.log(this.state);

        suprimeclient(this.state)
          .then((response) => {
            alert("you have delete an acompte");
            window.location.reload();
          })
          .catch((err) => {
            alert(err);
          });
      }
    };

    return (
      <div>
        <div>
          <h2 style={{margin: "30px"}}>Liste des sociétés</h2>
        </div>

        <div>
          <Table responsive>
            <thead>
              <tr>
                <td>Numéro du compte </td>
                <td>Nom de société </td>
                <td>Email </td>
                <td>Tel </td>
                <td> </td>
                <td> </td>
              </tr>
            </thead>
            <tbody>
              {this.state.posts.map((post) => (
                <tr>
                  <td>{post.NumCt}</td>

                  <td>{post.NameCt}</td>
                  <td>{post.EmailCt}</td>
                  <td>{post.TelCt}</td>
                  <td>
                    <Link>
                      <Button
                        variant="info"
                        onClick={() => {
                          handleClickSignIn(post._id);
                        }}
                      >
                        Consulter
                      </Button>
                    </Link>
                  </td>
                  <td>
                    <Link>
                      <Button
                        variant="success"
                        onClick={() => {
                          handleClickUpdate(post._id);
                        }}
                      >
                        Update
                      </Button>
                    </Link>
                  </td>
                  <td>
                    {" "}
                    <Button
                      variant="danger"
                      onClick={() => {
                        handleClickSuprime(post._id, post.NameCt);
                      }}
                    >
                      Supprimer
                    </Button>{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UpdateClient ID_up={this.state.ID} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ListeScoiete;
