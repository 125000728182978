import React, {useState} from "react";
import {Container, Alert, Form, Row, Col, Button} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {Ajouter_filer_Client} from "../../../../../api/Clinets";

function Ajouter_Filer(props) {
  const name = props.namect;

  const location = useLocation();

  const NAmeClient = location.state.NAmeClient;

  const [formSmister, setSmister] = useState({
    nameCt: name,
    NameClient: NAmeClient,
    NameFlier: "",
    errorMSg: "",
  });

  const {NameFlier} = formSmister;

  const handleChange = (evt) => {
    setSmister({
      ...formSmister,

      [evt.target.name]: evt.target.value,

      errorMSg: "",
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    Ajouter_filer_Client(formSmister)
      .then(() => {
        alert("Vous avez ajout un filer ");
        window.location.reload(false);
      })
      .catch((err) => {
        console.log("login error est", err);
        setSmister({
          ...formSmister,
          errorMSg: err.response.data.errorMessage,
        });
      });
  };

  return (
    <div>
      <Container>
        <div
          style={{
            "background-color": "#f5f5f5",
            padding: "20px ",
            "border-radius": "30px",
            marginTop: "30px",
            "box-shadow": "0px 0px 10px 2px #acacacf5 ",
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                {formSmister.errorMSg && (
                  <Alert key={"danger"} variant={"danger"}>
                    {formSmister.errorMSg}
                  </Alert>
                )}
                <Form.Label>Nom de filière de sous-client </Form.Label>
                <Form.Control
                  type="text"
                  name="NameFlier"
                  onChange={handleChange}
                  value={NameFlier}
                />
              </Form.Group>
            </Row>

            <Button variant="primary" type="submit">
              Envoyer
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
}

export default Ajouter_Filer;
