import React from "react";
import { Link } from "react-router-dom";
import Navbarstnd from "./Navbar/Navbarstnd";
import Navbar from "./Navbar/navbar";
import Footter from "./Footer/Footer1";

import { Container, Row, Col } from "react-bootstrap";

const sitemapData = [
  { name: "Accueil", path: "/" },
  { name: "Notre Approche", path: "/Approche" },
  {
    name: "Nos Offres",
    path: "/NOSOFFRES",
    children: [
      { name: "Analyse risque incendie", path: "/Analyse-risque-incendie" },
      { name: "APCI : audit prévention et conseil incendie", path: "/APCI" },
      {
        name: "Analyse de risque Bris de machine",
        path: "/Analyse-de-risque-Bris-de-machine",
      },
      {
        name: "Audit des installations électriques",
        path: "/Audit-des-installations-électriques",
      },
      {
        name: "Analyse de la vulnérabilité",
        path: "/Analyse-de-la-vulnérabilité",
      },
      {
        name: "Visite de risques à la souscription",
        path: "/Visite-de-risques-à-la-souscription",
      },
      { name: "Visite de prévention", path: "/Visite-de-prévention" },
      { name: "Expertise sinistre IARD", path: "/Expertise-sinistre-IARD" },
      {
        name: "Estimation préalable des capitaux / valorisation des capitaux à la valeur",
        path: "/Estimation-prealable-des-capitaux",
      },
    ],
  },
  { name: "Actualités", path: "/actualites" },
  { name: "Nos Spécialités", path: "/Specialites" },
  { name: "Contact", path: "/Contact" },
  { name: "Connexion (Espace Client)", path: "/connexion" },
];

const Sitemap = () => {
  return (
    <div>
      <div className="backgroundSpecialite">
        <div className="backr">
          <div className="backr">
            <Navbarstnd />
          </div>
          <Navbar />
        </div>
      </div>
      <Container>
        <Row>
          <Col>
            <ul>
              <h1 style={{ textAlign: "center", marginBlock: "50px" }}>
                Plan Du Site
              </h1>
              <h2 style={{ marginBottom: "30px" }}>Pages</h2>

              {sitemapData.map((item, index) => (
                <li style={{ marginLeft: "20px" }} key={index}>
                  <Link to={item.path}>{item.name}</Link>
                  {item.children && (
                    <ul style={{ marginLeft: "1cm" }}>
                      {item.children.map((child, childIndex) => (
                        <li key={childIndex}>
                          {typeof child === "string" ? (
                            child
                          ) : (
                            <Link to={child.path}>{child.name}</Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
      <Footter />
    </div>
  );
};

export default Sitemap;
