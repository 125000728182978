import React from 'react'; 
import { Row, Button , Col } from 'react-bootstrap'; 
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { readall   } from './../../../api/auth';  
import AOS from 'aos';
import Card from 'react-bootstrap/Card';

import {Link} from "react-router-dom";

class CardConsulterAdmin extends React.Component {
    

    state = { 
    titre: '', 
    text : '',
    source : '',
    image: null ,
    video: null ,
    linke : '',
        posts: []
      };
    
      componentDidMount = () => {
        this.getBlogPost();
      };
    
    
      getBlogPost = () => {
        readall()
          .then((response) => {
            const data = response.data;
            this.setState({ posts: data.article });
            console.log('Data has been received!!');
            console.log(data.article);
          })
          .catch(() => {
            alert('Error retrieving data!!!');
          });
      }
     
    
     
     
 
    
     
      displayBlogPost = (posts) => {
   
    
        if (!posts.length) return null;

      /*  const  handleClickSignIn = (refARticle) => {
      // this function : it's get the id of the blog ane stored in loclalstorge to reused leater 
          localStorage.setItem('_id', refARticle);
          localStorage.setItem('_id', refARticle ? refARticle : '');
               
          window.location = '/Artcle';   
        
        }*/
       
        AOS.init({
    
        })
       
      };
    
      render() {
    
        console.log('State: ', this.state);
      
        return(
          <div >
     


 
           
            <Row xs={1} md={3} className="g-4">
            {this.state.posts.map((post) => (
              <Col>
                <Card style={{'padding': '0'}}>
                
               
                  {(() => {
                    if(post.image != null)
                    {
                        return (
                           <Card.Img variant="top"   src = {'/uploads/'+ post.image }   alt="Image Title" />

                        )
                    }
                    
                    return null;
                  })()}
                  <Card.Body>
                    <Card.Title>{post.titre}</Card.Title>

                    <Card.Text>
                    <p>{post.text.length  >600 ? post.text.substring(0 , 100) +'...' : post.text.substring(0, 20) }</p>

                    </Card.Text>
                   
                    <ButtonGroup aria-label="Basic ">
                        
                        <Link    to={{
                          pathname: "/LireAdmin",
                          state: post._id  ,
                          }} >
                        <Button variant="outline-success">Lire</Button>
                       </Link>
                       <Link    to={{
                        pathname: "/Modifier",
                        state: post  ,
                        }} >
                      <Button variant="outline-info">Modifier</Button>
                     </Link>

                        <Link    to={{
                          pathname: "/Supprimer",
                          state: post._id  ,
                          }} >
                           <Button variant="outline-danger">Supprimer</Button>
                       </Link>
                    </ButtonGroup>
                  </Card.Body>
                  <Card.Footer>
                  <small className="text-muted">{post.createdAt.substring(0,10)}</small>
                </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>

        
            

          </div>
        );
      }
    }
    
export default CardConsulterAdmin ;