
import React, {   useState } from 'react' ;
import { Container, Form,  Row, Col, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { AjouterRapdefinitif } from '../../../../../../../../api/Clinets'; 
import { useHistory } from "react-router-dom";
const  Ajouter_rappdrfinitif = () =>  {


  const location = useLocation();
  
  console.log("id: " , location.state);
  const Id = location.state; 

   
     
  
  const [formSmister , setSmister]  = useState ({
     
    numClt :  Id ,
    rapp_definitif : null ,
    montant_definitif : '0'
   
}) ;

const {
 
  rapp_definitif ,
  montant_definitif
} = formSmister; 

let name = "sélectionner un rapport";
 
const handleChangeFiles = (evt) => {
    console.log( evt.target.files[0]);
setSmister({
  ...formSmister,
  [evt.target.name] : evt.target.files[0],
}); 
};
const handleChange = (evt) => {
      
  setSmister({
    ...formSmister,
    
    [evt.target.name] : evt.target.value,

    errorMSg :'',
  });
};

const handleSubmit = (evt) => {
      
    evt.preventDefault();
    let formData = new FormData();
      
    formData.append('Id',  Id );
    formData.append('montant_definitif',  montant_definitif );
    formData.append('rapp_definitif', rapp_definitif); 
    console.log(formData);
    
    AjouterRapdefinitif(formData)
  
   .then(() => {
      alert('Vous avez ajout un article ');
       //window.location = '/HomeAdmin' ; 
    
    })
    .catch ((err) =>{
      console.log('login error est',err);
      setSmister({
        ...formSmister, errorMSg : err.response.data.errorMessage
  
  
     });
    });
  
  
  
  }
  
  let history = useHistory();

 

return (
  <div>

  <Container >
  <div style={{
    margin : "20%",
    "border-radius" : "30px" ,
     
  }}>
  </div>
  <Form onSubmit={handleSubmit}>
 
        <Row className="mb-3">
        <Col>
          <Form.Group  as={Col}  className='custom-file' controlId="formGridName ">
      
  <Form.Label className='custom-file-label'>{name} </Form.Label>
  <Form.Control  
  className='custom-file-input'
  type ='file'
  name="rapp_definitif"
  onChange={handleChangeFiles}

  /> 
</Form.Group>

        </Col>
      <Col>
      
      
      <Form.Group className="mb-3" controlId="formGridAddress1">
      <Form.Label>Montant definitif	</Form.Label>
      <Form.Control  
                    name='montant_definitif' 
                    onChange={handleChange}
                    value = {montant_definitif} />
    </Form.Group>
      </Col>

  
 


 </Row>
 <Row>
 <Col> 
 <Button variant="primary" type="submit">
    Submit
  </Button>
  
  </Col>
 <Col style={{ "text-align": "right" }}><Button variant="dark" onClick={history.goBack}>Back</Button></Col>
 </Row>

 
  </Form>
  
</Container>
 
  </div>


  );
} 

export default Ajouter_rappdrfinitif ;