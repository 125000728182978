
import React from 'react'
import "./../../style2.scss" ; 
 
const Card = ()=> { 
    
      
      return ( 
 
     <div>
            
            <div className="testimotionals">
            <div className="card">
                <div className="layer">
                
                </div>
                <div className="content">
                <div className="details">
                    <h2>Analyse risque incendie   </h2>
                    <a href="Analyse-risque-incendie" class="nav-link"> <button className='ligthbtn'>Découvrir</button> </a>
                </div>
                </div>
            </div>
            </div>

 
 
</div>
 
    

);
}
export default Card ;

 
