

import React from 'react'
import './../css/style.css' ; 
class NavbarOffres extends React.Component {
 
    render() {
      
      return ( 

    <div>
    
    <nav class="navbar navbar-expand-lg navbar-light ftco_navbar bg-light ftco-navbar-light" id="ftco-navbar">
     
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="fa fa-bars"></span> Menu
      </button>
      <div class="collapse navbar-collapse" id="ftco-nav">
        <ul class="navbar-nav m-auto">
            <li class="nav-item"><a href="/" class="nav-link">Accueil  </a></li>
            <li class="nav-item"><a href="Approche" class="nav-link">Notre approche</a></li>
            <li class="nav-item active dropdown">
            <a class="nav-link dropdown-toggle " href="NOSOFFRES" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Nos offres
            </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item " href="NOSOFFRES">Nos offres</a>
              <div class="dropdown-divider"></div>

                <a class="dropdown-item" href="Analyse-risque-incendie">Analyse risque incendie</a>
                <a class="dropdown-item" href="APCI">APCI : audit prévention et conseil incendie</a>
                <a class="dropdown-item" href="Analyse-de-risque-Bris-de-machine">Analyse de risque Bris de machine </a>
                <a class="dropdown-item" href="Audit-des-installations-électriques">Audit des installations électriques</a>
                <a class="dropdown-item" href="Analyse-de-la-vulnérabilité">Analyse de la vulnérabilité </a>
                <a class="dropdown-item" href="Visite-de-risques-à-la-souscription">Visite de risques à la souscription </a>
                <a class="dropdown-item" href="Visite-de-prévention">Visite de prévention </a>
                <a class="dropdown-item" href="Expertise-sinistre-IARD">Expertise sinistre IARD </a>
                <a class="dropdown-item" href="Estimation-prealable-des-capitaux">Estimation préalable des capitaux /valorisation des capitaux à la juste valeur </a>
              
              </div>
          </li>
          
          <li className="nav-item"><a href="actualites" className="nav-link">actualites </a></li>

            <li class="nav-item"><a href="Specialites" class="nav-link">nos spécialités </a></li>
            <li class="nav-item"><a href="Contact" class="nav-link">Contact</a></li>
        </ul>
      </div>
 
  </nav>
    
    </div>
    )
}
}

export default NavbarOffres ;