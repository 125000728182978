import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from "@mui/material";

const acceptCookieButtonStyle = {
  background: "#0c0f26",
  color: "white",
  fontSize: "14px",
  fontWeight: "bold",
  height: "40px",
  width: "100px",
  borderRadius: "5px",
  marginLeft: "0px",
};

const declineCookieButtonStyle = {
  background: "#CD1818",
  color: "white",
  fontSize: "14px",
  fontWeight: "bold",
  height: "40px",
  width: "100px",
  borderRadius: "5px",
  marginLeft: "50px",
};

const CookieComponent = () => {
  return (
    <CookieConsent
      location="bottom"
      cookieName="cookieConsent"
      expires={999}
      buttonText="ACCEPTER"
      buttonStyle={acceptCookieButtonStyle}
      declineButtonText="REFUSER"
      onDecline={() => {
        Cookies.remove("cookieConsent");
      }}
      onAccept={() => {
        Cookies.set("cookieConsent", "true");
      }}
      declineButtonStyle={declineCookieButtonStyle}
      style={{
        background: "white",
        color: "black",
        border: "1px solid grey",
        fontWeight: 400,
        lineHeight: "1.5",
      }}
      enableDeclineButton
    >
      Notre site web utilise des cookies pour analyser le trafic et garantir que
      votre expérience soit cohérente lors de vos visites. Vous pouvez en
      apprendre davantage sur notre{" "}
      <Link
        href="/politiques-de-confidentialité"
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        politique de confidentialité
      </Link>
      .
    </CookieConsent>
  );
};

export default CookieComponent;
