import React, {useState, useEffect} from "react";
import {Container, Button, Table, Row, Col} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {
  readOneClient,
  Table_Expert_Simister_exacte,
  Table_Risque_exacte,
  Table_Visite_peretion_exacte,
  Table_Estimations_prelible_exacte,
} from "../../../../../api/Clinets";

const ItemList = (props) => {
  const location = useLocation();

  const [statistics, setStatistics] = useState({
    xr: "",
    risque: "",
    vp: "",
    ep: "",
  });

  const {
    xr: expertSimister,
    risque: risk,
    vp: visitPeretion,
    ep: estimationsPrelible,
  } = statistics;

  const [myIdUser, setMyIdUser] = useState({iduser: props.idcl});

  const fetchData = async () => {
    const response2 = await Table_Expert_Simister_exacte(myIdUser);
    const expertSimisterCount = response2.data.simster.length;

    const response3 = await Table_Risque_exacte(myIdUser);
    const riskCount = response3.data.risque.length;

    const response4 = await Table_Visite_peretion_exacte(myIdUser);
    const visitPeretionCount = response4.data.visite_de_peretion.length;

    const response5 = await Table_Estimations_prelible_exacte(myIdUser);
    const estimationsPrelibleCount = response5.data.estimations_prelible.length;
 
    setStatistics({
      ...statistics,
      xr: expertSimisterCount,
      risque: riskCount,
      vp: visitPeretionCount,
      ep: estimationsPrelibleCount,
    });
    
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <td>SIN : {statistics.xr} </td>
      <td>VR : {statistics.risque}</td>
      <td>VP : {statistics.vp}</td>
      <td>EP : {statistics.ep} </td>
    </div>
  );
};

export default ItemList;
