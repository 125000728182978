import React, {useState, useEffect} from "react";
import {Container, Row, Col, Button} from "react-bootstrap";
import {Link} from "react-router-dom";

import {useLocation} from "react-router-dom";
import {
  readOneClient,
  readOneFiler,
  StatRap,
  Table_Expert_Simister,
} from "../../../../../../api/Clinets";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import {BsDownload} from "react-icons/bs";
import fileDownload from "js-file-download";
import axios from "axios";
import NavbarClientleve2 from "../../NavbarClientleve2";
import ItemListtoutl from "./Itemtous";
import ItemListtoutl2 from "./Itemtous2";
import ItemListtoutl3 from "./Itemtous3";
const Stastistique5 = () => {
  const location = useLocation();
  let x = 0;
  let p = 0;
  let d = 0;

  const reslut = [];
  const causseList = [];
  const emmdList = [];

  const Id_CLIENT = location.state.Id_CLIENT;
  const NAME = location.state.NAME_SOUSCLIENT;
  const NAmeClient = location.state.NAmeClient;
  const Id_SOUS_CLIENT = location.state.Id_SOUS_CLIENT;

  const [myiduser, setMyiduser] = useState({iduser: Id_SOUS_CLIENT});
  const [formdata2, setformdata2] = useState({
    _id: Id_CLIENT,
    EmailCt: "",
    NameCt: "",
    NumCt: "",
    Pass: "",
    TelCt: "",
  });
  const handleClick = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
        console.log(filename);
      });
  };

  const [rapport, setrapport] = useState();
  const [stast, setstast] = useState({
    xr: "",
    pr: "",
    dr: "",
    causseLis: null,
    emmList: null,
  });
  const {xr, pr, dr, causseLis, emmList} = stast;
  useEffect(() => {
    StatRap(myiduser).then((response) => {
      const datas = response.data.statisque;
      console.log(datas);
      setrapport(datas);
      // raps.push(... data[0].rap)
    });
    Table_Expert_Simister()
      .then((response) => {
        const data = response.data;

        const reslut = response.data.simster;
        reslut.map((m) => {
          if (m.ID_Filer_Client == Id_SOUS_CLIENT) {
            x = x + 1;
            causseList.push(...m.causes);
            emmdList.push(...m.endommagees);

            m.rapp_preminare.map((mm) => {
              p = p + Number(mm.montant_preminare);
            });
            m.rapp_definitif.map((mm) => {
              d = d + Number(mm.montant_definitif);
            });
          }
        });
        setstast({
          xr: x,
          pr: p,
          dr: d,
          causseLis: causseList,
          emmList: emmdList,
        });
      })
      .catch((err) => {
        alert("Error retrieving data!!!");
      });

    readOneClient(formdata2).then((response) => {
      //  setformdata(response.data);
      setformdata2(response.data);
    });
  }, []);

  return (
    <div>
      <NavbarClientleve2
        Id_CLIENT={Id_CLIENT}
        Id_SOUS_CLIENT={Id_SOUS_CLIENT}
        NAME_SOUSCLIENT={NAME}
        NAmeClient={NAmeClient}
      />
      <Container>
        <h1 style={{margin: "30px"}}>Stastistique</h1>

        <Row>
          <Col>
            <Row>
              <p
                style={{
                  "margin-left": "20px",
                  "font-size": "15px",
                  "font-family": "georgia",
                }}
              >
                <Link
                  exact
                  to={{
                    pathname: "/DashBord",
                    state: {
                      Id_CLIENT: Id_CLIENT,
                    },
                  }}
                >
                  {formdata2.NameCt}
                </Link>
                {">"}
                <Link
                  exact
                  to={{
                    pathname: "/Accueil_sous_Client",
                    state: {
                      Id_CLIENT: Id_CLIENT,
                      Id_SOUS_CLIENT: Id_SOUS_CLIENT,
                      NAME_SOUSCLIENT: NAME,
                      NAmeClient: NAmeClient,
                    },
                  }}
                >
                  {NAME}
                </Link>
              </p>
            </Row>
          </Col>
          <Col>
            <p
              style={{
                margin: "10px",
                "font-size": "20px",
                "text-align": "right",
                "font-family": "georgia",
              }}
            >
              Numéro du compte : {formdata2.NumCt}
            </p>
          </Col>
        </Row>

        <Row style={{margin: "30px"}}>
          <Col>
            <ItemListtoutl3 idcl={Id_SOUS_CLIENT} NAME_SOUSCLIENT={NAME} />
          </Col>
          <Col>
            <ItemListtoutl idcl={Id_SOUS_CLIENT} NAME_SOUSCLIENT={NAME} />
          </Col>

          <Col>
            <ItemListtoutl2 idcl={Id_SOUS_CLIENT} NAME_SOUSCLIENT={NAME} />
          </Col>
        </Row>
        <Row>
          <Col style={{margin: "30px"}}>
            <div>
              {rapport &&
                rapport.map((item) => (
                  <div key={item._id}>
                    {item.rap.map((rapItem) => (
                      <div>
                        <br />
                        <Button
                          className="btn btn-light"
                          onClick={() =>
                            handleClick(
                              "../../../../../../../../uploads/" + rapItem,
                              rapItem
                            )
                          }
                        >
                          {rapItem}
                          {"  "}
                          <BsDownload />
                        </Button>
                        <br />
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <h5>Causes de sinistres </h5>
            <ListGroup as="ol" numbered>
              {stast.causseLis ? (
                stast.causseLis.map((item, index) => (
                  <ListGroup.Item key={index}>{item}</ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item>This element is not visible</ListGroup.Item>
              )}
            </ListGroup>
          </Col>

          <Col>
            <h5>Parties endommagées </h5>
            <ListGroup as="ol" numbered>
              {stast.emmList ? (
                stast.emmList.map((item, index) => (
                  <ListGroup.Item key={index}>{item}</ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item>This element is not visible</ListGroup.Item>
              )}
            </ListGroup>
          </Col>
        </Row>
        <Row></Row>
      </Container>
    </div>
  );
};

export default Stastistique5;
