import React, {useState, useEffect} from "react";
import {Container, InputGroup, Form} from "react-bootstrap";

import isEmpty from "validator/lib/isEmpty";
import {Updateclient, readOneClient} from "../../../../api/Clinets";
import AlertDanger from "../../../Compusnte/Alert/AlertDanger";

const AjouterUnClient = (props) => {
  const Id = localStorage.getItem("ID_Client_update");
  const [formdata, setformdata] = useState({
    _id: Id,
    NumCt: "",
    NameCt: "",
    EmailCt: "",
    TelCt: "",
    errorMSg: "",
    Pass: "",
  });

  const {_id, NumCt, NameCt, EmailCt, TelCt, errorMSg, Pass} = formdata;

  useEffect(() => {
    readOneClient(formdata).then((response) => {
      //  setformdata(response.data);
      
      setformdata(response.data);
    });
  }, []);
  const handleChange = (evt) => {
    setformdata({
      ...formdata,

      [evt.target.name]: evt.target.value,
      errorMSg: "",
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (
      isEmpty(NumCt) ||
      isEmpty(NameCt) ||
      isEmpty(EmailCt) ||
      isEmpty(TelCt) ||
      isEmpty(Pass)
    ) {
      setformdata({
        ...formdata,
        errorMSg: "Tous les champs sont requis",
      }); 
    } else {
      Updateclient(formdata)
        .then((response) => {
          alert("Toutes les modifications sont enregistrées ");
          window.location = "/HomeAdmin/GestiondDeComptes";
        })
        .catch((err, response) => {
        
          setformdata({
            ...formdata,
            errorMSg: err.response.data.errorMessage,
          });
        });
    }
  };

  return (
    <div>
      <Container>
        <Container>
          <div className="frome">
            <Form onSubmit={handleSubmit}>
              <div className="cont"></div>
              {AlertDanger(errorMSg)}

              <Form.Group>
                <Form.Label>Nom de société </Form.Label>
                <InputGroup>
                  <Form.Control
                    className="inputtest1"
                    type="text"
                    placeholder="Nom de societe"
                    name="NameCt"
                    onChange={handleChange}
                    value={NameCt}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.Label>Numéro de compte </Form.Label>
                <InputGroup>
                  <Form.Control
                    className="inputtest1"
                    type="text"
                    placeholder="Entrer Numéro de compte"
                    name="NumCt"
                    onChange={handleChange}
                    value={NumCt}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.Label>Email</Form.Label>
                <InputGroup>
                  <Form.Control
                    className="inputtest1"
                    type="email"
                    placeholder="Entrer email"
                    name="EmailCt"
                    onChange={handleChange}
                    value={EmailCt}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.Label>Numéro de téléphone</Form.Label>
                <InputGroup>
                  <Form.Control
                    className="inputtest1"
                    type="text"
                    placeholder="Entrer Numéro de téléphone"
                    name="TelCt"
                    onChange={handleChange}
                    value={TelCt}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Label>Mote de pass</Form.Label>
                <InputGroup>
                  <Form.Control
                    className="inputtest1"
                    type="text"
                    placeholder="Entrer Numéro de téléphone"
                    name="Pass"
                    onChange={handleChange}
                    value={Pass}
                  />
                </InputGroup>
              </Form.Group>
              <button type="submit" class="butnn">
                <div>Save </div>
              </button>
            </Form>
          </div>
        </Container>
      </Container>
    </div>
  );
};
export default AjouterUnClient;
