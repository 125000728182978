/* eslint-disable eqeqeq */
 
import NavbarAdmin from '../../Navbar/NavbarAdmin';
import { Container, Row, Button ,Form, Col } from 'react-bootstrap'; 
import {React ,useState  }  from 'react';  
import { UpdateArticles } from './../../../api/auth'; 
import Card from 'react-bootstrap/Card';
import { isAuthenticated} from '../../../helpers/auth';
import {Link, useLocation} from "react-router-dom";






const ModifierArticle = ()=> { 
    
  const location = useLocation();
   
  console.log(location.state);
  const Titre = location.state['titre']; 
  const id = location.state["_id"]; 
  const Source = location.state['source']; 
  const Linke = location.state['linke']; 
 
         
        const [formdata , setformdata]  = useState ({ _id : id , titre :'' ,text :'' ,source :''  ,linke:'' ,createdAt:'',video : null ,image : null ,heading :'' }) ;
        const {titre  ,text  ,source  ,linke ,createdAt, heading} = formdata; 
        
   
         const handleChange = (evt) => {
      
                setformdata({
                  ...formdata,
        
                  [evt.target.name] : evt.target.value,
                  errorMSg :'',
                });
        
             };
        

         const handleSubmit = (evt) => {
      
                evt.preventDefault();
               
                console.log("to update " ,formdata);
                UpdateArticles(formdata)
        
                 .then(() => {
                    alert("Les modifications sont bien sauvegardées");
                    window.location = '/HomeAdmin' ; 
              
               });
            
             
          }
    return ( 
 
       <div>
       {(() => {
        if(isAuthenticated())
        {
            return (
              <div>
                 <NavbarAdmin /> 
                 <Container>
      
                  <div style={{'marginTop':'30px'}}>
                      <Card style={{'padding': '0'}}>
                          <Card.Header>Etes-vous sûr de Modifier ce blog</Card.Header>
                        
                          <Card.Body>
                            <Card.Title>Modification de l'article : {Titre}</Card.Title>
      
                               <div className="addblogF">
                    
                                 <Form  onSubmit={handleSubmit} >
               
                                 <Row className="mb-3">
           
                                 <Form.Group  className='.AddBlogForm mb-4 ' controlId="formGridNomsociete">
             
                                 <Form.Label>Titre de l'article : {Titre} </Form.Label>
               
                                          
                                 <Form.Control  
                                         type ='text'
                
                                         className='AddBlogForm'
                 
                                         name="titre"
                 
                                         onChange={handleChange}
                
                                         value = {titre} />
         
                                         </Form.Group>
     
                                         </Row>

                                         <Row className="mb-3">
           
                                         <Form.Group  className='.AddBlogForm mb-4 ' controlId="formGridNomsociete">
                     
                                         <Form.Label>heading de l'article</Form.Label>
                       
                   
                                         <Form.Control  
                                                 type ='text'
                        
                                                 className='AddBlogForm'
                         
                                                 name="heading"
                         
                                                 onChange={handleChange}
                        
                                                 value = {heading} />
                 
                                                 </Form.Group>
             
                                                 </Row>

                                         
      
                                         <Row className="mb-3">
      
                                         <Form.Group  controlId="formGridName ">
      
                                         <Form.Label>Le contenu </Form.Label>

      
                                         <Form.Control  
      
                                         type ='text'
      
                                         name="text"
      
                                         as="textarea"
      
                                         className='AddBlogForm'
      
                                         onChange={handleChange}
      
                                         value = {text} /> 
      
                                         </Form.Group>
  
                                         </Row>



  
                                         <Row className="mb-3">


   {(() => {
    if(Source != '')
    {
        return (
           <div> 
           <Form.Group  controlId="formGridName ">
           <Form.Label>Source </Form.Label>
    
           <Form.Control  
           type ='text'
           name="source"
           className='AddBlogForm'
           onChange={handleChange}
           value = {source} /> 
       </Form.Group></div>
          )
    }
    
    return ( 
      <Form.Group  controlId="formGridName ">
    <Form.Label>Source </Form.Label>
  <p style={{"color":'red'}}>la champs de Source est vide </p>
    <Form.Control  
    type ='text'
    name="source"
    className='AddBlogForm'
    onChange={handleChange}
    value = {source} /> 
</Form.Group>);
  })()} 

  
</Row>
<Row className="mb-3">

{(() => {
  if(Linke != '')
  {
      return (
         <div> 
         <Form.Group  controlId="formGridName ">
   <Form.Label>lien </Form.Label>
   <Form.Control  
   type ='linke'
   name="linke"
   onChange={handleChange}
   value = {linke} /> 
</Form.Group>
         </div>
        )
  }
  
  return ( 
    <Form.Group  controlId="formGridName ">
    <Form.Label>lien </Form.Label>
  <p style={{"color":'red'}}>la champs de lien est vide </p>

    <Form.Control  
    type ='linke'
    name="linke"
    onChange={handleChange}
    value = {linke} /> 
 </Form.Group>
  );
})()} 


</Row>





 
 








             <Row>
             <Col>
             <Link    to={{
                pathname: "/HomeAdmin",
                }} >
              <Button variant="outline-secondary">Annuler</Button>
             </Link>
             </Col>
             <Col  md={{ span: 3, offset: 3 }}>
                <Button variant="outline-success" type="submit"  >Sauvegarder</Button>
            </Col>
             </Row>



             
   </Form>
           
      
   
   </div>
   
   </Card.Body>
   <Card.Footer className="text-muted">{createdAt.substring(0,10)}</Card.Footer>
   </Card>
   </div>
    
 

   
  </Container>

  </div>
  )
}else{ window.location = '/loginAdmin'}

return null;
})()}

       </div>
 
      );
    } 

export default  ModifierArticle  ; 