import React from "react";
import { FaBars } from "react-icons/fa";
import logov from "./../../img/PREV-EX AFRICA.png";

import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import { Logout } from "./../../helpers/auth";

export const Nav = styled.nav`
  background: -webkit-linear-gradient(top left, #13bbe6 0%, #72f1f3 100%);
  height: 85px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 12;
`;
export const NavLogo = styled(Link)`
  cursor: pointer;
  color: #fff;
  font-size: 2rem;
  text-decoration: none;
`;

export const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: black;
  }
  &:hover {
    color: black;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: transparent;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: 1px solid #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
`;

const NavbarAdmin = () => {
  const hendleLogout = () => {
    Logout();

    window.location = "/LoginAdmin";
  };

  return (
    <div>
      <Nav>
        <img src={logov} width="20%" alt="Example2" />

        <Bars />

        <NavMenu>
          <NavLink to="/HomeAdmin" activeStyle>
            ACCUEIL
          </NavLink>

          <NavLink to="/HomeAdmin/AddBlog" activeStyle>
            AJOUTER UN ARTICLE
          </NavLink>

          <NavLink to="/HomeAdmin/ConsulterARTIClE" activeStyle>
            CONSULTER LES ARTICLES
          </NavLink>

          <NavLink to="/HomeAdmin/GestiondDeComptes" activeStyle>
            GESTION DES COMPTES
          </NavLink>

          <NavBtn>
            <NavBtnLink to="/" onClick={hendleLogout}>
              Déconnexion
            </NavBtnLink>
          </NavBtn>
        </NavMenu>
      </Nav>
    </div>
  );
};

export default NavbarAdmin;
