import React, {useState, useEffect} from "react";
import {Table, Button, Form} from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import fileDownload from "js-file-download";
import {Table_Expert_Simister_exacte} from "../../../../../../../../api/Clinets";
import {
  supprimeSinister,
  supprimeSinisterRapportDifintif,
  supprimeSinisterRapportPreliminaire,
  updateNumSin ,updateNumCont , updatedate
} from "../../../../../../../../api/Sinister";
import {
  updateSinisterRapportPreliminaireMontat,
  updateSinisterRapportDifintifMontat,
} from "./../../../../../../../../api/Sinister";
import {BsDownload, BsPencil, BsXOctagon} from "react-icons/bs";

const TableauSimster = ({ Id_CLIENT }) => {
  const [posts, setPosts] = useState([]);
 

  useEffect(() => {
    getBlogPost();
  }, []);

  const getBlogPost = async () => {
    try {
      const response = await Table_Expert_Simister_exacte({iduser: Id_CLIENT});
      const data = response.data;
      setPosts(data.simster);
    } catch (error) {
      console.error("Error retrieving data:", error);
      alert("Error retrieving data!!!");
    }
  };

  const handleClick = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
        console.log(filename);
      });
  };

  const handleClickSupprime = async (idSimister) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Sinister? " + idSimister
    );
    if (confirmed) {
      try {
        await supprimeSinister(idSimister);
        getBlogPost();
      } catch (error) {
        console.error("Error moving item to trash:", error);
      }
    }
  };

  const handleClickSupprimeRapportPreminare = async (
    rappName,
    index,
    idSims
  ) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Report? " + rappName
    );
    if (confirmed) {
      try {
        await supprimeSinisterRapportPreliminaire({index, id: idSims});
        getBlogPost();
      } catch (error) {
        console.error("Error moving item to trash:", error);
      }
    }
  };

  const handleClickUpdateMontaRappPriminer = async (
    rappName,
    idSims,
    oldMont
  ) => {
    const confirmed = window.confirm(
      `Are you sure you want to update the Montant of the report '${rappName}' from: ${oldMont} to: ${newRappMont}`
    );
    if (confirmed) {
      try {
        await updateSinisterRapportPreliminaireMontat({
          rappName: rappName,
          id: idSims,
          newRappMont,
          oldMont,
        });
        getBlogPost();
        SetnewRappMont("");
        setShowInput({show: false});
      } catch (error) {
        console.error("Error moving item to trash:", error);
      }
    }
  };

  const handleClickSupprimeRapportDefintif = async (
    rappName,
    index,
    idSims
  ) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Report? " + rappName
    );
    if (confirmed) {
      try {
        await supprimeSinisterRapportDifintif({index, id: idSims});
        getBlogPost();
      } catch (error) {
        console.error("Error moving item to trash:", error);
      }
    }
  };

  const handleClickUpdateMontaRappDefintif = async (
    rappName,
    idSims,
    oldMont
  ) => {
    const confirmed = window.confirm(
      `Are you sure you want to update the Montant of the report '${rappName}' from: ${oldMont} to: ${newRappMont}`
    );
    if (confirmed) {
      try {
        await updateSinisterRapportDifintifMontat({
          rappName: rappName,
          id: idSims,
          newRappMont,
          oldMont,
        });
        getBlogPost();
        SetnewRappMont("");
        setShowInputDefntif({show: false});
      } catch (error) {
        console.error("Error moving item to trash:", error);
      }
    }
  };

  const [showInput, setShowInput] = useState({
    show: false,
    indexbtn: "",
  });

  const [newRappMont, SetnewRappMont] = useState("");

  const handleNewRappMontChange = (event) => {
    SetnewRappMont(event.target.value);
  };

  const handleClicksupprimeRapportDefntif = async (rappName, index, idSims) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Report? " + rappName
    );
    if (confirmed) {
      try {
        await supprimeSinisterRapportDifintif({index, id: idSims});
        getBlogPost();
      } catch (error) {
        console.error("Error moving item to trash:", error);
      }
    }
  };

  const handlClickUpdateMontaRappDefintif = async (
    rappName,
    idSims,
    oldMont
  ) => {
    const confirmed = window.confirm(
      "Are you sure you want to update the Montant of the report '" +
        rappName +
        "' from: " +
        oldMont +
        " to: " +
        newRappMont
    );
    if (confirmed) {
      try {
        await updateSinisterRapportDifintifMontat({
          rappName: rappName,
          id: idSims,
          newRappMont: newRappMont,
          oldMont: oldMont,
        });
        getBlogPost();
        SetnewRappMont("");
        setShowInputDefntif({show: false});
      } catch (error) {
        console.error("Error moving item to trash:", error);
      }
    }
  };

  const [showInputDefntif, setShowInputDefntif] = useState({
    show: false,
    indexbtn: "",
  });

  const [showInputSin, setShowInputSin] = useState({
    show: false,
    indexbtn: "",
  });


  const [showInputNumCont, setShowInputNumCont] = useState({
    show: false,
    indexbtn: "",
  });

  const [showInputdate, setshowInputdate] = useState({
    show: false,
    indexbtn: "",
  });
  const [newNumeroSim, setnewNumeroSim] = useState("");

  const handlechangenewNumeroSim = async (id) => {
    const confirmed = window.confirm("Are you sure you want to update");
    if (confirmed) {
      try {
        await updateNumSin({
          id: id,
          newNumeroSim: newNumeroSim,
        });
        getBlogPost();
        setnewNumeroSim("");
        setShowInputSin({show: false});
      } catch (error) {
        console.error("Error moving item to trash:", error);
      }
    }
  };



  
  const handleSinChange = (event) => {
    setnewNumeroSim(event.target.value);
  };


  const [NumCont, setnumCont] = useState("");
  const [chnagedate, setchnagedate] = useState("");
    
  const handlenumcont = (event) => {
    setnumCont(event.target.value);
  };


  const handlechnagedate = (event) => {
    setchnagedate(event.target.value);
  };

  const handlechangeNumCont = async (id) => {
    const confirmed = window.confirm("Are you sure you want to update");
    if (confirmed) {
      try {
        await updateNumCont({
          id: id,
          NumCont: NumCont,
        });
        getBlogPost();
        setnumCont("");
        setShowInputNumCont({show: false});
      } catch (error) {
        console.error("Error moving item to trash:", error);
      }
    }
  };

  
  const handlechangechnagedate = async (id) => {
    const confirmed = window.confirm("Are you sure you want to update");
    if (confirmed) {
      try {
        await updatedate({
          id: id,
          chnagedate: chnagedate,
        });
        getBlogPost();
        setchnagedate("");
        setShowInputNumCont({show: false});
      } catch (error) {
        console.error("Error moving item to trash:", error);
      }
    }
  };


  return (
    <div>
      <Table bordered>
        <thead variant="dark">
          <tr>
            <th></th>
            <th scope="col">Numéro Sinistre</th>
            <th scope="col">Date de sinistre</th>
            <th scope="col">Numéro Contrat</th>
            <th scope="col">Rapport Préliminaire</th>
            <th scope="col">Rapport définitif</th>
            <th scope="col">Joindre un rapport Préliminaire</th>
            <th scope="col">Joindre un rapport définitif</th>
            <th scope="col">Garantie concernée</th>
            <th scope="col">les causes</th>
            <th scope="col">les parties endommagees</th>
          </tr>
        </thead>
        <tbody>
          {posts.map((post, indexpost) => {
            if (post.ID_Filer_Client === Id_CLIENT) {
              return (
                <tr>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleClickSupprime(post._id)}
                    >
                      Supprime
                    </Button>{" "}
                  </td>
                  <td>
                    {post.numSIM}
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() =>
                        setShowInputSin({
                          show: !showInputSin.show,
                          indexbtn: indexpost,
                        })
                      }
                    >
                      <BsPencil />
                    </Button>{" "}
                    {showInputSin.show &&
                      showInputSin.indexbtn === indexpost && (
                        <div>
                          <Form.Control
                            key={indexpost}
                            type="text"
                            value={newNumeroSim}
                            onChange={handleSinChange}
                            placeholder="Enter new numero "
                          />

                          <Button
                            onClick={() => handlechangenewNumeroSim(post._id)}
                          >
                            Update{" "}
                          </Button>
                        </div>
                      )}
                  </td>
                  <td>{post.DateSim}
                  
                  <Button
                  variant="warning"
                  size="sm"
                  onClick={() =>
                    setshowInputdate({
                      show: !showInputdate.show,
                      indexbtn: indexpost,
                    })
                  }
                >
                  <BsPencil />
                </Button>{" "}
                {showInputdate.show &&
                  showInputdate.indexbtn === indexpost && (
                    <div>
                      <Form.Control
                        key={indexpost}
                        type="date"
                        value={chnagedate}
                        onChange={handlechnagedate}
                        placeholder="Enter new numero "
                      />

                      <Button
                        onClick={() => handlechangechnagedate(post._id)}
                      >
                        Update{" "}
                      </Button>
                    </div>
                  )}
                  
                  

                  
                  </td>
                  <td>{post.NumCont}
                  
                  <Button
                  variant="warning"
                  size="sm"
                  onClick={() =>
                    setShowInputNumCont({
                      show: !showInputNumCont.show,
                      indexbtn: indexpost,
                    })
                  }
                >
                  <BsPencil />
                </Button>{" "}
                {showInputNumCont.show &&
                  showInputNumCont.indexbtn === indexpost && (
                    <div>
                      <Form.Control
                        key={indexpost}
                        type="text"
                        value={NumCont}
                        onChange={handlenumcont}
                        placeholder="Enter new numero "
                      />

                      <Button
                        onClick={() => handlechangeNumCont(post._id)}
                      >
                        Update{" "}
                      </Button>
                    </div>
                  )}
                  

                  
                  
                  </td>
                  <td style={{padding: " 0px"}}>
                    <Table bordered>
                      <thead variant="dark">
                        <tr>
                          <th scope="col">Rapport</th>
                          <th scope="col">montant</th>
                        </tr>
                      </thead>
                      <tbody>
                        {post.rapp_preminare.map((name, index) => (
                          <tr>
                            <td>
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() =>
                                  handleClickSupprimeRapportPreminare(
                                    name.rapp_p,
                                    index,
                                    post._id
                                  )
                                }
                              >
                                <BsXOctagon />
                              </Button>
                              <Button
                                className="btn btn-light"
                                onClick={() =>
                                  handleClick(
                                    "../../../../../../../../../../uploads/" +
                                      name.rapp_p,
                                    name.rapp_p
                                  )
                                }
                              >
                                {" "}
                                <BsDownload />
                              </Button>{" "}
                              {"  "}
                              {name.rapp_p}
                            </td>
                            <td>
                              <Button
                                variant="warning"
                                size="sm"
                                onClick={() =>
                                  setShowInput({
                                    show: !showInput.show,
                                    indexbtn: index,
                                  })
                                }
                              >
                                <BsPencil />
                              </Button>{" "}
                              <br />
                              {name.montant_preminare}
                              {showInput.show &&
                                showInput.indexbtn === index && (
                                  <div>
                                    <Form.Control
                                      key={index}
                                      type="text"
                                      value={newRappMont}
                                      onChange={handleNewRappMontChange}
                                      placeholder="Enter new Rapp Montant "
                                    />

                                    <Button
                                      onClick={() =>
                                        handleClickUpdateMontaRappPriminer(
                                          name.rapp_p,
                                          post._id,
                                          name.montant_preminare
                                        )
                                      }
                                    >
                                      Update{" "}
                                    </Button>
                                  </div>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </td>
                  <td style={{padding: " 0px"}}>
                    <Table bordered>
                      <thead variant="dark">
                        <tr>
                          <th scope="col">Rapport</th>
                          <th scope="col">montant</th>
                        </tr>
                      </thead>
                      <tbody>
                        {post.rapp_definitif.map((name, index) => (
                          <tr>
                            <td>
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() =>
                                  handleClickSupprimeRapportDefintif(
                                    name.rapp_d,
                                    index,
                                    post._id
                                  )
                                }
                              >
                                <BsXOctagon />
                              </Button>
                              <Button
                                className="btn btn-light"
                                onClick={() =>
                                  handleClick(
                                    "../../../../../../../../../../uploads/" +
                                      name.rapp_d,
                                    name.rapp_d
                                  )
                                }
                              >
                                {" "}
                                <BsDownload />
                              </Button>{" "}
                              {"  "}
                              {name.rapp_d}
                            </td>
                            <td>
                              <Button
                                variant="warning"
                                size="sm"
                                onClick={() =>
                                  setShowInputDefntif({
                                    show: !showInputDefntif.show,
                                    indexbtn: index,
                                  })
                                }
                              >
                                <BsPencil />
                              </Button>{" "}
                              <br />
                              {name.montant_definitif}
                              {showInputDefntif.show &&
                                showInputDefntif.indexbtn === index && (
                                  <div>
                                    <Form.Control
                                      key={index}
                                      type="text"
                                      value={newRappMont}
                                      onChange={handleNewRappMontChange}
                                      placeholder="Enter new Rapp Montant "
                                    />

                                    <Button
                                      onClick={() =>
                                        handlClickUpdateMontaRappDefintif(
                                          name.rapp_d,
                                          post._id,
                                          name.montant_definitif
                                        )
                                      }
                                    >
                                      Update{" "}
                                    </Button>
                                  </div>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </td>
                  <td>
                    <Link
                      to={{
                        pathname: "/Admin/Ajouter_rapp_sinistre",
                        state: post._id,
                      }}
                    >
                      <Button variant="outline-info">Joindre</Button>
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={{
                        pathname: "/Admin/Ajouter_rappdrfinitif",
                        state: post._id,
                      }}
                    >
                      <Button variant="outline-info">Joindre</Button>
                    </Link>
                  </td>
                  <td>
                    {post.Garantieconcernee.map((tag2, index2) => (
                      <div key={index2}>{tag2}</div>
                    ))}
                  </td>
                  <td>
                    {post.causes.map((tag2, index2) => (
                      <div key={index2}>{tag2}</div>
                    ))}
                  </td>
                  <td>
                    {post.endommagees.map((tag, index) => (
                      <div key={index}>{tag}</div>
                    ))}
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default TableauSimster;
