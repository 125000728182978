import React, { Component } from 'react' ;
import NavbarAdmin from '../../Navbar/NavbarAdmin';
import { Container} from 'react-bootstrap'; 


export default class Ajouter_rap extends Component {
  render() {
    return (
        <div>
            <NavbarAdmin />
                <Container >
                   <h1> Ajouter un rapporte </h1>
                   <p>Nom de client : test <br/>  Numéro Du compte : 1236</p>
                </Container>
        </div>
     
    )
  }
}