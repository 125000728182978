import React from 'react';
import './../../css/style.css' ; 
 
import { Container, Row, Col } from 'react-bootstrap';
 
import  icons2 from './../../../img/icons2.svg';
import  icons3 from './../../../img/icons3.svg';
import  asurnce from './../../../img/asurnce.svg';

import AOS from 'aos';
const Client = ()=> { 
    
  AOS.init({
    
  })

    return ( 
 
     <div>
   

     <div className="secandparts">
    
    <div className='secands'>
    <Container> 
    <div className='Clinets'> 
      <h1 style={{'font-size':'30px'}} >Nos clients  </h1> 
      <div style={{'margin-top': '100px'}}></div>
    <Row >  
         
         
      
        <Row   >
            <Col className="item climg"  data-aos="flip-left"  data-aos-offset="250"md>
               <img alt='' className = 'imgExp' src={asurnce} />
               <p>Les compagnies d’assurances</p>
            </Col>

            <Col  className="item climg"  data-aos="flip-left"  data-aos-offset="250" md>
                 <img alt='' className = 'imgExp' src={icons2} />
                <p>Les réassureurs</p>
         
            </Col>
            <Col  className="item climg"  data-aos="flip-left"  data-aos-offset="250" md>
      
               <img  alt=''className = 'imgExp' src={icons3} />
                <p>Les courtiers</p>   
           </Col> 
           
           <Col className="climg"  data-aos="flip-left"  data-aos-offset="250"  md>
               <img  alt='' className = 'imgExp' src={asurnce} />
               <p>Les banques</p>
           
          </Col>
       </Row>

               <Row>
               <Col    data-aos="flip-left"  data-aos-offset="250"  xs lg="6">
               <img alt='' className = 'imgExp' src={icons2} style={{"alignItems" : "center"}}/>
            <p>Les industries, les ERP (établissements recevant public), les cabinets de conseils et les bureaux d’études…</p>
            
               </Col>

               <Col  data-aos="flip-left"  data-aos-offset="250"   xs lg="6">
               <img alt='' className = 'imgExp' src={icons3} />
               <p>Tout autre entreprise souhaitant réaliser des audits de prévention des risques </p>
            
             
                                   
                             </Col>  
                             </Row>
          </Row>
          </div>
    </Container>
          </div>
    </div>

 
    </div>
 
      );
    } 

export default  Client  ;