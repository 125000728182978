import React from "react";
import "./css/style.css";
import "./style2.scss";

import ApprochFormulaire from "./Compusnte/approche/ApprochFormulaire";
import ApprochTop from "./Compusnte/approche/ApprocheTop";
import ApprocheBtn from "./Compusnte/approche/ApprocheBtn";
import Footer from "./Footer/Footer1";

const Approche = () => {
  return (
    <div>
      <ApprochTop />
      <ApprocheBtn />
      <ApprochFormulaire />
      <Footer />
    </div>
  );
};

export default Approche;
