import React from "react";
import { filer_client } from "../../../../api/Clinets"; import {Row, Table, Col, Button} from "react-bootstrap";
import {Link} from "react-router-dom"; 
import ItemList from "../../../Adimn/Gestion de compts/Accuile/sous_cleint/Lineitem";
import ItemListtoutl from "../../../Adimn/Gestion de compts/Accuile/sous_cleint/Lineitemtoutl";

class List_filer extends React.Component {
  state = {
    NameFlier: "",
    nameCt: "",
    ID: "",
    posts: [],
    isOpen: false,
  };

  componentDidMount = () => {
    this.getBlogPost();
  };

  getBlogPost = () => {
    filer_client()
      .then((response) => {
        const data = response.data;

        this.setState({posts: data.filerClient});
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  };
 
  render() {
    const Id = this.props.Id_client;
    const Id_SOUS_CLIENT = this.props.Id_SOUS_CLIENT;
    console.log('==========Id_SOUS_CLIENT==========================');
    console.log(Id_SOUS_CLIENT);
    console.log('====================================');
    const name = this.props.NameCt;
    

    

    return (
      <div>
        <Row className=" justify-content-md-center ">
          <Col md="6">
            <Row>
              <Col md="12"></Col>
              <Col md="12">
                <ItemListtoutl NAME_SOUSCLIENT={name} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className=" justify-content-md-center ">
          <Col md="6">
            <Row>
              <Col md="12">
                <Table striped>
                  <tbody>
                    {this.state.posts.map((post) => {
                      if (post.Name_SousClient === name) {
                        // fetchData(post.Id_SOUS_CLIENT);
                        return (
                          <tr>
                            <td>{post.Name_filer}</td>
                            <td>
                              <Link
                                className="float-right btn btn-info btn-sm"
                                to={{
                                  pathname: "/Filiere",
                                  state: {
                                    Id_Filer: post._id,
                                    NameCtt: post.Name_client,
                                    NAME_Filer: post.Name_filer,
                                    Name_sous_client: post.Name_SousClient,
                                    Id_CLIENT: Id,

                                    Id_SOUS_CLIENT: Id_SOUS_CLIENT,
                                  },
                                }}
                              >
                                Consulter
                              </Link>
                            </td>
                         

                            <ItemList idcl={post._id} />
                         
                          </tr>
                        );
                      }
                      return true;
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default List_filer;
