import React from 'react'
import './../css/style.css' ; 
class Navbar extends React.Component {
 
    render() {
      
      return ( 

    <div>
    
    <nav className="navbar navbar-expand-lg navbar-light ftco_navbar bg-light ftco-navbar-light" id="ftco-navbar">
     
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="fa fa-bars"></span> Menu
      </button>
      <div className="collapse navbar-collapse" id="ftco-nav">
        <ul className="navbar-nav m-auto">
            <li className="nav-item active"><a href="/" className="nav-link">Accueil  </a></li>
            <li className="nav-item"><a href="Approche" className="nav-link">Notre approche</a></li> 
            <li className="nav-item  dropdown">
            <a className="nav-link dropdown-toggle " href="/NOSOFFRES" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Nos offres
            </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <a className="dropdown-item " href="/NOSOFFRES">Nos offres</a>
              <div className="dropdown-divider"></div>

                <a className="dropdown-item" href="/Analyse-risque-incendie">Analyse risque incendie</a>
                <a className="dropdown-item" href="/APCI">APCI : audit prévention et conseil incendie</a>
                <a className="dropdown-item" href="/Analyse-de-risque-Bris-de-machine">Analyse de risque Bris de machine </a>
                <a className="dropdown-item" href="/Audit-des-installations-électriques">Audit des installations électriques</a>
                <a className="dropdown-item" href="/Analyse-de-la-vulnérabilité">Analyse de la vulnérabilité </a>
                <a className="dropdown-item" href="/Visite-de-risques-à-la-souscription">Visite de risques à la souscription </a>
                <a className="dropdown-item" href="/Visite-de-prévention">Visite de prévention </a>
                <a className="dropdown-item" href="/Expertise-sinistre-IARD">Expertise sinistre IARD </a>
                <a className="dropdown-item" href="/Estimation-prealable-des-capitaux">Estimation préalable des capitaux /valorisation des capitaux à la juste valeur </a>
              
              </div>
          </li>
          <li className="nav-item"><a href="actualites" className="nav-link">actualites </a></li>
            
            <li className="nav-item"><a href="Specialites" className="nav-link">nos spécialités </a></li>
            <li className="nav-item"><a href="Contact" className="nav-link">Contact</a></li>
            <li className="nav-item"><a href="connexion" className="nav-link">connexion</a></li>
        </ul>
      </div>
 
  </nav>
    
    </div>
    )
}
}

export default Navbar ;