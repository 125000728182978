import axios from "axios";

export const supprimeSinister = async (idSimister) => {
  const response = await axios.post(`/api/Clients/supprimeSinister/${idSimister}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};


export const supprimeSinisterRapportPreliminaire = async (data) => {
  const response = await axios.post(`/api/Clients/supprimeSinisterRapportPreliminaire`, data);
  return response;
};




export const updateSinisterRapportPreliminaireMontat = async (data) => {
  const response = await axios.post(`/api/Clients/updateSinisterRapportPreliminaireMontat`, data);
  return response;
};




export const updateNumSin = async (data) => {
  const response = await axios.post(`/api/Clients/updateNumSin`, data);
  return response;
};
export const updateNumCont = async (data) => {
  const response = await axios.post(`/api/Clients/updateNumCont`, data);
  return response;
};

export const updatedate = async (data) => {
  const response = await axios.post(`/api/Clients/updatedate`, data);
  return response;
};






export const supprimeSinisterRapportDifintif = async (data) => {
  const response = await axios.post(`/api/Clients/supprimeSinisterRapportDifintif`, data);
  return response;
};




export const updateSinisterRapportDifintifMontat = async (data) => {
  const response = await axios.post(`/api/Clients/updateSinisterRapportDifintifMontat`, data);
  return response;
};
