
import React from 'react'
import {   Container  , Row, Col } from 'react-bootstrap';
import "./../../../style2.scss" ; 
import "./../../../css/style.css"
import { FcNext  } from "react-icons/fc";
import {Link} from "react-router-dom";
import Card2 from '../Card2';
import Card3 from '../Card3';
import Card4 from '../Card4';
import Card5 from '../Card5';
import Card6 from '../Card6';
import Card from '../Card';
import Card9 from '../Card9';
import Card7 from '../Card7';
import NavbarOffres from './../../../Navbar/NavbarOffres';
import Navbarstnd from './../../../Navbar/Navbarstnd';
import Footter from './../../../Footer/Footer1';

const Estimationprealabledescapitaux = ()=> { 
       
    return ( 
 
        <div>
      
        <div className='backgroundOffres'>
        <div className='backr'>
                <div className='backr'>
                <Navbarstnd />
                </div>
                <NavbarOffres/> 
        <div style={{paddingBottom : 30 , margin : 40 }} ></div>
      </div>
      </div>
   
   
      <div className="secandparts">
       
      <div className='secands'>
      <Container>
      <Row className=' justify-content-md-center'> 
        <Col   style={{marginTop : 30  }} >
          <h1 >Estimation préalable des capitaux / Valorisation des capitaux à la juste valeur   </h1>
          
            <p>
            Grâce à nos outils avancés nous offrons des missions de valorisation des capitaux dont les avantages sont immédiats :
           </p>
           <div className="EStt" >
           <Container>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FcNext/> Plan de prévention et d’atténuation des risques  <br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FcNext/> Conseil et accompagnement en matière d’assurance des risques <br/> <br />
                &nbsp;&nbsp;&nbsp;&nbsp; <FcNext/> Analyse de la sinistralité et de leur portée  </p>
          </Container>
                </div>
         <div>
         <Container>
         <Row>
         <Link    to={{
             pathname: "/Form",
             state: "Estimation préalable des capitaux / Valorisation des capitaux à la juste valeur  "  ,
             }} >
             <button className='demandeBTN'>Demander un devis</button>
          </Link>
             </Row>
         </Container>
         
         </div>

            </Col>  
            </Row>
   

         
            </Container>   
            </div>









            
            <div className="autreOffer">
            <Container>
            <Row className=' justify-content-md-center'> 
          <h1 > Nos autres offres   </h1>
         





 
          <Col md={4}>
          <Card />
      </Col> 
      <Col md={4}>
          <Card2 />
      </Col> 
      <Col md={4}>
           <Card3 />
      </Col> 
      <Col md={4}>
           <Card4 />
      </Col> 
      <Col md={4}>
            <Card5 />
      </Col>  
       <Col md={4}>
           <Card6 />
      </Col>   
      <Col md={4}>
            <Card7 />
      </Col>   
       <Col md={4}>
           <Card9 />
      </Col>   



            </Row>
            </Container>   
            </div>



      </div>
   
    
   
      <Footter /> 
   
   
   
   </div>  
      
   
);
}
export default Estimationprealabledescapitaux ;

 
