import React from 'react'
import './../../css/style.css' ;  
import "./../../style2.scss" ; 

const Card7 = ()=> { 
    
      
    return ( 
 
     <div>
   



        <div className="testimotionals">
        <div className="card">
          <div className="layer">
            
          </div>
          <div className="content">
           
            <div className="details">
              <h2>Visite de prévention</h2>
              <a href="Visite-de-prévention" class="nav-link"> <button className='ligthbtn'>Découvrir</button> </a>
      
            </div>
          </div>
        </div>
      </div>
 

 
</div>
 
      );
    } 

export default  Card7  ;