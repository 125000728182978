import React from 'react';
import './../../css/style.css' ; 
 
import "./../../style2.scss" ;
import { Container, Row, Col } from 'react-bootstrap'; 
import Navbarstnd from '../../Navbar/Navbarstnd';
import SaideRitheFromuler from './SaideRitheFromuler';
import SaideLeftFromuler from './SaideLeftFromuler';
import NavbarOffres from './../../Navbar/NavbarOffres';

const Formuler = ()=> { 
    
      
    return ( 
 
     <div>
        
      
     <div className='backgroundOffres'>
     <div className='backr'>
             <div className='backr'>
             <Navbarstnd />
             </div>
     <NavbarOffres/> 
     <div style={{paddingBottom : 30 , margin : 40 }} ></div>
   </div>
   </div>

     <div> 
 <Container>
 <Row>
   <Col>
   <SaideRitheFromuler />
   </Col>
   <Col xs lg="4" >
   <SaideLeftFromuler />
   </Col>
 </Row>

</Container>
     </div>
 
    </div>
 
      );
    } 

export default  Formuler  ;