import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {NavLink} from "react-router-dom";
import "../Adimn/Gestion de compts/Accuile/sous_cleint/Filière/nav.scss";

function Saidbar_disable(props) {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/DashBord",
                state: {
                  Id_CLIENT: props.Id_CLIENT,
                },
              }}
              activeClassName="activeClicked"
            >
              Accueil{"  "}
            </NavLink>

            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/client/ExpertisSinister",
                state: {
                  Id_CLIENT: props.Id_CLIENT,
                },
              }}
              activeClassName="activeClicked"
            >
              Expertise Sinistre{" "}
            </NavLink>

            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/client/visite_risque",
                state: {
                  Id_CLIENT: props.Id_CLIENT,
                },
              }}
              activeClassName="activeClicked"
            >
              Visite de Risque
            </NavLink>

            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/client/visite de peretion client",
                state: {
                  Id_CLIENT: props.Id_CLIENT,
                },
              }}
              activeClassName="activeClicked"
            >
              Visite de prévention{" "}
            </NavLink>

            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/client/risk survey client",
                state: {
                  Id_CLIENT: props.Id_CLIENT,
                },
              }}
              activeClassName="activeClicked"
            >
              Risk Survey
            </NavLink>

            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/client/Estimation prelible client",
                state: {
                  Id_CLIENT: props.Id_CLIENT,
                },
              }}
              activeClassName="activeClicked"
            >
              Estimation préalable
            </NavLink>

            <NavLink
              style={{
                "text-decoration": "none",
                color: "rgb(218, 218, 218)",
                margin: "10px",
              }}
              exact
              to={{
                pathname: "/client/stastistique client",
                state: {
                  Id_CLIENT: props.Id_CLIENT,
                },
              }}
              activeClassName="activeClicked"
            >
              Statistiques
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Saidbar_disable;
