
import React from 'react'
import { Container  , Row, Col } from 'react-bootstrap';
import "./../../../style2.scss" ; 
import "./../../../css/style.css"
import { FaCircle } from "react-icons/fa";

import {Link, } from "react-router-dom";
import Navbarstnd from '../../../Navbar/Navbarstnd';
import Card2 from '../Card2';
import Card3 from '../Card3';
import Card4 from '../Card4';
import Card5 from '../Card5';
import Card6 from '../Card6';
import Card from '../Card';
import Card9 from '../Card9';
import Card10 from '../Card10';
import NavbarOffres from './../../../Navbar/NavbarOffres';
import Footter from './../../../Footer/Footer1';
import AOS from 'aos';
import ApprochFormulaire from './../../approche/ApprochFormulaire';


const Visitedeprevention = ()=> { 
    
  
  AOS.init({
  
  })

    return ( 
 
        <div>
      
        <div className='backgroundOffres'>
        <div className='backr'>
                <div className='backr'>
                <Navbarstnd />
                </div>
                <NavbarOffres /> 
        <div style={{paddingBottom : 30 , margin : 40 }} ></div>
      </div>
      </div>
   
   
      <div className="secandparts">
       
      <div className='secands'>
      <Container>
      <Row className=' justify-content-md-center'> 
        <Col   style={{marginTop : 30  }} >
          <h1 >Visite de prévention    </h1>
           <p>
           Consiste à évaluer le système de sécurité, accompagner les clients et les conseiller pour améliorer la démarche 
           </p>
           <p>
           Le risque industriel est défini comme un évènement accidentel se produisant sur un site industriel mettant en jeu des produits et/ou des procédés dangereux et entraînant des conséquences immédiates graves pour le personnel, les biens, l’activité et l'environnement.
           </p> 
           <p>
           Afin d'en limiter la survenue et les conséquences, les établissements les plus dangereux sont soumis à une réglementation particulière (classement des installations) et à des contrôles réguliers.
           </p>
            <p>
            Néanmoins, ce n’est pas parce qu’un site n’est pas classé qu’il ne présente pas de danger.
           </p>
            </Col> 

            </Row>
   
            <Row>
            <Link    to={{
                pathname: "/Form",
                state: "Visite de prévention "  ,
                }} >
                <button className='demandeBTN'>Demander un devis</button>
             </Link>
                </Row>
         
            </Container>   
            </div>


<div className='visite'>

<Container>
<Row >
        <div  className='titreVisite'>
            <h1  data-aos="fade-right" data-aos-offset="300">
             Les principaux dangers qui peuvent se manifester 
            </h1>
 
      </div>
  

</Row>



<Row>



<Col>
     
        
             
            <h2  data-aos="fade-up" data-aos-offset="300">
            <FaCircle /> L’incendie
            </h2>
       
    
      <p  data-aos="fade-up" data-aos-offset="300">Dû à l’ignition de combustibles par une flamme ou un point chaud, l’explosion due au mélange combustible /comburant (air) avec libération brutale de gaz, pouvant toucher un Process, une ligne de production, un équipement ou la totalité de l’entreprise </p>
    



</Col>




<Col>
     
        
             
            <h2  data-aos="fade-up" data-aos-offset="300">
            <FaCircle />  Les risques naturels 
            </h2>
       
    
      <p  data-aos="fade-up" data-aos-offset="300">Les inondations, mouvements de terrain, séismes…</p>
    
</Col>


<Col>
    
                <h2  data-aos="fade-up" data-aos-offset="300">
                <FaCircle />   Les risques technologiques
                </h2>
           
         <p  data-aos="fade-up" data-aos-offset="300">
         Risques de nature industrielle, nucléaires, liés à la radioactivité, aux transports de matières dangereuses … 
         </p>
        
</Col>

</Row>

</Container>


</div>



<div className="secandparts">
       
<div className='secands'>
<Container>
<Row className=' justify-content-md-center'> 
  <Col   style={{marginTop : 30  }} >
    <h1 >Danger, Risque, Accident : des notions communes    </h1>
     <p>
     La prévention des risques industriels, qu’ils soient professionnels ou environnementaux, s’appuie sur les principales notions suivantes : danger, risque, accident ou dommage. 
     </p>
     <p> Le Code du travail et le Code de l’environnement définissent le risque de la même manière ; la notion d’exposition d’une cible à un danger y est intégrée.
     </p>
     <p>
     Les deux codes exigent une évaluation des risques, sur laquelle va reposer sur une identification des dangers puis une analyse détaillée des conditions d’exposition aux dangers.
     </p>
      </Col>  
      </Row>


   
      </Container>   
      </div>
      </div>

      <div style={{marginTop : "10%",marginBottom : "5%" }}>
      <ApprochFormulaire />
      
      </div>


            
            <div className="autreOffer">
            <Container>
            <Row className=' justify-content-md-center'> 
          <h1 > Nos autres offres   </h1>
          <Col md={4}>
          <Card />
          </Col> 
            <Col md={4}>
            <Card2 />
            </Col> 
            <Col md={4}>
            <Card3 />
            </Col>   <Col md={4}>
            <Card4 />
            </Col>   <Col md={4}>
            <Card5 />
            </Col>   <Col md={4}>
            <Card6 />
            </Col>     <Col md={4}>
            <Card9 />
            </Col>   <Col md={4}>
            <Card10 />
            </Col> 
            </Row>
            </Container>   
            </div>



      </div>
   
    
      <Footter /> 
    
   
   
   
   </div>  
      
   
);
}
export default Visitedeprevention ;

 
