import React  from 'react';
import './../../css/Login.css' ; 
import FromulerLogin from './FormulerLogin';
import { Container} from 'react-bootstrap'; 
import { Helmet } from "react-helmet";

const Login = ()=> { 
    

    return ( 
 
        <div>
            <Helmet>
                 <title>Login</title>
        
        <style>{'body { background: #06a5cc;background: -webkit-linear-gradient(top left, #06a5cc 0%, #54b2b4 100%);  }'}</style>
             </Helmet>
            <div className="login"  >
            <Container>
            <FromulerLogin />
    </Container>
            </div>
        </div>
 
      );
    } 

export default  Login  ;