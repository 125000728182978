
import React from 'react'
import {  Container   , Row, Col } from 'react-bootstrap';
import "./../../../style2.scss" ; 
import "./../../../css/style.css"
 
import {Link} from "react-router-dom";
import Navbarstnd from '../../../Navbar/Navbarstnd';
import Card2 from '../Card2';
import Card3 from '../Card3';
import Card4 from '../Card4';
import Card5 from '../Card5';
import Card from '../Card';
import Card7 from '../Card7';
import Card9 from '../Card9';
import Card10 from '../Card10';
import NavbarOffres from './../../../Navbar/NavbarOffres';
import Footter from './../../../Footer/Footer1';

const Visitederisquesalasouscription = ()=> { 
       
    return ( 
 
        <div>
      
        <div className='backgroundOffres'>
        <div className='backr'>
                <div className='backr'>
                <Navbarstnd />
                </div>
                <NavbarOffres /> 

        <div style={{paddingBottom : 30 , margin : 40 }} ></div>
      </div>
      </div>
   
   
      <div className="secandparts">
       
      <div className='secands'>
      <Container>
      <Row className=' justify-content-md-center'> 
        <Col   style={{marginTop : 30  }} >
          <h1 >Visite de risques à la souscription   </h1>
           <p>Consiste à évaluer les risques à la souscription des contrats pour une meilleure appréciation et tarification du risque
           </p>
          
           
            </Col>  
            </Row>
            <Row>
            <Link    to={{
                pathname: "/Form",
                state: "Visite de risques à la souscription "  ,
                }} >
                <button className='demandeBTN'>Demander un devis</button>
             </Link>
                </Row>
         
         
            </Container>   
            </div>


            
            <div className="autreOffer">
            <Container>
            <Row className=' justify-content-md-center'> 
          <h1 > Nos autres offres   </h1>
          <Col md={4}>
          <Card />
          </Col>   
            <Col md={4}>
            <Card2 />
            </Col> 
            <Col md={4}>
            <Card3 />
            </Col>   <Col md={4}>
            <Card4 />
            </Col>   <Col md={4}>
            <Card5 />
            </Col>     <Col md={4}>
            <Card7 />
            </Col>   <Col md={4}>
            <Card9 />
            </Col>   <Col md={4}>
            <Card10 />
            </Col> 
            </Row>
            </Container>   
            </div>



      </div>
   
    
   
    
      <Footter /> 
   
   
   </div>  
      
   
);
}
export default Visitederisquesalasouscription ;

 
