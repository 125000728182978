import React , {useState } from 'react';
 
import   isEmpty  from 'validator/lib/isEmpty'; 
import { Container, Row , Form, Col} from 'react-bootstrap'; 
import  logo from './../../img/PREV-EX AFRICA.jfif';
import { login } from '../../api/clientAuth';
import {setAuthentication , isAuthenticated} from '../../helpers/auth';
import AlertDanger from './../Compusnte/Alert/AlertDanger';
import loginm from "./../../img/file.svg";

const Connexion = ()=> { 
     
  

 

  const [formdata , setformdata]  = useState ({

     
    email : '', 
    password :'',
    errorMSg :'',



  }) ;



  const {
            
    email, 
    password ,
    errorMSg  ,

  } = formdata; 

  const handleChange = (evt) => {
  
    setformdata({
      ...formdata,
      [evt.target.name] : evt.target.value,
      errorMSg :'',
    });
 };

 
 
const handleSubmit = (evt) => {
  
    evt.preventDefault();
    if( isEmpty(email) || isEmpty(password)   ) {
    
      setformdata({
          ...formdata, errorMSg : 'Tous les champs sont requis'

      });
      console.log(formdata.errorMSg);

}else{
  const {email, password  } = formdata ; 
  const data = {email, password  } ;

  login(data)

  .then(response => {

    setAuthentication(response.data.token , response.data.client);

    if (isAuthenticated() ) {
      console.log('admin dash bord'); 
      
      window.location = '/DashBord' ; 
    }
    else{
      console.log('error dash bord');
    }
  })
  .catch ((err,response) =>{
    console.log('login error est',err);
    setformdata({
      ...formdata, errorMSg : 'Vérifiez votre email ou mot de pass'


   });
  });


  
}

   
   
                

  }

  const [showPassword, setShowPassword] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


return ( 
    <div> 
    <Container>
    <Row>
    <Col> 
    
    <img src={loginm} className='imgLogin' alt='' />
    </Col>
    <Col>
    <div className='blog_form_login' >


    <form  onSubmit={handleSubmit}>
    <img src={logo} className='logoLogin' alt='' />
    {AlertDanger(errorMSg) }
    <div className="mb-3">
      <label>Email address</label>
      <input
        type="email"
        className="form-control"
        placeholder="Enter email"
        name="email"
        onChange={handleChange}
        value = {email}  />
    </div>
    <label>Password</label>


    <Form.Control
    type={showPassword ? 'text' : 'password'}
    className="form-control"
    placeholder="Enter password"
    name="password"
    onChange={handleChange}
    value={password}
  />
  <input
    type="checkbox"
    checked={showPassword}
    onChange={togglePasswordVisibility}
  />
  <label>Show password</label>
    
    <div className="d-grid">
      <button  href="DashBord" type="submit" className="btn btn-primary">
        Submit
      </button>
    </div>
    
  </form>
    </div>
   
    </Col>
   
    </Row>
    </Container>
    </div>
 
 
      );
    } 

export default  Connexion  ;