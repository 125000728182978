import React from "react";
import {Table_Expert_Simister} from "../../../../../../../api/Clinets";
import {Form, Col, Row, Table, Button} from "react-bootstrap";
import {BsDownload} from "react-icons/bs";
import axios from "axios";
import {Link} from "react-router-dom";
import fileDownload from "js-file-download";
class TableauSimster extends React.Component {
  state = {
    numSIM: "",
    DateSim: "",
    NumCont: "",
    ID_Filer_Client: "",
    posts: [],
  };

  componentDidMount = () => {
    this.getBlogPost();
  };

  getBlogPost = () => {
    Table_Expert_Simister()
      .then((response) => {
        console.log("ok");
        const data = response.data;
        console.log(data);

        this.setState({posts: data.simster});
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  };

  render() {
    const num = this.props.ID_Filer_Client;
    const handleClick = (url, filename) => {
      axios
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res.data, filename);
          console.log(filename);
        });
    };

    return (
      <div>
        <Table bordered>
          <thead variant="dark">
            <tr>
              <th scope="col">Numéro Sinistre</th>
              <th scope="col">Date de sinistre </th>
              <th scope="col">Numéro Contrat</th>

              <th scope="col">Rapport Préliminaire</th>

              <th scope="col">Rapport Définitif</th>
              <th scope="col">Garantie concernée</th>

            <th scope="col">les causes</th>
              <th scope="col">les parties endommagees </th>
            </tr>
          </thead>

          <tbody>
            {this.state.posts.map((post) => {
              if (post.ID_Filer_Client === num) {
                return (
                  <tr>
                    <td>{post.numSIM}</td>

                    <td>{post.DateSim}</td>
                    <td>{post.NumCont}</td>

                    <td style={{padding: " 0px"}}>
                      <Table bordered>
                        <thead variant="dark">
                          <tr>
                            <th scope="col">Rapport</th>
                            <th scope="col">montant</th>
                          </tr>
                        </thead>

                        <tbody>
                          {post.rapp_preminare.map((name) => (
                            <tr>
                              <td>
                                <Button
                                  className="btn btn-light"
                                  onClick={() =>
                                    handleClick(
                                      "../../../../../../../../../uploads/" +
                                        name.rapp_p,
                                      name.rapp_p
                                    )
                                  }
                                >
                                  {" "}
                                  <BsDownload />
                                </Button>{" "}
                                {"  "}
                                {name.rapp_p}
                              </td>
                              <td>{name.montant_preminare}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </td>

                    <td style={{padding: " 0px"}}>
                      <Table bordered>
                        <thead variant="dark">
                          <tr>
                            <th scope="col">Rapport</th>
                            <th scope="col">Montant</th>
                          </tr>
                        </thead>

                        <tbody>
                          {post.rapp_definitif.map((name) => (
                            <tr>
                              <td>
                                <Button
                                  className="btn btn-light"
                                  onClick={() =>
                                    handleClick(
                                      "../../../../../../../../../../uploads/" +
                                        name.rapp_d,
                                      name.rapp_d
                                    )
                                  }
                                >
                                  {" "}
                                  <BsDownload />
                                </Button>{" "}
                                {"  "}
                                {name.rapp_d}
                              </td>
                              <td>{name.montant_definitif}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </td>
                    <td>
                    {post.Garantieconcernee.map((tag2, index2) => (
                      <div key={index2}>{tag2}</div>
                    ))}
                  </td>
                       <td>
                      {post.causes.map((tag2, index2) => (
                        <div key={index2}>{tag2}</div>
                      ))}
                    </td>
                    <td>
                      {post.endommagees.map((tag, index) => (
                        <div key={index}>{tag}</div>
                      ))}
                    </td>
                  </tr>
                );
              }
              return true;
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default TableauSimster;
