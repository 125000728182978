import React, {useState, useEffect} from "react";
import {filer_client, suprimeFilerClient} from "../../../../../api/Clinets";
import {Row, Table, Col, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaUserEdit} from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import UpdatesousclientFilier from "./UpdatesousclientFilier";
import ItemList from "./Lineitem";
import ItemListtoutl from "./Lineitemtoutl";

class List_filer extends React.Component {
  state = {
    NameFlier: "",
    nameCt: "",
    ID: "",
    posts: [],
    isOpen: false,
  };

  componentDidMount = () => {
    this.getBlogPost();
  };

  getBlogPost = () => {
    filer_client()
      .then((response) => {
        const data = response.data;

        this.setState({posts: data.filerClient});
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  };

  openModal = () => {
    this.setState({isOpen: true});
  };

  closeModal = () => {
    this.setState({isOpen: false});
  };

  render() {
    const Id = this.props.Id_client;
    const Id_SOUS_CLIENT = this.props.Id_SOUS_CLIENT;
    console.log('==========Id_SOUS_CLIENT==========================');
    console.log(Id_SOUS_CLIENT);
    console.log('====================================');
    const name = this.props.NameCt;
    const handleClickSuprime = async (id, name) => {
      if (window.confirm("Are you sure you want to delete " + name + " ?")) {
        await this.setState({ID: id});

        suprimeFilerClient(this.state)
          .then((response) => {
            alert("you have delete an acompte");
            window.location.reload(false);
          })
          .catch((err) => {
            alert(err);
          });
      }
    };

    const handleClickUpdate = async (id, name) => {
      await localStorage.setItem("ID_Sous_Client_update", id);
      await localStorage.setItem("Name_Sous_Client_update", name);

      this.openModal();
      //redurection to update page of client
      // window.location = "/Admin/updateClient";
    };

    return (
      <div>
        <Row className=" justify-content-md-center ">
          <Col md="6">
            <Row>
              <Col md="12"></Col>
              <Col md="12">
                <ItemListtoutl NAME_SOUSCLIENT={name} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className=" justify-content-md-center ">
          <Col md="6">
            <Row>
              <Col md="12">
                <Table striped>
                  <tbody>
                    {this.state.posts.map((post) => {
                      if (post.Name_SousClient === name) {
                        // fetchData(post.Id_SOUS_CLIENT);
                        return (
                          <tr>
                            <td>{post.Name_filer}</td>
                            <td>
                              <Link
                                className="float-right btn btn-info btn-sm"
                                to={{
                                  pathname: "/Admin/Filiere",
                                  state: {
                                    Id_Filer: post._id,
                                    NameCtt: post.Name_client,
                                    NAME_Filer: post.Name_filer,
                                    Name_sous_client: post.Name_SousClient,
                                    Id_CLIENT: Id,

                                    Id_SOUS_CLIENT: Id_SOUS_CLIENT,
                                  },
                                }}
                              >
                                Consulter
                              </Link>
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  handleClickSuprime(post._id, post.Name_filer);
                                }}
                                className="float-right btn btn-danger btn-sm"
                                style={{
                                  marginLeft: "10px",
                                  marginRight: " 10px",
                                }}
                              >
                                X
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="float-right btn btn-warning btn-sm"
                                onClick={() => {
                                  handleClickUpdate(post._id, post.Name_filer);
                                }}
                              >
                                <FaUserEdit />
                              </Button>
                            </td>

                            <ItemList idcl={post._id} />
                            <td>
                              <Modal
                                show={this.state.isOpen}
                                onHide={this.closeModal}
                              >
                                <Modal.Header closeButton></Modal.Header>
                                <Modal.Body>
                                  <UpdatesousclientFilier />
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={this.closeModal}
                                  >
                                    Close
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </td>
                          </tr>
                        );
                      }
                      return true;
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default List_filer;
