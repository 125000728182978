import React from 'react'; 
import { Table_Estimations_prelible   } from '../../../../../../api/Clinets';   
import {  Table,  Button } from 'react-bootstrap';
import { BsDownload } from 'react-icons/bs';

import {Link} from "react-router-dom";
import axios from 'axios';
import fileDownload from 'js-file-download';

class TableauSimster extends React.Component  {
 

    state = { 
      DateEstimations_prelible  :'', NumCont  :'', ID_Filer_Client  : '',posts: []
      };
    
      componentDidMount = () => {
        this.getBlogPost();
      };
    
      

  
      
      getBlogPost = () => {

        Table_Estimations_prelible()

          .then((response) => {
            console.log("ok")
            const data = response.data
            console.log(data)

            this.setState({ posts: data.estimations_prelible  });
          })
          .catch(() => {
            alert('Error retrieving data!!!');
          });
      }
     
    
     
     
 
    
     
     
    
      render() {
        const  num = this.props.ID_Filer_Client ;
        const handleClick = (url, filename) => {
          axios.get(url, {
            responseType: 'blob',
          })
          .then((res) => {
            fileDownload(res.data, filename);
            console.log(filename);
          })
        }
          return(
          <div >
                <Table striped bordered hover >
                     <thead variant="dark">
                     <tr>
                     <th scope="col">Date</th>
                     <th scope="col">Numéro Contrat</th>
                     <th scope="col">Rapport</th>
                     <th scope="col">Joindre un rapport</th>
                     <th></th>
                     </tr>
                </thead>

                  <tbody>
                           
                  {this.state.posts.map((post) => {
                    if ( post.ID_Filer_Client === num ) {
                      return (

   <tr>
                   
                      <td>
                      {post.DateEstimations_prelible }
                      </td>
                      <td>
                      {post.NumCont}
                      </td>
                      <td>
                      {post.rapp.map(name => <div>   <Button className="btn btn-light" onClick={() => handleClick('../../../../../../../../../../uploads/'+ name, name) } > <BsDownload /></Button>  {'  '}{name}<hr/> </div>)}
  
                      </td>
                      <td>
                      
                      <Link    to={{
                        pathname: "/Admin/Ajouter_rapp_Estimaions_prealable",
                        state: post._id  ,
                              }} >
                              <Button variant="outline-info">Joindre</Button>
                      </Link>


                      </td>
                       
                      </tr>

                      )
                   
                    }
                    return true ;
                  }
                      
                   
                      

                      
                    
                   
      
                  )}
             
          

        </tbody>
                </Table>
                
                </div>
               
                

        );
      }
    }
    
export default TableauSimster ;