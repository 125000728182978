import React, {useState, useEffect} from "react";
import {Container, Button, Table, Row, Col} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {
  readOneClient,
  Table_Expert_Simister_exacte,
  Table_Risque_exacte,
  Table_Visite_peretion_exacte,
  Table_Estimations_prelible_exacte,
} from "../../../../../../api/Clinets";
import {Link} from "react-router-dom";
import CollapsibleExample from "./NavbarClient";

const Accueil_Filiere = () => {
  const location = useLocation();

  const [statistics, setStatistics] = useState({
    xr: "",
    risque: "",
    vp: "",
    ep: "",
  });

  const {
    xr: expertSimister,
    risque: risk,
    vp: visitPeretion,
    ep: estimationsPrelible,
  } = statistics;

  const {
    Id_Filer,
    Id_CLIENT,
    NameCtt,
    NAME_Filer,
    Name_sous_client,
    Id_SOUS_CLIENT,
  } = location.state;
console.log('=====Id_SOUS_CLIENT=================Id_SOUS_CLIENT==============');
console.log(Id_SOUS_CLIENT);
console.log('====================================');
  const [formData, setFormData] = useState({
    _id: Id_CLIENT,
    EmailCt: "",
    NameCt: "",
    NumCt: "",
    Pass: "",
    TelCt: "",
  });

  console.log("====================================");
  console.log(
    Id_Filer,
    Id_CLIENT,
    NameCtt,
    NAME_Filer,
    Name_sous_client,
    Id_SOUS_CLIENT
  );
  console.log("====================================");

  const [myIdUser, setMyIdUser] = useState({iduser: Id_Filer});

  const fetchData = async () => {
    const response1 = await readOneClient(formData);
    setFormData(response1.data);

    const response2 = await Table_Expert_Simister_exacte(myIdUser);
    const expertSimisterCount = response2.data.simster.length;

    const response3 = await Table_Risque_exacte(myIdUser);
    const riskCount = response3.data.risque.length;

    const response4 = await Table_Visite_peretion_exacte(myIdUser);
    const visitPeretionCount = response4.data.visite_de_peretion.length;

    const response5 = await Table_Estimations_prelible_exacte(myIdUser);
    const estimationsPrelibleCount = response5.data.estimations_prelible.length;

    setStatistics({
      ...statistics,
      xr: expertSimisterCount,
      risque: riskCount,
      vp: visitPeretionCount,
      ep: estimationsPrelibleCount,
    });
  };

  useEffect(() => {
    fetchData();
    console.log("====================================");
    console.log(statistics);
    console.log("====================================");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goHome = () => {
    window.location = "/HomeAdmin";
  };

  return (
    <div>
      <CollapsibleExample
        Id_Filer={Id_Filer}
        Id_CLIENT={Id_CLIENT}
        Name_sous_client={Name_sous_client}
        NameCtt={NameCtt}
        NAME_Filer={NAME_Filer}
        Id_SOUS_CLIENT = {Id_SOUS_CLIENT}
      />

      <Container>
        <Row>
          <div>
            <Row>
              <Col>
                <h1 style={{margin: "30px"}}>Accueil</h1>
              </Col>
              <Col md={{span: 3, offset: 3}}>
                <Button
                  style={{margin: "30px"}}
                  onClick={goHome}
                  className="btn-info"
                >
                  Retour Menu Principal
                </Button>
              </Col>
            </Row>

            <div>
              <Row>
                <Col>
                  <p
                    style={{
                      margin: "10px",
                      "font-size": "20px",
                      "font-family": "georgia",
                    }}
                  >
                    {" "}
                    Nom de client : {formData.NameCt}{" "}
                  </p>
                </Col>
                <Col>
                  <p
                    style={{
                      margin: "10px",
                      "font-size": "20px",
                      "font-family": "georgia",
                    }}
                  >
                    Numéro du compte : {formData.NumCt}
                  </p>
                </Col>
              </Row>
              <Row>
                <p
                  style={{
                    "margin-left": "20px",
                    "font-size": "15px",
                    "font-family": "georgia",
                  }}
                >
                  <Link
                    exact
                    to={{
                      pathname: "/Admin/accuile",
                      state: {
                        Id_CLIENT: Id_CLIENT,
                      },
                    }}
                  >
                    {NameCtt}
                  </Link>

                  {">"}
                  <Link
                    exact
                    to={{
                      pathname: "/Admin/GroupSousClient",
                      state: {
                        Id_CLIENT: Id_CLIENT,
                        Id_SOUS_CLIENT: Id_SOUS_CLIENT,
                        NAME_SOUSCLIENT: Name_sous_client,
                        NAmeClient: NameCtt,
                      },
                    }}
                  >
                    {Name_sous_client}
                  </Link>

                  {">"}
                  <Link
                    to={{
                      pathname: "/Admin/Filiere",
                      state: {
                        Id_Filer: Id_Filer,
                        Id_CLIENT: Id_CLIENT,
                        NameCtt: NameCtt,
                        NAME_Filer: NAME_Filer,
                        Name_sous_client: Name_sous_client,
                      },
                    }}
                  >
                    {NAME_Filer}
                  </Link>
                </p>
              </Row>
              <Row>
                <Col md={{span: 3}}>
                  <p
                    style={{
                      margin: "10px",

                      "font-size": "20px",
                      "font-family": "georgia",
                    }}
                  >
                    Nombre de visites : {statistics.xr}
                  </p>
                </Col>
              </Row>

              <Row>
                <Table striped>
                  <tbody>
                    <td>SIN : {statistics.xr} </td>
                    <td>VR : {statistics.risque}</td>
                    <td>VP : {statistics.vp}</td>
                    <td>EP : {statistics.ep} </td>
                  </tbody>
                </Table>
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Accueil_Filiere;
