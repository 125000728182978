import axios from "axios";

export const JouterClient = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post("/api/Clients/JouterClient", data, config);
  return response;
};

export const ListeScoietes = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post("/api/Clients/ListeScoietes", data, config);
  return response;
};

export const readOneClient = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post("/api/Clients/readOneClient", data, config);
  return response;
};

export const readOneFiler = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post("/api/Clients/readOneFiler", data, config);
  return response;
};

export const AjouterSimstre = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    "/api/Clients/AjouterSimstre",
    data,
    config
  );
  return response;
};

export const Ajouter_Visite_de_Risque = async (data) => {
  const response = await axios.post(
    "/api/Clients/Ajouter_Visite_de_Risque",
    data
  );
  return response;
};

export const Ajouter_Visite_de_peretion = async (data) => {
  const response = await axios.post(
    "/api/Clients/Ajouter_Visite_de_peretion",
    data
  );
  return response;
};

export const AjouterRapSimstre = async (data) => {
  const response = await axios.post("/api/Clients/AjouterRapSimstre", data);
  return response;
};


export const AjouterRapsata = async (data) => {
  const response = await axios.post("/api/Clients/AjouterRapsata", data);
  return response;
};


export const AjouterRapdefinitif = async (data) => {
  const response = await axios.post("/api/Clients/AjouterRapdefinitif", data);
  return response;
};

export const AjouterRap_viste_risque = async (data) => {
  const response = await axios.post(
    "/api/Clients/AjouterRap_viste_risque",
    data
  );
  return response;
};

export const Ajouterrapp_visite_prevention = async (data) => {
  const response = await axios.post(
    "/api/Clients/Ajouterrapp_visite_prevention",
    data
  );
  return response;
};

export const Ajouterrapp_risk_survey = async (data) => {
  const response = await axios.post(
    "/api/Clients/Ajouterrapp_risk_survey",
    data
  );
  return response;
};
export const Ajouterrapp_Estimaions_prealable = async (data) => {
  const response = await axios.post(
    "/api/Clients/Ajouterrapp_Estimaions_prealable",
    data
  );
  return response;
};

export const Table_Expert_Simister = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    "/api/Clients/Table_Expert_Simister",
    data,
    config
  );
  return response;
};

export const Table_Expert_Simister_exacte = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };



  const response = await axios.post(
    "/api/Clients/Table_Expert_Simister_exacte",
    data,
    config
  );
  return response;
};



export const Table_Risque_exacte = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };



  const response = await axios.post(
    "/api/Clients/Table_Risque_exacte",
    data,
    config
  );
  return response;
};


export const Table_Visite_peretion_exacte = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };



  const response = await axios.post(
    "/api/Clients/Table_Visite_peretion_exacte",
    data,
    config
  );
  return response;
};



export const Table_Estimations_prelible_exacte = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };



  const response = await axios.post(
    "/api/Clients/Table_Estimations_prelible_exacte",
    data,
    config
  );
  return response;
};

export const Table_Visite_peretion = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    "/api/Clients/Table_Visite_peretion",
    data,
    config
  );
  return response;
};

export const Table_Risque = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post("/api/Clients/Table_Risque", data, config);
  return response;
};

export const Table_Estimations_prelible = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    "/api/Clients/Table_Estimations_prelible",
    data,
    config
  );
  return response;
};

export const Table_risque_survey = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    "/api/Clients/Table_risque_survey",
    data,
    config
  );
  return response;
};

export const readOnesiniter = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    "/api/Clients/readOnesiniter",
    data,
    config
  );
  return response;
};

export const Ajouter_Risquesurvey = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    "/api/Clients/Ajouter_Risquesurvey",
    data,
    config
  );
  return response;
};

export const Ajouter_Estimations_prelible = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    "/api/Clients/Ajouter_Estimations_prelible",
    data,
    config
  );
  return response;
};

export const ClientList = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post("/api/Clients/ClientList", data, config);
  return response;
};

export const filer_client = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post("/api/Clients/filer_client", data, config);
  return response;
};

export const Ajouter_filer_Client = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    "/api/Clients/Ajouter_filer_Client",
    data,
    config
  );
  return response;
};

export const StatRap = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post("/api/Clients/StatRap", data, config);
  return response;
};

export const AjouterStat = async (data) => {
  const response = await axios.post("/api/Clients/AjouterStat", data);
  return response;
};

export const AjouterSousClient = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    "/api/Clients/AjouterSousClient",
    data,
    config
  );
  return response;
};

export const suprimeclient = async (data) => {
  const response = await axios.post("/api/Clients/suprimeclient", data);
  return response;
};

export const suprimesuousclient = async (data) => {
  const response = await axios.post("/api/Clients/suprimesuousclient", data);
  return response;
};

export const suprimeFilerClient = async (data) => {
  const response = await axios.post("/api/Clients/suprimeFilerClient", data);
  return response;
};

export const Updateclient = async (data) => {
     
  const response = await axios.post('/api/Clients/Updateclient',data);
  return response ;
};


export const UpdateSousclient = async (data) => {
     
  const response = await axios.post('/api/Clients/UpdateSousclient',data);
  return response ;
};


export const UpdateSousclientfiler = async (data) => {
     
  const response = await axios.post('/api/Clients/UpdateSousclientfiler',data);
  return response ;
};





export const contact = async (data) => {
     
  const response = await axios.post('/api/contact/',data);
  return response ;
};



export const contactOffre = async (data) => {
     
  const response = await axios.post('/api/contact/contactOffre',data);
  return response ;
};