import React, {useState, useEffect} from "react";
import {Container, InputGroup, Form} from "react-bootstrap";

import isEmpty from "validator/lib/isEmpty";
import {UpdateSousclientfiler} from "../../../../../api/Clinets";
import AlertDanger from "../../../../Compusnte/Alert/AlertDanger";

const UpdatesousclientFilier  = (props) => {
  const Id = localStorage.getItem("ID_Sous_Client_update");
  const NameC = localStorage.getItem("Name_Sous_Client_update");
  const [formdata, setformdata] = useState({
     _id :  Id ,
    NameCt: NameC ,
    errorMSg: "",
  }); 
  const { _id, NameCt , errorMSg} = formdata;

  
  const handleChange = (evt) => {
    setformdata({
      ...formdata,

      [evt.target.name]: evt.target.value,
      errorMSg: "",
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (
      isEmpty(NameCt) 
    ) {
      setformdata({
        ...formdata,
        errorMSg: "Tous les champs sont requis",
      }); 
    } else {
     UpdateSousclientfiler(formdata)
        .then((response) => {
          alert("Toutes les modifications sont enregistrées ");
          window.location.reload(false);
        })
        .catch((err, response) => {
        
          setformdata({
            ...formdata,
            errorMSg: err.response.data.errorMessage,
          });
        });
    }
  };

  return (
    <div>
      <Container>
        <Container>
          <div className="frome">
            <Form onSubmit={handleSubmit}>
              <div className="cont"></div>
              {AlertDanger(errorMSg)}
              <Form.Group>
                <Form.Label>Mote de pass</Form.Label>
                <InputGroup>
                  <Form.Control
                    className="inputtest1"
                    type="text"
                    placeholder="Entrer Numéro de téléphone"
                    name="NameCt"
                    onChange={handleChange}
                    value={NameCt}
                  />
                </InputGroup>
              </Form.Group>
              <button type="submit" class="butnn">
                <div>Save </div>
              </button>
            </Form>
          </div>
        </Container>
      </Container>
    </div>
  );
};
export default UpdatesousclientFilier ;
