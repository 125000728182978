import { InputGroup, Container, Form, Row, Col } from "react-bootstrap";
import "./css/style.css";
import Navbarstnd from "./Navbar/Navbarstnd";
import "./style2.scss";
import { FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import NavbarContact from "./Navbar/NavbarContact";
import Footter from "./Footer/Footer1";
import emailjs from "emailjs-com";
import React, { useState } from "react";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import AlertDanger from "./Compusnte/Alert/AlertDanger.js";
import AlertSuss from "./Compusnte/Alert/AlertSucc";
import { GiSmartphone } from "react-icons/gi";
import { contact } from "../api/Clinets";

const Contact = () => {
  // configertion for emls

  const [formdata, setformdata] = useState({
    Nom: "",
    Email: "",
    tel: "",
    MESG: "",
    errorMSg: "",
    sussMsg: "",
  });

  const { Nom, Email, tel, MESG, errorMSg, sussMsg } = formdata;

  const handleChange = (evt) => {
    setformdata({
      ...formdata,
      [evt.target.name]: evt.target.value,
      errorMSg: "",
    });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (isEmpty(Nom) || isEmpty(Email) || isEmpty(tel) || isEmpty(MESG)) {
      setformdata({
        ...formdata,
        errorMSg: "Tous les champs sont requis",
      });
      console.log(formdata.errorMSg);
    } else if (!isEmail(Email)) {
      setformdata({
        ...formdata,
        errorMSg: "Email invalide",
      });
      console.log(formdata.errorMSg);
    } else {
      console.log(formdata);

      const { Nom, Email, tel, MESG } = formdata;
      const data = { Nom, Email, tel, MESG };

      await contact(formdata);
    }

    setformdata({
      ...formdata,
      sussMsg: "Merci d'utiliser ce formulaire. Réponse rapide garantie !",
    });
  };

  return (
    <div>
      <div className="backgroundContact">
        <div className="backr">
          <div className="backr">
            <Navbarstnd />

            <NavbarContact />
          </div>

          <div className="from">
            <Container>
              <div className="cont">
                <h1>Contact</h1>
              </div>
              <div className="frome">
                <Row>
                  <Col>
                    <Form onSubmit={handleSubmit}>
                      <div className="cont">
                        <h2>Besoin de renseignement ?</h2>
                        <h3>Contactez-nous.</h3>
                      </div>

                      <Form.Group>
                        {AlertDanger(errorMSg)}
                        {AlertSuss(sussMsg)}

                        <Form.Label>Nom *</Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="inputtest1"
                            type="text"
                            placeholder="Enter Name"
                            name="Nom"
                            onChange={handleChange}
                            value={Nom}
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Address email</Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="inputtest1"
                            type="email"
                            placeholder="Enter email"
                            name="Email"
                            onChange={handleChange}
                            value={Email}
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Numéro de téléphone</Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="inputtest1"
                            type="text"
                            placeholder="Enter Numéro de téléphone"
                            name="tel"
                            onChange={handleChange}
                            value={tel}
                            maxLength="14"
                            minLength={8}
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Message</Form.Label>
                        <InputGroup>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="MESG"
                            onChange={handleChange}
                            value={MESG}
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="mb-3" id="formGridCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="Oui je donne mon consentement*"
                          required
                        />
                      </Form.Group>
                      <p className="pital">
                        *En soumettant ce formulaire, j'accepte que les
                        informations saisies dans ce formulaire soient utilisées
                        et traitées pour permettre de me contacter, dans le
                        cadre de ma demande d'informations, que ce soit par mail
                        ou par téléphone. Pour connaître et exercer vos droits,
                        notamment de retrait, de consentement à l'utilisation de
                        données collectées par ce formulaire, veuillez consulter
                        notre politique de confidentialité.
                      </p>

                      <button type="submit" class="butnn">
                        <div>Envoyer </div>
                      </button>
                    </Form>
                  </Col>

                  <Col md={4}>
                    <div className="cont pt2">
                      <h2>Nos coordonnées</h2>
                      <h3>Pour toute demande d’information</h3>
                      <Row>
                        <p>
                          {" "}
                          <GiSmartphone className="icons" /> +216 95 999 644{" "}
                        </p>
                      </Row>
                      <Row>
                        <p>
                          {" "}
                          <FaPhoneAlt className="icons" /> +216 70 038 084{" "}
                        </p>
                      </Row>
                      <Row>
                        <p>
                          {" "}
                          <IoMail className="icons" /> contact@uesas.com.tn
                        </p>
                      </Row>
                      <Row>
                        <p>
                          <FaMapMarkerAlt className="icons" />
                          Imm Centre de vie, 1er étage bureau B1-4, Zone
                          industrielle lac 3
                        </p>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <div>
        <Footter />
      </div>
    </div>
  );
};

export default Contact;
