import React, {  useState } from 'react' ;
import { Container, Form,  Row, Col, Button } from 'react-bootstrap';
import { Ajouter_Visite_de_Risque } from '../../../../../../../api/Clinets';
 
function Ajouter_VISITE_DE_RISQUE_Formulair (props) {


  const num = props.numCt ; 
  
  
  const [formSmister , setSmister]  = useState ({
 
    DateSim	: "",
    NumCont: "",
    ID_Filer_Client :  num ,
    rapp : null ,
   
}) ;

const {
 
  DateSim	 ,
  NumCont ,
  rapp ,
} = formSmister; 

  const handleChange = (evt) => {
      
    setSmister({
      ...formSmister,
      
      [evt.target.name] : evt.target.value,

      errorMSg :'',
    });
 };

 const handleChangeFiles = (evt) => {
      console.log( evt.target.files[0]);
  setSmister({
    ...formSmister,
    [evt.target.name] : evt.target.files[0],
  });
};

     
 const handleSubmit = (evt) => {
      
  evt.preventDefault();
  let formData = new FormData();
    
  formData.append('DateSim', DateSim);
  formData.append('NumCont', NumCont);
  formData.append('ID_Filer_Client',  num );
  formData.append('rapp', rapp); 
  console.log(formData);
  
  Ajouter_Visite_de_Risque(formData)

 .then(() => {
    alert('Vous avez ajout un article ');
     //window.location = '/HomeAdmin' ; 
  
  })
  .catch ((err) =>{
    console.log('login error est',err);
    setSmister({
      ...formSmister, errorMSg : err.response.data.errorMessage


   });
  });



}


  return (
 
        <div>

        <Container fluid>


        <div  style={{"background-color": "#f5f5f5" , "padding" : "20px " , 'border-radius': '30px'}}>
          <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Date</Form.Label>
              <Form.Control type="date"
                            placeholder="Password"
                            name='DateSim' 
                            onChange={handleChange}
                            value = {DateSim} />
            </Form.Group>
          </Row>
      
                <Form.Group className="mb-3" controlId="formGridAddress1">
                  <Form.Label>Numéro contrat	</Form.Label>
                  <Form.Control 
                                name='NumCont' 
                                onChange={handleChange}
                                value = {NumCont} />
                </Form.Group>
        
                <Row className="mb-3">
              
          <Form.Group  as={Col}  className='custom-file' controlId="formGridName ">
              
          <Form.Label className='custom-file-label'>Ajouter un rapport ... </Form.Label>
          <Form.Control  
          className='custom-file-input'
          type ='file'
          name="rapp"
          onChange={handleChangeFiles}

          /> 
  </Form.Group>

          
         
        

         </Row>

          <Button variant="primary" type="submit">
          Envoyer
          </Button>
          </Form>
          </div>
            
    </Container>
       
        </div>
     
     
        );
      } 
    
export default Ajouter_VISITE_DE_RISQUE_Formulair ;