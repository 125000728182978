import React, {useState, useEffect} from "react";
import {Container, Button, Card, Row, Col} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {
  readOneClient,
  Table_Expert_Simister_exacte,
  filer_client,
  ClientList,
} from "../../../../../../api/Clinets";

const ItemListtoutl2 = (props) => {
  const location = useLocation();

  const [statistics, setStatistics] = useState({
    some: "",
  });

  const [datafil, setdatafil] = useState({
    Name_SousClient: "",
    Name_client: "",
    Name_filer: "",
    _id: "",
  });

  const fetchData = async (id) => {
    const response2 = await Table_Expert_Simister_exacte({iduser: id});
    return response2;
  };

  let sim = 0;
  useEffect(async () => {
    let some = 0;

    await fetchData(props.Id_CLIENT).then((response) => {
      response.data.simster.forEach((e) => {
        e.rapp_definitif.forEach((d) => {
          console.log("results resultsresults", d.montant_definitif);
          some = some + parseInt(d.montant_definitif);
        });
      });
    });
    ClientList().then((response) => {
      const promises = response.data.sousClient
        .filter((itemx) => itemx.numClt === props.Id_CLIENT)
        .map(async (itemx) => {
          await fetchData(itemx._id).then((response) => {
            response.data.simster.forEach((e) => {
              e.rapp_definitif.forEach((d) => {
                some = some + parseInt(d.montant_definitif);
              });
            });
          });
          console.log("results  itemx.NameCt  ",  itemx.NameCt);

          filer_client().then(async (response) => {
            const data = response.data;
            const filerClientList = data.filerClient;
            console.log("filerClientList" , filerClientList);
            const promises = filerClientList
              .filter((itemy) => itemy.Name_SousClient=== itemx.NameCt)
              .map((itemy) => fetchData(itemy._id));
            const results = await Promise.all(promises);

            results.forEach((element) => {
              element.data.simster.forEach((e) => {
                e.rapp_definitif.forEach((d) => {
                  some = some + parseInt(d.montant_definitif);
                });
              });
            });
            console.log("results results", some);

            console.log("results resultsresult   s", some);

            setStatistics({
              some: some,
            });
          });
        });
    });
  }, []);

  return (
    <div>
      <Card
        bg={"info"}
        text={"light"}
        style={{width: "18rem"}}
        className="mb-2"
      >
        <Card.Body style={{"text-align": "center"}}>
          <Card.Title> Montant total des Rapports définitifs </Card.Title>
          <Card.Text style={{fontSize: "50px"}}>
            {" "}
            {statistics.some.toLocaleString("fr-FR")} DT
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ItemListtoutl2;
