import React from 'react'; 
import { StatRap   } from '../../../../../../../api/Clinets';   
import { Container, Button } from 'react-bootstrap';

class Rp extends React.Component  {
 

    state = { 
       numClt  : '',stat: "",posts: []
      };
    
      componentDidMount = () => {
        this.getBlogPost();
      };
    
      

  
      
      getBlogPost = () => {

        StatRap()

          .then((response) => {
            console.log("ok")
            const data = response.data
            console.log(data)

            this.setState({ posts: data.statisque });
          })
          .catch(() => {
            alert('Error retrieving data!!!');
          });
      }
     
    
     
     
 
    
      
    
      render() {
        const  num = this.props.numCt ;
        let pdf ;
          return(
          <div >
          <div>
          <Container>
          <div className="d-grid gap-2">
         
          {this.state.posts.map((post) => {
            if ( post.numClt === num ) {
              return (
                <Button variant="primary" size="lg">
                
                <a style={{"color" : "#fff"}}  href={pdf}> Voir les statistiques </a>
                
                </Button>

              );
           
            }
            return true ;
          }
          )} 
           
          </div>
          </Container>
          </div>
                           
                
                      

                      
                    
                   
       
           
                
                </div>
               
                

        );
      }
    }
    
export default Rp ;