import React from 'react'
import './../../css/style.css' ;  
import "./../../style2.scss" ; 

const Card2 = ()=> { 
    
      
    return ( 
 
     <div>
   



<div className="testimotionals">
  <div className="card">
    <div className="layer">
      
    </div>
    <div className="content">
     
     
      <div className="details">
        <h2> APCI : audit prévention et conseil incendie</h2>
        <a href="APCI" class="nav-link"> <button className='ligthbtn'>Découvrir</button> </a>

      </div>
    </div>
  </div>
</div>



 
</div>
 
      );
    } 

export default  Card2 ;