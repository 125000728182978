import React from 'react';
import './../../css/style.css' ; 
 
import "./../../style2.scss" ;
import { Container, Row, Col } from 'react-bootstrap';
import logov from './../../../img/logov.png' ;
import PREVEX_V from './../../../videos/PREVEX_03_V05_MIX.mp4' ;
 

const Partenaires = ()=> { 
    
      
    return ( 
 
     <div>
   

     <div className="secandparts">
    
    <div className='secands'>
    <div className="partenair">
    <Container> 
    <hr />
    <div style={{'margin-top': '60px'}}></div>

      <h1  style={{'font-size':'30px'}}>Nos partenaires   </h1> 
      <div style={{'margin-top': '40px'}}></div>

      <Row>
      <Col>
      <img  alt="" className = 'PREVEX' src={logov} />

    
<p>PREV-EX AFRICA représente le cabinet international Union d’experts.</p>
</Col>
<Col>
<div className='video'>
<video  
    src={PREVEX_V} autoPlay muted 
    width="500" 
    shouldPlay height="300" controls="controls" 
    
    />
                   
            </div>
</Col>
</Row>
<div style={{'margin-top': '40px'}}></div>

</Container>
    </div>
          </div>
    </div>

 
    </div>
 
      );
    } 

export default  Partenaires  ;