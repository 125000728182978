import React   from 'react';

  
  function AlertSuss (text) { 
    if (!(text)  ){
  
    return <div> 
    
  </div>
  }else{
    return <div> 
    <div className="alert alert-success" role="alert" style={{textAlign : 'center'}}>
  {text}
  </div>
  </div>
  
  }}  
  




export default  AlertSuss  ;