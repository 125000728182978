import React, {useState, useEffect} from "react";
import {Container, Button, Card, Row, Col} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {
  readOneClient,
  Table_Expert_Simister_exacte,
  filer_client,
} from "../../../../../../../api/Clinets";

const ItemListtoutl3 = (props) => {
  const location = useLocation();

  const [statistics, setStatistics] = useState({
    some: "",
  });

  const [datafil, setdatafil] = useState({
    Name_SousClient: "",
    Name_client: "",
    Name_filer: "",
    _id: "",
  });

  const fetchData = async (id) => {
    const response2 = await Table_Expert_Simister_exacte({iduser: id});
    return response2;
  };

  let sim = 0;
  useEffect(async () => {
    filer_client()
      .then(async (response) => {
        const data = response.data;
        const filerClientList = data.filerClient;
        const promises = filerClientList
          .filter((item) => item.Name_SousClient === props.NAME_SOUSCLIENT)
          .map((item) => fetchData(item._id));
        const results = await Promise.all(promises);

        let some = 0;
        results.forEach((element) => {
          element.data.simster.forEach((e) => {
              some = some + 1 ;
          });
        });
        console.log("results results", some);

      await  fetchData(props.idcl).then((response) => {
          response.data.simster.forEach((e) => {

              some = some + 1 ;
          });
        });

        console.log("results resultsresult   s", some);

          setStatistics({
            some: some
        });
 
      })

      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  return (
    <div>

      <Card
      bg={"info"}
      text={"light"}
      style={{width: "18rem"}}
      className="mb-2"
    >
      <Card.Body style={{"text-align": "center"}}>
        <Card.Title>    Nombre de Sinistres</Card.Title>
        <Card.Text style={{fontSize: "50px"}}> {statistics.some.toLocaleString('fr-FR')} </Card.Text>
      </Card.Body>
    </Card>
    </div>
  );
};

export default ItemListtoutl3;
