import React, {useState, useEffect} from "react";
import {Container, Button, Table, Row, Col} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {
  readOneClient,
  Table_Expert_Simister_exacte,
  Table_Risque_exacte,
  Table_Visite_peretion_exacte,
  filer_client,
  Table_Estimations_prelible_exacte,
} from "../../../../../api/Clinets";

const ItemListtoutl = (props) => {
  const location = useLocation();

  const [statistics, setStatistics] = useState({
    xr: "",
    risque: "",
    vp: "",
    ep: "",
  });

  const [datafil, setdatafil] = useState({
    Name_SousClient: "",
    Name_client: "",
    Name_filer: "",
    _id: "",
  });
  const {Name_SousClient, Name_client, Name_filer, _id} = datafil;

  const {
    xr: expertSimister,
    risque: risk,
    vp: visitPeretion,
    ep: estimationsPrelible,
  } = statistics;

  const fetchData = async (id) => {
    const response2 = await Table_Expert_Simister_exacte({iduser: id});
    const expertSimisterCount = response2.data.simster.length;

    const response3 = await Table_Risque_exacte({iduser: id});
    const riskCount = response3.data.risque.length;

    const response4 = await Table_Visite_peretion_exacte({iduser: id});
    const visitPeretionCount = response4.data.visite_de_peretion.length;

    const response5 = await Table_Estimations_prelible_exacte({iduser: id});
    const estimationsPrelibleCount = response5.data.estimations_prelible.length;

    return [
      expertSimisterCount,
      riskCount,
      visitPeretionCount,
      estimationsPrelibleCount,
    ];
  };

  let sim = 0;
  let vr = 0;
  let vp = 0;
  let ep = 0;
  useEffect(() => {
    filer_client()
      .then(async (response) => {
        const data = response.data;
        const filerClientList = data.filerClient;
        const promises = filerClientList
          .filter((item) => item.Name_SousClient === props.NAME_SOUSCLIENT)
          .map((item) => fetchData(item._id));
        const results = await Promise.all(promises);
        results.forEach((countValues) => {
          sim += countValues[0];
          vr += countValues[1];
          vp += countValues[2];
          ep += countValues[3];
        }); 
        setStatistics({
          xr: sim,
          risque: vr,
          vp: vp,
          ep: ep,
        });
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });

    // fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Table striped>
      <tbody>
      <tr>
        <td> Récap des filières  </td>
        <td></td>
        <td></td>
        <td></td>
        <td>SIN : {statistics.xr} </td>
        <td>VR : {statistics.risque}</td>
        <td>VP : {statistics.vp}</td>
        <td>EP : {statistics.ep} </td>
      </tr>

        </tbody>
      </Table>
    </div>
  );
};

export default ItemListtoutl;
