import React, {useState, useEffect} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";

import {useLocation} from "react-router-dom";
import {readOneClient, readOneFiler} from "../../../../../../../api/Clinets";
import AjouterVisitedeperetionFormulaie from "./Ajouter_Visite_de_peretion";
import TableVisitedeperetion from "./table_Visite_de_peretion";
import NavbarClientleve2 from "../../NavbarClientleve2";
const Visiteperetion3 = () => {
  const location = useLocation();

  console.log("id: ", location.state);

  
  const Id_CLIENT = location.state.Id_CLIENT;
  const NAME = location.state.NAME_SOUSCLIENT;
  const NAmeClient = location.state.NAmeClient;
  const Id_SOUS_CLIENT = location.state.Id_SOUS_CLIENT;

  const [formdata2, setformdata2] = useState({
    _id: Id_CLIENT,
    EmailCt: "",
    NameCt: "",
    NumCt: "",
    Pass: "",
    TelCt: "",
  });

  useEffect(() => {
    readOneClient(formdata2).then((response) => {
      //  setformdata(response.data);
      console.log("hi2", response.data);
      setformdata2(response.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <NavbarClientleve2
        Id_SOUS_CLIENT={Id_SOUS_CLIENT}
        NAME_SOUSCLIENT={NAME}
        NAmeClient={NAmeClient}
        Id_CLIENT={Id_CLIENT}
      />
      <Container fluid>
        <div>
          <h1 style={{margin: "30px"}}>Visite de prévention </h1>

          <div>
            <Row>
              <Col>
              <Row>
              <p
                style={{
                  "margin-left": "20px",
                  "font-size": "15px",
                  "font-family": "georgia",
                }}
              >
                <Link
                  exact
                  to={{
                    pathname: "/Admin/accuile",
                    state: {
                      Id_CLIENT: Id_CLIENT,
                    },
                  }}
                >
                  {formdata2.NameCt}
                </Link>
                {">"}
                <Link
                  exact
                  to={{
                    pathname: "/Admin/GroupSousClient",
                    state: {
                      Id_CLIENT: Id_CLIENT,
                      Id_SOUS_CLIENT: Id_SOUS_CLIENT,
                      NAME_SOUSCLIENT: NAME,
                      NAmeClient: NAmeClient,
                    },
                  }}
                >
                  {NAME}
                </Link>
              </p>
            </Row>
              </Col>
              <Col>
                <p
                  style={{
                    margin: "10px",
                    "font-size": "20px",
                    "font-family": "georgia",
                  }}
                >
                  Numéro du compte : {formdata2.NumCt}
                </p>
              </Col>
            </Row>

            <Row>
              <Col style={{margin: "20px", "font-size": "15px"}}>
                <TableVisitedeperetion numCt={Id_SOUS_CLIENT}>
                  {" "}
                </TableVisitedeperetion>
              </Col>
            </Row>

            <Row>
              <Row>
                <div>
                  <AjouterVisitedeperetionFormulaie numCt={Id_SOUS_CLIENT}>
                    {" "}
                  </AjouterVisitedeperetionFormulaie>
                </div>
              </Row>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Visiteperetion3;
