import React, {useEffect, useState} from "react";
import "./style_client.css";
import {Container, Row, Col} from "react-bootstrap";
import {isAuthenticated} from "../../helpers/auth";
import ListClient from "./Details/ListClient";
import Saidbar_disable from "./Saidbar_disable";

const DashBord = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("user"));
    if (items) {
      setItems(items);
    }
  }, []);

  return (
    <div>
      {(() => {
        if (isAuthenticated()) {
          return (
            <div>
              <Saidbar_disable Id_CLIENT={items._id} />
              <Container>
                <Row>
                  <Col xs={10} id="page-content-wrapper">
                    <div>
                      <h1 style={{marginTop: "30px"}}>Accueil</h1>
                      <div>
                        <Row>
                          <Col>
                            <p
                              style={{
                                marginTop: "10px",
                                "font-size": "20px",
                                "font-family": "georgia",
                              }}
                            >
                              {" "}
                              Nom : {items.NameCt}{" "}
                            </p>
                          </Col>
                          <Col md={{span: 3, offset: 3}}>
                            <p
                              style={{
                                margin: "10px",
                                "font-size": "20px",
                                "font-family": "georgia",
                              }}
                            >
                              Numéro du compte : {items.NumCt}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <p>vous trouverez ici la liste des sous-clients</p>
                        </Row>

                        <Row>
                          <Col style={{margin: "20px", "font-size": "15px"}}>
                            <ListClient
                              Name_Client={items.NameCt}
                              numCt={items._id}
                              Id={items._id}
                            >
                              {" "}
                            </ListClient>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          );
        } else {
          window.location = "/connexion";
        }

        return null;
      })()}
    </div>
  );
};

export default DashBord;
