import NavbarAdmin from '../../Navbar/NavbarAdmin';
import { Container, Row, Button , Col } from 'react-bootstrap'; 
import {React ,useState ,useEffect }  from 'react';  
import { readOne ,Supprimer } from './../../../api/auth'; 
import Card from 'react-bootstrap/Card';
import {Link, useLocation} from "react-router-dom";
import { isAuthenticated} from '../../../helpers/auth';

const SupprimeArticle = ()=> { 
    
  const location = useLocation();
   
  console.log(location.state);
  const Id = location.state; 

    const [formdata , setformdata]  = useState ({

        _id : Id , titre :'' ,text :'' ,source :'' ,image: null ,video: null ,linke:'' ,createdAt:'',
      
        }) ;

        const {
            titre  ,text  ,createdAt,
           } = formdata; 
             
               useEffect(() => {
          
                 readOne(formdata)
             
               .then( (response ) => {
               //  setformdata(response.data);
                 setformdata(response.data);

            
            // eslint-disable-next-line react-hooks/exhaustive-deps
           }) }, [])


           
        const  SupprimerArticle = () => {
            Supprimer(formdata)
                
            .then(() => {
              alert("vous avez supprimé l'article ");
              window.location = '/HomeAdmin' ; 
        
         });
              
              }
    return ( 
 
       <div>
       {(() => {
        if(isAuthenticated())
        {
            return (
              <div>
       <NavbarAdmin /> 
       
       <Container>
      
        <div style={{'marginTop':'30px'}}>
        <Card style={{'padding': '0'}}>
        <Card.Header>Etes-vous sûr de vouloir supprimer ce blog</Card.Header>
        <Card.Body>
        <Card.Title>{titre}</Card.Title>
        <Card.Text>
        {text.length  >600 ? text.substring(0 , 500) +'...' : text.substring(0, 20) }
        </Card.Text>
        <Row>
        <Col>
        <Link    to={{
           pathname: "/HomeAdmin",
           }} >
         <Button variant="outline-secondary">Annuler</Button>
        </Link>
        </Col>
        <Col  md={{ span: 3, offset: 3 }}>
        <Button variant="outline-danger" onClick ={()=> {SupprimerArticle()}} >Supprimer</Button>
       </Col>
        </Row>
        </Card.Body>
        <Card.Footer className="text-muted">{createdAt.substring(0,10)}</Card.Footer>
        </Card>
        </div>
        
       </Container>
       </div>
       )
     }else{ window.location = '/loginAdmin'}
     
     return null;
   })()}
       </div>
 
      );
    } 

export default  SupprimeArticle  ; 