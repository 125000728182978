import logo from "./../../img/PREV-EX AFRICA.png";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./../css/style.css";
import { GrMail } from "react-icons/gr";
import { FaPhoneAlt } from "react-icons/fa";
import { GiSmartphone } from "react-icons/gi";
class Navbarstnd extends React.Component {
  render() {
    return (
      <div className="navbarst">
        <Container>
          <Row>
            <Col sm={12} lg={4} className="d-none d-lg-block">
              <div
                style={{
                  "margin-top": "20px",
                  textAlign: "left",
                  fontSize: "18px",
                }}
              >
                <GrMail /> contact@uesas.com.tn
              </div>
            </Col>

            <Col>
              <img src={logo} width="70%" alt="Example2" />
            </Col>
            <Col sm={12} lg={4} className="d-none d-lg-block">
              <div
                style={{
                  "margin-top": "20px",
                  textAlign: "right",
                  fontSize: "18px",
                }}
              >
                <p>
                  {" "}
                  <FaPhoneAlt /> :70 038 084 /{" "}
                  <GiSmartphone style={{ marginLeft: "10px" }} /> : +216 95 999
                  644{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Navbarstnd;
