import { Container, Row, Form, Button } from 'react-bootstrap'; 
import React , {useState} from 'react';
import './../../css/style.css' ; 
import   isEmpty  from 'validator/lib/isEmpty'; 
import NavbarAdmin from '../../Navbar/NavbarAdmin';
import { AddArticles } from '../../../api/auth';
import { isAuthenticated} from '../../../helpers/auth';
import AlertDanger from './../../Compusnte/Alert/AlertDanger';


const AddBlog = ()=> { 
    

    const [formdata , setformdata]  = useState ({

        titre :'' ,text :'' ,source :'' ,image: null ,video: null ,linke:'' ,heading:'',errorMSg :'',
    
    
      }) ;
    
    
    
      const {
        titre  ,text  ,source  ,image ,video ,linke ,heading,errorMSg ,
      } = formdata; 
        
      const handleChangeFiles = (evt) => {
      
        setformdata({
          ...formdata,
          [evt.target.name] : evt.target.files[0],
        });
     };

     
      const handleChange = (evt) => {
      
        setformdata({
          ...formdata,

          [evt.target.name] : evt.target.value,
          errorMSg :'',
        });

     };

     
    
     
     
    const handleSubmit = (evt) => {
      
        evt.preventDefault();
        if( isEmpty(titre) || isEmpty(text)   ) {
        
          setformdata({
              ...formdata, errorMSg : 'Tous les champs sont requis'
    
          });
          console.log(formdata.errorMSg);
    
    }else{

      let formData = new FormData();

      formData.append('titre', titre);
      formData.append('text', text);
      formData.append('source', source);
      formData.append('image', image);
      formData.append('video', video);
      formData.append('linke', linke);
      formData.append('heading', heading);
        
      
        AddArticles(formData)

        .then(response => {
          alert('Vous avez ajout un article ');
           window.location = '/HomeAdmin' ; 
        
        })
        .catch ((err,response) =>{
          console.log('login error est',err);
          setformdata({
            ...formdata, errorMSg : err.response.data.errorMessage
      
      
         });
        });
      


    }
     
  }
    return ( 
 
        <div>
        {(() => {
          if(isAuthenticated())
          {
              return (
                
                  <div>    <NavbarAdmin />
       


                  <div>
                  <Container>
                  <Row>
                  <div className="addblogF">
                  <h1  className="addblogFh1"> Ajouter un article</h1>
                  <Form  onSubmit={handleSubmit} >
                  {AlertDanger(errorMSg) }
                          
                  <Row className="mb-3">
                      <Form.Group 
                      className='.AddBlogForm  
                      mb-24 ' controlId="formGridNomsociete">
                          <Form.Label>Titre de l'article</Form.Label>
                          
                          <Form.Control  
                          
                              type ='text'
                              className='AddBlogForm'
                              name="titre"
                              onChange={handleChange}
                              value = {titre} />
                      </Form.Group>
                  </Row>  
                  
                  <Row className="mb-3">
                  <Form.Group 
                  className='.AddBlogForm  
                  mb-24 ' controlId="formGridNomsociete">
                      <Form.Label>Heading de l'article</Form.Label>
                      
                      <Form.Control  
                      
                          type ='text'
                          className='AddBlogForm'
                          name="heading"
                          onChange={handleChange}
                          value = {heading} />
                  </Form.Group>
              </Row>
                  
                
                  <Row className="mb-3">
                  <Form.Group  controlId="formGridName ">
                      <Form.Label>Le contenu </Form.Label>
      
                      <Form.Control  
                      type ='text'
                      name="text"
                      as="textarea"
                      className='AddBlogForm'
                      onChange={handleChange}
                      value = {text} /> 
                  </Form.Group>
              </Row>
      
              <Row className="mb-3">
              <Form.Group  controlId="formGridName ">
                  <Form.Label>Source </Form.Label>
      
                  <Form.Control  
                  type ='text'
                  name="source"
                  className='AddBlogForm'
                  onChange={handleChange}
                  value = {source} /> 
              </Form.Group>
          </Row>
        <Row className="mb-3">
                     
      
                      <Form.Group  className='custom-file' controlId="formGridName ">
                  <Form.Label className='custom-file-label'>Ajouter une video... </Form.Label>
                  <Form.Control  
                  className='custom-file-input'
                  type ='file'
                  name="video"
                  onChange={handleChangeFiles}
                   /> 
              </Form.Group>
                  </Row>
                  <Row className="mb-3">
                  
      
              <Form.Group  className='custom-file' controlId="formGridName ">
              
                  <Form.Label className='custom-file-label'>Ajouter une image... </Form.Label>
                  <Form.Control  
                  className='custom-file-input'
                  type ='file'
                  name="image"
                  onChange={handleChangeFiles}
                  /> 
          </Form.Group>
      
              </Row> 
      
               <Row className="mb-3">
              <Form.Group  controlId="formGridName ">
              <Form.Label>lien </Form.Label>
              <Form.Control  
              type ='linke'
              name="linke"
              onChange={handleChange}
              value = {linke} /> 
          </Form.Group>
          </Row>
                  <Button variant="primary" type="submit">
                      Ajouter
                  </Button>
              </Form>
                      
                 
              
              </div>
              </Row>
                  </Container>
                     </div>  </div>
                  )
              }else{ window.location = '/loginAdmin'}
              
              return null;
            })()}
     
        </div>
 
      );
    } 

export default  AddBlog  ;