import React  ,{useEffect} from 'react'; 
import { Container, Row, Button, Col } from 'react-bootstrap'; 
import { Helmet } from "react-helmet";
import NavbarAdmin from '../Navbar/NavbarAdmin';
import { isAuthenticated} from '../../helpers/auth';
import Card from 'react-bootstrap/Card'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
const HomeAdmin = ()=> { 
    


    useEffect(()=>{
  
  
        if (isAuthenticated() ) {


            
        }else{
            window.location = '/loginAdmin'

        }
      
      },[])
    

    return ( 
 

        
        <div>
       
          {(() => {
            if(isAuthenticated())
            {
                return (
                  
                    <div>
                    <Helmet>
                 <title>HomeAdmin</title>
        
             </Helmet>
             <NavbarAdmin /> 
            <div className="HomeAdmin"  >
            <Container>
     <Row  className="justify-content-md-center">
     <Col xs lg="4">  <Card border="primary" style={{ width: '18rem' }}>
     <Card.Body>
       <Card.Title>Gestion de blog </Card.Title>
       <Card.Text>
       <br/>
       <ButtonGroup aria-label="Basic example">
       <Button href='/HomeAdmin/AddBlog' variant="secondary">Ajouter</Button>
       <Button href='/HomeAdmin/ConsulterARTIClE' variant="secondary">Consulter </Button>
     </ButtonGroup>
       </Card.Text>
     </Card.Body>
   </Card>
   
   </Col>

     <Col  xs lg="4">
     <Card border="primary" style={{ width: '18rem' }}>
     <Card.Body>
       <Card.Title>Gestion de Client </Card.Title>
       <Card.Text>
       <br/>
       <ButtonGroup aria-label="Basic example">
       <Button href='/HomeAdmin/GestiondDeComptes' variant="secondary">Consulter</Button>
     </ButtonGroup>
       </Card.Text>
     </Card.Body>
   </Card>
</Col>
     </Row>
          

          





            
            
            </Container>
            </div>
                    </div>
                )
            }else{ window.location = '/loginAdmin'}
            
            return null;
          })()}
      
        </div>
 
      );
    } 

export default  HomeAdmin  ;