import React, {useState, useEffect} from "react";
import {Container, Table, Row, Col} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {
  readOneClient,
  Table_Expert_Simister_exacte,
  Table_Risque_exacte,
  Table_Visite_peretion_exacte,
  Table_Estimations_prelible_exacte,
} from "../../../../../api/Clinets";
import CollapsibleExample from "./NavbarClient";
import {Link} from "react-router-dom";

const Accueil_Filiere2 = () => {
  const location = useLocation();

  const [statistics, setStatistics] = useState({
    xr: "",
    risque: "",
    vp: "",
    ep: "",
  });

  const {
    xr: expertSimister,
    risque: risk,
    vp: visitPeretion,
    ep: estimationsPrelible,
  } = statistics;

  const {
    Id_Filer,
    Id_CLIENT,
    NameCtt,
    NAME_Filer,
    Name_sous_client,
    Id_SOUS_CLIENT,
  } = location.state;
  const [formData, setFormData] = useState({
    _id: Id_CLIENT,
    EmailCt: "",
    NameCt: "",
    NumCt: "",
    Pass: "",
    TelCt: "",
  });

  const [myIdUser, setMyIdUser] = useState({iduser: Id_Filer});

  const fetchData = async () => {
    const response1 = await readOneClient(formData);
    setFormData(response1.data);

    const response2 = await Table_Expert_Simister_exacte(myIdUser);
    const expertSimisterCount = response2.data.simster.length;

    const response3 = await Table_Risque_exacte(myIdUser);
    const riskCount = response3.data.risque.length;

    const response4 = await Table_Visite_peretion_exacte(myIdUser);
    const visitPeretionCount = response4.data.visite_de_peretion.length;

    const response5 = await Table_Estimations_prelible_exacte(myIdUser);
    const estimationsPrelibleCount = response5.data.estimations_prelible.length;

    setStatistics({
      ...statistics,
      xr: expertSimisterCount,
      risque: riskCount,
      vp: visitPeretionCount,
      ep: estimationsPrelibleCount,
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CollapsibleExample
        Id_Filer={Id_Filer}
        Id_CLIENT={Id_CLIENT}
        Name_sous_client={Name_sous_client}
        Id_SOUS_CLIENT={Id_SOUS_CLIENT}
        NameCtt={NameCtt}
        NAME_Filer={NAME_Filer}
      />

      <Container fluid>
        <Row>
          <Col xs={10} id="page-content-wrapper">
            <div>
              <h1 style={{margin: "30px"}}>Accueil</h1>

              <div>
                <Row>
                  <Col>
                    <p
                      style={{
                        margin: "10px",
                        "font-size": "20px",
                        "font-family": "georgia",
                      }}
                    >
                      {" "}
                      Nom de client : {formData.NameCt}{" "}
                    </p>
                  </Col>
                  <Col>
                    <p
                      style={{
                        margin: "10px",
                        "font-size": "20px",
                        "font-family": "georgia",
                      }}
                    >
                      Numéro du compte : {formData.NumCt}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <p
                    style={{
                      "margin-left": "20px",
                      "font-size": "15px",
                      "font-family": "georgia",
                    }}
                  >
                    <Link
                      exact
                      to={{
                        pathname: "/DashBord",
                        state: {
                          Id_CLIENT: Id_CLIENT,
                        },
                      }}
                    >
                      {NameCtt}
                    </Link>
                    {">"}
                    <Link
                      exact
                      to={{
                        pathname: "/Accueil_sous_Client",
                        state: {
                          Id_CLIENT: Id_CLIENT,
                          Id_SOUS_CLIENT: Id_SOUS_CLIENT,
                          NAME_SOUSCLIENT: Name_sous_client,
                          NAmeClient: NameCtt,
                        },
                      }}
                    >
                      {Name_sous_client}
                    </Link>

                    {">"}
                    <Link
                      to={{
                        pathname: "/Filiere",
                        state: {
                          Id_Filer: Id_Filer,
                          Id_CLIENT: Id_CLIENT,
                          NameCtt: NameCtt,
                          NAME_Filer: NAME_Filer,
                          Name_sous_client: Name_sous_client,
                        },
                      }}
                    >
                      {NAME_Filer}
                    </Link>
                  </p>
                </Row>

                <Row>
                  <Table striped>
                    <tbody>
                      <td>SIN : {statistics.xr} </td>
                      <td>VR : {statistics.risque}</td>
                      <td>VP : {statistics.vp}</td>
                      <td>EP : {statistics.ep} </td>
                    </tbody>
                  </Table>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Accueil_Filiere2;
