import React from 'react';
import './../../css/style.css' ; 
 
import "./../../style2.scss" ;
import { Container, Row,  } from 'react-bootstrap';  

import { FaPhoneAlt  , FaMapMarkerAlt } from 'react-icons/fa';
import { IoMail} from    "react-icons/io5";
function SaideLeftFromuler () { 

    return ( 
 
     <div>
        
     
     <div> 
        <Container>
   
        <div className='cont pt2' >
        <h2>Nos coordonnées</h2>
        <h3>Pour toute demande d’information</h3>
        <Row>
        <p> <FaPhoneAlt className='icons' />  +216 95 999 644  </p>
      </Row> 
      <Row>
      <p> <FaPhoneAlt className='icons' />  +216 70 298 170</p>
    </Row> 
      <Row>
         
         <p> <IoMail className='icons'  />   contact@uesas.com.tn
         </p>
      </Row>
      <Row>
       <p><FaMapMarkerAlt className='icons'  />  Avenue de la bourse , les jardins de lac 1053, Immeuble Rosalys , 1er étage bureau C2 , Tunis, 1053, TN</p> 
      </Row>
        </div>

       </Container>
     </div>
 
    </div>
 
      );
    } 

export default  SaideLeftFromuler  ;